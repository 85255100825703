import { MENU_DRAWER,DIALOG,CHANGE_CONTEXT} from './../constants/typesRedux';



export const getMenuDrawerManager = menuDrawerStatus => {
    return {
      type: MENU_DRAWER,
      menuDrawerStatus,
    };
};
export const getDialogManager = dialog => {
  return {
    type: DIALOG,
    dialog,
  };
};

export const getContextApp = context => {
  return {
    type: CHANGE_CONTEXT,
    context,
  };
};