import React from 'react';
import { makeStyles, } from '@material-ui/core';
import Accordion from '@material-ui/core/ExpansionPanel';
import AccordionSummary from '@material-ui/core/ExpansionPanelSummary';
import AccordionDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { dialogsMsgs, sections } from '../msgs';
import ic_warning from '../../../../assets/ic_warning.png'
import ic_error from '../../../../assets/ic_error.png'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  heading: {
    color: "#004E9B",
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold,
  },
  answers: {
    color: "gray",
    fontSize: theme.typography.pxToRem(15),
    //fontWeight: theme.typography.fontWeightMedium,
  },
  answerssubtitlestitle: {
    color: "#666667",
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold,
    marginLeft: 20
  },
  answerssubtitlesresponses: {
    color: "gray",
    fontSize: theme.typography.pxToRem(15),
    marginLeft: 20
  },
  answerssubtitlesresponsessubtitles: {
    color: "gray",
    fontSize: theme.typography.pxToRem(15),
    marginLeft: 20
  },
  answerssubtitlesresponsessubtitleslist:{
    color: "#666667",
    fontSize: theme.typography.pxToRem(15),
    //fontWeight: theme.typography.fontWeightBold,
    marginLeft: 40
  },

  image: {
    height: 25,
    marginRight: 20
  },
  imagedown: {
    height: 25,

    marginLeft: 20
  },
}))
export default function Accordeon() {
  const classes = useStyles()

 
  return (

    <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>{sections.sections[0].pregunta}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.answers}>{sections.sections[0].respuestas[0].title}</Typography>

        </AccordionDetails>
        {
          sections.sections[0].respuestas[0].subtitles.map((value, index, array) => (
            <>
              <AccordionDetails>
                <Typography className={classes.answerssubtitlestitle}>{sections.sections[0].respuestas[0].subtitles[index].title} </Typography>
              </AccordionDetails>

              {
                sections.sections[0].respuestas[0].subtitles[index].responses.map((val, ind, arra) => (
                  <>
                    <AccordionDetails>
                      {

                        sections.sections[0].respuestas[0].subtitles[index].responses[ind].title.includes("Dpos")?
                      <Typography className={classes.answerssubtitlesresponses}>{sections.sections[0].respuestas[0].subtitles[index].responses[ind].title.substring(0,16)}{ <b>{sections.sections[0].respuestas[0].subtitles[index].responses[ind].title.substring(sections.sections[0].respuestas[0].subtitles[index].responses[ind].title.indexOf("Dpos"),20)}</b>}{sections.sections[0].respuestas[0].subtitles[index].responses[ind].title.substring(sections.sections[0].respuestas[0].subtitles[index].responses[ind].title.indexOf("Dpos") + 4)}</Typography>
                      
                        :                      
                        <Typography className={classes.answerssubtitlesresponses}>{sections.sections[0].respuestas[0].subtitles[index].responses[ind].title}</Typography>

                        }
                      </AccordionDetails>
                    {
                      sections.sections[0].respuestas[0].subtitles[index].responses[ind].subtitles.map((v, i, a) =>
                        <div style={{marginTop:-10}}>
                          <AccordionDetails>
                            {console.log(v.split(':')[0])}
                            {v.split(':')[0] === "Si es un teléfono inteligente" ||  v.split(':')[0] === "Si es una tableta"  ?
                              <div >
                              <Typography className={classes.answerssubtitlesresponsessubtitles}><b>{v.split(':')[0]}: </b>{v}</Typography></div> :<div><Typography className={classes.answerssubtitlesresponsessubtitleslist }>{v} </Typography>
                              </div>
                            }
                          </AccordionDetails>
                        </div>
                      )
                    }
                  </>
                ))

              }
            </>
          ))
        }

      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>{sections.sections[1].pregunta}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.answers}>
            {sections.sections[1].respuestas[0].title}
          </Typography>
        </AccordionDetails>

        {
          sections.sections[1].respuestas[0].subtitles.map((value, index, array) => (
            <>
              <AccordionDetails>
                <Typography className={classes.answerssubtitlestitle} style={{marginTop:-15}}>{sections.sections[1].respuestas[0].subtitles[index].title}</Typography>
              </AccordionDetails>

              {
                sections.sections[1].respuestas[0].subtitles[index].responses.map((val, ind, arra) => (
                  <>
                    <AccordionDetails>
                      {
                        sections.sections[1].respuestas[0].subtitles[index].responses[ind].title.includes("Dpos")?
                    <Typography className={classes.answerssubtitlesresponses}>{sections.sections[1].respuestas[0].subtitles[index].responses[ind].title.substring(0,16)}{ <b>{sections.sections[1].respuestas[0].subtitles[index].responses[ind].title.substring(sections.sections[1].respuestas[0].subtitles[index].responses[ind].title.indexOf("Dpos"),20)}</b>}{sections.sections[1].respuestas[0].subtitles[index].responses[ind].title.substring(sections.sections[1].respuestas[0].subtitles[index].responses[ind].title.indexOf("Dpos") + 4)}</Typography>
                      
                        :
                        
                        <Typography className={classes.answerssubtitlesresponses}>{sections.sections[1].respuestas[0].subtitles[index].responses[ind].title}</Typography>
                      }
                    </AccordionDetails>
                    {
                      sections.sections[1].respuestas[0].subtitles[index].responses[ind].subtitles.map((v, i, a) =>
                        <div style={{marginTop:-10}}>
                          <AccordionDetails>
                            <Typography className={classes.answerssubtitlesresponsessubtitleslist}>{v}</Typography>
                          </AccordionDetails>
                          
                        </div>
                        
                      )
                    }
                    {
                      sections.sections[1].respuestas[0].subtitles[index].responses[ind].note !=="" &&
                      <AccordionDetails>
                      <Typography className={classes.answerssubtitlesresponses}>{sections.sections[1].respuestas[0].subtitles[index].responses[ind].note}</Typography>
                    </AccordionDetails>
                    }
                    
                    
                  </>
                ))

              }
            </>
          ))
        }
      </Accordion>

      <Accordion >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography className={classes.heading}>{sections.sections[2].pregunta}</Typography>
        </AccordionSummary>
        <AccordionDetails>
         
          <Typography className={classes.answers}>{sections.sections[2].respuestas[0].title.substring(0,sections.sections[2].respuestas[0].title.indexOf('Dpos'))}{ <b>{sections.sections[2].respuestas[0].title.substring(sections.sections[2].respuestas[0].title.indexOf("Dpos"),sections.sections[2].respuestas[0].title.indexOf("Dpos")+4)}</b>}{sections.sections[2].respuestas[0].title.substring(sections.sections[2].respuestas[0].title.indexOf("Dpos") + 4)}</Typography>

           
          
        </AccordionDetails>

        {
          sections.sections[2].respuestas[0].subtitles.map((value, index, array) => (
            <>
              <AccordionDetails>
                <Typography className={classes.answerssubtitlestitle}>{sections.sections[2].respuestas[0].subtitles[index].title}</Typography>
              </AccordionDetails>

              {
                sections.sections[2].respuestas[0].subtitles[index].responses.map((val, ind, arra) => (
                  <>
                    <AccordionDetails>
                      <Typography className={classes.answerssubtitlesresponses}>{sections.sections[2].respuestas[0].subtitles[index].responses[ind].title}</Typography>
                    </AccordionDetails>
                    {
                      sections.sections[2].respuestas[0].subtitles[index].responses[ind].subtitles.map((v, i, a) =>
                        <>
                       
                          <AccordionDetails>
                            
                            <Typography className={classes.answerssubtitlesresponsessubtitles}>{v}</Typography>
                          </AccordionDetails>
                        </>
                      )
                    }
                  </>
                ))

              }
            </>
          ))
        }
      </Accordion>

      <Accordion >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography className={classes.heading}>{sections.sections[3].pregunta}</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Typography className={classes.answers}>{sections.sections[3].respuestas[0].title.substring(0,sections.sections[3].respuestas[0].title.indexOf('Dpos'))}{ <b>{sections.sections[3].respuestas[0].title.substring(sections.sections[3].respuestas[0].title.indexOf("Dpos"),sections.sections[3].respuestas[0].title.indexOf("Dpos")+4)}</b>}{sections.sections[3].respuestas[0].title.substring(sections.sections[3].respuestas[0].title.indexOf("Dpos") + 4)}</Typography>


          
        </AccordionDetails>

        {
          sections.sections[3].respuestas[0].subtitles.map((value, index, array) => (
            <>
              <AccordionDetails>
              <Typography className={classes.answers}>{sections.sections[3].respuestas[0].subtitles[index].title.substring(0,sections.sections[3].respuestas[0].subtitles[index].title.indexOf('Dpos'))}{ <b>{sections.sections[3].respuestas[0].subtitles[index].title.substring(sections.sections[3].respuestas[0].subtitles[index].title.indexOf("Dpos"),sections.sections[3].respuestas[0].subtitles[index].title.indexOf("Dpos")+4)}</b>}{sections.sections[3].respuestas[0].subtitles[index].title.substring(sections.sections[3].respuestas[0].subtitles[index].title.indexOf("Dpos") + 4)}</Typography>

              </AccordionDetails>

              {
                sections.sections[3].respuestas[0].subtitles[index].responses.map((val, ind, arra) => (
                  <>
                    <AccordionDetails>
                      <Typography className={classes.answerssubtitlesresponses}>{sections.sections[3].respuestas[0].subtitles[index].responses[ind].title}</Typography>
                    </AccordionDetails>
                    {
                      sections.sections[3].respuestas[0].subtitles[index].responses[ind].subtitles.map((v, i, a) =>
                        <>
                          <AccordionDetails>
                            <Typography className={classes.answerssubtitlesresponsessubtitles}>{v}</Typography>
                          </AccordionDetails>
                        </>
                      )
                    }
                  </>
                ))

              }
            </>
          ))
        }
        <AccordionDetails>
       
          <Typography className={classes.answers}>
            {sections.sections[3].respuestas[1].title}
           
          </Typography>
        
        </AccordionDetails>
        <AccordionDetails>
       
          <Typography className={classes.answers}>
            {sections.sections[3].respuestas[2].title.split("(")[0]} ( <b>{sections.sections[3].respuestas[2].title.split("(")[1].split(")")[0]}</b> ){sections.sections[3].respuestas[2].title.split("(")[1].split(")")[1]}
           
          </Typography>
        
        </AccordionDetails>

        <AccordionDetails>
        <Typography className={classes.answers}>  
         <b>{sections.sections[3].respuestas[3].title.split('!')[0]}! </b> {sections.sections[3].respuestas[3].title.split('!')[1].split("www.mercantilbanco.com")[0]} <u><a href="https://www.mercantilbanco.com" style={{color:"blue"}}>{sections.sections[3].respuestas[3].title.split('!')[1].substring(sections.sections[3].respuestas[3].title.split('!')[1].indexOf('www.mercantilbanco.com'),136)}</a></u> {sections.sections[3].respuestas[3].title.split('!')[1].substring(136)}
         </Typography>  
          

          {/* <Typography className={classes.answers}>
          

           {sections.sections[3].respuestas[3].title} }
          </Typography> */}
        
  
         
        </AccordionDetails>
      </Accordion>

      <Accordion >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography className={classes.heading}>{sections.sections[4].pregunta}</Typography>
        </AccordionSummary>

        {
          sections.sections[4].respuestas.map((value, index) => (
            <>{console.log(value.title.split(":"))}
                <AccordionDetails>
                  {value.title.split(':')[0] === "Dispositivos Android" ||  value.title.split(':')[0] === "Dispositivos iOS"  ?
                              <div >
                              <Typography className={classes.answers}><b>{value.title.split(':')[0]}: </b>{value.title}</Typography></div> :<div><Typography className={classes.answers }>{value.title}</Typography>
                              </div>
                  }
              
              </AccordionDetails>
              {

                sections.sections[4].respuestas[index].subtitles.map((val, ind) => (
                  <AccordionDetails>
                    <img src={ic_error} className={classes.imagedown} /><Typography className={classes.answerssubtitlestitle}>
                      {val}
                    </Typography>
                  </AccordionDetails>
                ))
                // {
                //   
                // }
              }
            </>
          ))

        }

      </Accordion>

      <Accordion >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography className={classes.heading}>{sections.sections[5].pregunta}</Typography>
        </AccordionSummary>

        {
          sections.sections[5].respuestas.map((value, index) => (
            <>
              <AccordionDetails>
                <Typography className={classes.answers}>
                  {value.title}
                </Typography>
              </AccordionDetails>

            </>
          ))

        }

      </Accordion>

      <Accordion >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography className={classes.heading}>{dialogsMsgs.sections[0]}</Typography>
        </AccordionSummary>
        {
          dialogsMsgs.sections.map((value, index) => {
            if (index > 0) {
              return (
                <>
                  <AccordionDetails>
                    <Accordion style={{ width: '100%' }}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3a-content"
                        id="panel3a-header"
                      >
                        <img src={value.icon === "error" ? ic_error : ic_warning} className={classes.image} /><Typography className={classes.heading}>{value.mensaje}</Typography>
                      </AccordionSummary>

                      {dialogsMsgs.sections[index].preguntas.map((val, ind) => (
                        <>
                          <AccordionDetails>
                            <Typography className={classes.answerssubtitlestitle}>
                              {val.title}
                            </Typography>
                          </AccordionDetails>

                          {
                            dialogsMsgs.sections[index].preguntas[ind].respuesta.map((v, i) => (
                              <>
                                <AccordionDetails>
                                  <Typography className={classes.answerssubtitlesresponsessubtitles}>
                                    {v}
                                  </Typography>
                                </AccordionDetails>

                              
                              </>
                            ))
                          }
                          {/* {
                            dialogsMsgs.sections[index].preguntas[ind].respuesta.map((v, i) => (
                              <>
                                <AccordionDetails>
                                  <Typography className={classes.answerssubtitlesresponsessubtitles}>
                                    {v}
                                  </Typography>
                                </AccordionDetails>

                              
                              </>
                            ))
                          } */}
                         

                            {
                                  dialogsMsgs.sections[index].preguntas[ind].subrespuesta.map((valor, indice) => (
                                    <>
                                      <AccordionDetails>
                                        <Typography className={classes.answerssubtitlesresponsessubtitleslist}>
                                          {valor}
                                        </Typography>
                                      </AccordionDetails>
                                    </>
                                  ))
                                }

                        {     
                            dialogsMsgs.sections[index].preguntas[ind].notas !== undefined &&
                            dialogsMsgs.sections[index].preguntas[ind].notas.map((n, x) => (
                              <>
                                <AccordionDetails>
                                  <Typography className={classes.answerssubtitlesresponsessubtitles}>
                                   <b><u>Nota:</u></b> {n.substring(0,104)} <b>{n.substring(105)}</b>
                                   
                                  </Typography>
                                </AccordionDetails>

                              
                              </>
                            ))
                          }
                        </>
                      ))}


                    </Accordion>


                  </AccordionDetails>
                </>
              )
            }
          })
        }

     
      </Accordion>
    </div>
  );
}