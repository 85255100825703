export const seccion = {
    "secciones":[
        { "pregunta": "1.Tienes inconvenientes para descargar tu aplicación Dpos", "respuesta": "Si no puedes descargar la aplicación Dpos, debes considerar lo siguiente: Valida el tipo de sistema operativo de tu teléfono o tableta."},
        { "pregunta": "2.Tu aplicación Dpos se cierra inesperadamente o tienes inconvenientes", "respuesta": ""},
        { "pregunta": "3.Tienes inconvenientes para descargar tu aplicación Dpos", "respuesta": ""},
        { "pregunta": "4.Qué sucede si no aceptas todos los permisos solicitados al descargar tu aplicación Dpos", "respuesta": ""},
        { "pregunta": "5.Qué sucede si desactivas la ubicación de tu dispositivo Dpos", "respuesta": ""},
        { "pregunta": "Mensajes presentados en la aplicación Dpos", "respuesta": ""},
        { "pregunta": "La aplicación se cerrará debido a que no permitiste el acceso a la ubicación de tu dispositivo", "respuesta": ""},
        { "pregunta": "Este usuario se encuentra registrado en otro dispositivo", "respuesta": ""}
    ]
}

export const sections = {
    "sections":[
        { 
            "pregunta": "1.Tienes inconvenientes para descargar tu aplicación Dpos",
            "respuestas":[
                {
                    "title":"Si no puedes descargar la aplicación Dpos, debes considerar lo siguiente:",
                    "subtitles":[
                        {
                            "title": "1.Valida el tipo de sistema operativo de tu teléfono o tableta.",
                            "responses":[
                                {
                                    "title":"Dispositivos con Sistemas Operativos compatibles:",
                                    "subtitles":["Android 8.0 en adelante","iOS 12 en adelante (No disponible para iPAD)."],
                                
                                },
                                {
                                    "title":"Dispositivos con Sistemas Operativos no permitidos:",
                                    "subtitles":["BlackBerry","Windows","Symbian","Entre otros."],
                                
                                }
                            ]
                        },
                        
                        {
                            "title": "2.Valida el tipo de dispositivo desde donde estás intentando descargar la aplicación Dpos:",
                            "responses":[
                                {
                                    "title":"",
                                    "subtitles":["Si es un teléfono inteligente: sólo podrás descargar la aplicación Dpos desde teléfonos con un tamaño igual o superior a 5 pulgadas.","Si es una tableta: sólo podrás descargar la aplicación Dpos desde una tableta con sistema operativo Android. No se permite la descarga desde tabletas con sistema operativo iOS (iPad)"],
                                
                                },
                              
                            ]
                        },
        
                        {
                            "title": "3.Verifica si tu dispositivo tiene espacio suficiente en la memoria.",
                            "responses":[]
                        },
        
                        {
                            "title": "4.Valida si tu dispositivo tiene conexión a internet estable.",
                            "responses":[
                                {
                                    "title":"Para poder usar Dpos debes asegurarte de contar con una conexión WIFI o plan de datos móviles en tu dispositivo que sea estable.",
                                    "subtitles":[],
                                
                                },
                              
                            ]
                        },
        
                        {
                            "title": "5.Verifica la versión mínima permitida en las tiendas de aplicaciones.",
                            "responses":[]
                        },
                    ]
                },

                
            ]
        },

        { 
            "pregunta": "2.¿Tu aplicación Dpos se cierra inesperadamente o tienes inconvenientes para abrirla?",
            "respuestas":[
                {
                    "title":"Si la aplicación Dpos se cierra o presenta inconvenientes al momento de abrirla, debes considerar lo siguiente:",
                    "subtitles":[
                        {
                            "title": "1.Acepta todos los permisos de la aplicación:",
                            "responses":[
                                {
                                    "title":"",
                                    "subtitles":["Gestionar llamadas","Acceder a los contactos","Geolocalización del dispositivo"],
                                    "note":"Si rechazaste alguno de los permisos, la aplicación se cerrará de forma inesperada y no podrás hacer uso de ella."
                                },
                               
                            ],
                            
                        },
                        {
                            "title": "2.Valida si posees la última versión de la aplicación Dpos.",
                            "responses":[
                                {
                                    "title":"",
                                    "subtitles":[],
                                    "note":""
                                },
                               
                            ],
                            
                        },
                        {
                            "title": "3.Valida el tipo de sistema operativo de tu teléfono o tableta.",
                            "responses":[
                                {
                                    "title":"Dispositivos con Sistemas Operativos compatibles:",
                                    "subtitles":["Android 8.0 en adelante","iOS 12 en adelante (No disponible para iPAD)."],
                                    "note":""
                                },
                                {
                                    "title":"Dispositivos con Sistemas Operativos no permitidos:",
                                    "subtitles":["BlackBerry","Windows","Symbian","Entre otros"],
                                    "note":""
                                },
                               
                            ],
                            
                        },

                        {
                            "title": "4.Verifica que tu conexión a internet sea estable.",
                            "responses":[
                                {
                                    "title":"Para poder usar Dpos debes asegurarte de contar con una conexión WIFI o plan de datos móviles en tu dispositivo que sea estable.",
                                    "subtitles":[],
                                    "note":""
                                },
                            ],
                            
                        },
                    ]
                },
            ]
        },

        { 
            "pregunta": "3.¿No puedes iniciar sesión en tu Dpos?",
            "respuestas":[
                {
                    "title":"Si presentas inconvenientes al momento de iniciar sesión en la aplicación Dpos, debes considerar lo siguiente:",
                    "subtitles":[
                        {
                            "title": "1.Valida si tu dispositivo tiene conexión a internet estable.",
                            "responses":[
                                {
                                    "title":"Para poder usar Dpos debes asegurarte de contar con una conexión WIFI o plan de datos móviles en tu dispositivo, que sea estable.",
                                    "subtitles":[],
                                    "note":""
                                },
                               
                            ],
                            
                        },
                        {
                            "title": "2.Valida si el usuario y la clave ingresados son correctos.",
                            "responses":[
                                {
                                    "title":"Puedes verificar el usuario y contraseña que asignaste en la sección Gestionar usuarios del portal administrativo Dpos.",
                                    "subtitles":[],
                                    "note":""
                                },
                               
                            ],
                         
                        },
                        {
                            "title": "3.Valida si el usuario utilizado está aprobado.",
                            "responses":[
                                {
                                    "title":"Para poder acceder a Dpos tu usuario debe estar Aprobado.  Los usuarios con estatus Pendiente o No califica, no tendrán acceso a la aplicación Dpos.",
                                    "subtitles":[],
                                    "note":""
                                },
                                {
                                    "title":"El estatus de tu(s) usuario(s) lo puedes verificar en la sección Gestionar usuarios del portal administrativo Dpos.",
                                    "subtitles":[],
                                    "note":""
                                },
                               
                            ],
                           
                        },
                        {
                            "title": "4.Validar que el usuario ingresado no se esté utilizando en otro dispositivo.",
                            "responses":[
                                {
                                    "title":"Al iniciar sesión por primera vez en la aplicación móvil Dpos, tu usuario quedará emparejado con el dispositivo. Si deseas ingresar con ese mismo usuario en otro equipo, deberás cambiar el dispositivo en la sección “Gestionar Usuarios”.  Para conocer más detalle consulta el instructivo “Cambio de dispositivo”.",
                                    "subtitles":[],
                                    "note":""
                                },
                                {
                                    "title":"Cuando esto ocurre al momento de iniciar sesión se generara el mensaje de atención “Este usuario se encuentra registrado en otro dispositivo”.",
                                    "subtitles":[],
                                    "note":""
                                },
                            ],
                           
                        },
                    ]
                },
            ]
        },

        { 
            "pregunta": "4.¿Qué sucede si no aceptas todos los permisos solicitados al descargar tu aplicación Dpos?",
            "respuestas":[
                {
                    "title":"Para que puedas utilizar tu Dpos debes aceptar los 3 primeros permisos de configuración solicitados al momento de instalar la aplicación (Gestión de llamadas, contactos y ubicación), si los rechazas la aplicación se cerrará, cada vez que intentes abrirla o iniciar sesión. \nSi no aceptaste alguno o todos los permisos debes:",
                    "subtitles":[
                        {
                            "title": "1.Ingresar a los permisos de tu dispositivo para aceptar el(los) que haya(s) rechazado(s)  ó",
                            "responses":[],
                            "note":""
                        },
                        {
                            "title": "2.Desinstalar la aplicación Dpos, volver a instalarla y aceptar todos los permisos solicitados.",
                            "responses":[],
                            "note":""
                        },
                    ]
                },

                {
                    "title":"Al aceptar el permiso de ubicación del dispositivo se activará esta opción.",
                    "subtitles":[]
                },
                {
                    "title":"El cuarto permiso solicitado (Acceso a fotos, contenido multimedia y archivos del dispositivo) es requerido para que puedas descargar el documento de términos y condiciones de Dpos, si  no lo aceptas no podrás realizar la descarga pero sí podrás ingresar y realizar operaciones con tu Dpos.",
                    "subtitles":[]
                },
                {
                    "title":"¡Recuerda! Los Términos y condiciones que rigen esta solución de pago también los puedes descargar desde nuestra página web www.mercantilbanco.com, en la opción Empresas > Pagos y Cobranzas > Puntos de Venta y Soluciones de Pago > Dpos.",
                    "subtitles":[]
                },
            ]
        },

        { 
            "pregunta": "5.¿Qué sucede si desactivas la ubicación de tu dispositivo Dpos?",
            "respuestas":[
                {
                    "title":"Para iniciar sesión o realizar transacciones con tu Dpos, debes tener activa la opción de ubicación del dispositivo donde se encuentra la aplicación Dpos, la cual permite gestionar la seguridad de tus transacciones.",
                    "subtitles":[]
                },

                {
                    "title":"Si la desactivas, se generará un mensaje de alerta, indicando que debes activar la ubicación del dispositivo.",
                    "subtitles":[]
                },

                {
                    "title":"Dispositivos Android: Para continuar, activa la ubicación del dispositivo, que usa el servicio de ubicación de Google.",
                    "subtitles":[]
                },
                {
                    "title":"Dispositivos iOS: ¿Permitir que 'Dpos' acceda a su ubicación?",
                    "subtitles":[]
                },

                {
                    "title":"Es posible que su ubicación se muestre en el mapa y se utilice para proporcionar elementos como alertas de lugar y servicios iSharing",
                    "subtitles":[]
                },

                {
                    "title":"Si seleccionas “No, Gracias” se generará un mensaje de error y no podrás iniciar sesión o realizar transacciones con tu Dpos.",
                    "subtitles":["La aplicación se cerrará debido a que no permitiste el acceso a la ubicación de tu dispositivo"]
                },

                {
                    "title":"Al seleccionar “Aceptar” se activará la opción de ubicación de tu dispositivo y podrás utilizarlo sin inconvenientes.",
                    "subtitles":[]
                },
            ]
            
        },

        { 
            "pregunta": "6.¿Al realizar tus operaciones de Pago móvil C2P resultan negadas indicando Número de teléfono del comercio inválido?",
            "respuestas":[
                {
                    "title":"Las operaciones negadas de Pago móvil C2P con el motivo de rechazo “Número de teléfono del comercio inválido” se originan cuando el número de teléfono registrado en la plataforma Dpos para las operaciones de Pago móvil C2P no es igual al número de teléfono con el que el estás afiliado al servicio de Tpago P2C/C2P.",
                    "subtitles":[]
                },

                {
                    "title":"Esta disparidad se puede presentar cuando realizas una modificación del número de teléfono afiliado a Tpago P2C/C2P, ya que la homologación de este número con el portal Dpos se realiza al día siguiente de la modificación.",
                    "subtitles":[]
                },

                {
                    "title":"En la sección Datos del comercio del Portal administrativo Dpos, puedes verificar el número de teléfono registrado en la plataforma Dpos para las operaciones de Pago móvil C2P.",
                    "subtitles":[]
                },
                {
                    "title":"Si eres una persona jurídica o persona natural con firma Personal, el número de teléfono con el que estás afiliado al servicio de Tpago P2C/C2P lo puedes verificar en Mercantil en Línea, en la sección Administración de Seguridad > Banca Móvil > Configurar Tpago.",
                    "subtitles":[]
                },

            
            ]
            
        },

    ]
}

export const dialogsMsgs = {
    "sections":[

        "Mensajes presentados en la aplicación Dpos",
        {
            "icon":"error",
            "mensaje":"El código de afiliación del comercio debe contener 6 dígitos.",
            "preguntas":[
                {
                    "title": "¿Cuándo se genera?",
                    "respuesta": ["Cuando haces clic en el botón Continuar y no has colocado el código de afiliación o colocas uno con menos de 6 dígitos."],
                    "subrespuesta":[],
                    "notas":[]
                },
                {
                    "title": "¿Qué debes hacer?",
                    "respuesta": ["Validar el código de afiliación ingresado e intentarlo nuevamente"],
                    "subrespuesta":[],
                    "notas":["Tu código de afiliación será informado por el banco, vía correo electrónico, al procesar tu afiliación a Dpos."]
                },
            ]
        },
        {
            "icon":"error",
            "mensaje":"Ingresa usuario o contraseña correcta.",
            "preguntas":[
                {
                    "title": "¿Cuándo se genera?",
                    "respuesta": ["Cuando intentas iniciar sesión con un usuario o contraseña incorrecta o el usuario utilizado no se encuentra registrado en el portal administrativo Dpos o no está Aprobado."],
                    "subrespuesta":[],
                    "notas":[]
                },
                {
                    "title": "¿Qué debes hacer?",
                    "respuesta": ["Validar en la sección Gestionar usuarios del Portal administrativo Dpos:"],
                    "subrespuesta":["Que el usuario o contraseña ingresada son las correctas.","Que el usuario ingresado existe y tiene estatus Aprobado."],
                    "notas":[]
                },
            ]
        },
        {
            "icon":"warning",
            "mensaje":"No te encuentras afiliado a Dpos. Para conocer cómo hacerlo, ingresa a www.mercantilbanco.com, opción Empresas > Pagos y Cobranzas > Puntos de Venta y Soluciones de Pago > Dpos.",
            "preguntas":[
                {
                    "title": "¿Cuándo se genera?",
                    "respuesta": ["Cuando ingresas un código de afiliación diferente al informado por el banco para tu comercio o cuando haces clic en la opción ¿No tienes código de afiliación?                    "],
                    "subrespuesta":[],
                    "notas":[]
                },
                {
                    "title": "¿Qué debes hacer?",
                    "respuesta": ["Validar el código de afiliación ingresado e intentarlo nuevamente.  Este código debe ser igual al informado por el banco en el correo de bienvenida a Dpos."],
                    "subrespuesta":[],
                    "notas":[]
                },
            ]
        },
        {
            "icon":"warning",
            "mensaje":"Este usuario se encuentra registrado en otro dispositivo.",
            "preguntas":[
                {
                    "title": "¿Cuándo se genera?",
                    "respuesta": ["Cuando el usuario con el que estás intentando iniciar sesión en tu Dpos ya se está utilizando en otro dispositivo."],
                    "subrespuesta":[],
                    "notas":[]
                },
                {
                    "title": "¿Qué debes hacer?",
                    "respuesta": ["Al iniciar sesión por primera vez en la aplicación móvil Dpos, tu usuario quedará emparejado con el dispositivo.","Si requieres utilizar el usuario en otro dispositivo, puedes liberarlo en la sección Gestionar usuarios del Portal administrativo Dpos.  Para conocer más detalle consulta el instructivo “Cambio de dispositivo”."],
                    "subrespuesta":[],
                    "notas":[]
                },
            ]
        },

        {
            "icon":"warning",
            "mensaje":"Verifica la conexión a la red WIFI o datos móviles de tu dispositivo e intenta nuevamente.",
            "preguntas":[
                {
                    "title": "¿Cuándo se genera?",
                    "respuesta": ["Cuando el dispositivo no tiene conexión a Internet o su conexión es inestable."],
                    "subrespuesta":[],
                    "notas":[]
                },
                {
                    "title": "¿Qué debes hacer?",
                    "respuesta": ["Asegurarte de contar con una conexión WIFI o plan de datos móviles en el dispositivo estable para poder iniciar sesión o realizar alguna transacción o consulta en tu aplicación Dpos."],
                    "subrespuesta":[],
                    "notas":[]
                },
            ]
        },

        {
            "icon":"error",
            "mensaje":"Algo ha salido mal… Intenta realizar esta operación más tarde.",
            "preguntas":[
                {
                    "title": "¿Cuándo se genera?",
                    "respuesta": ["Cuando los servicios de la plataforma Dpos se encuentran sin conexión o se produce un error interno, esto puede ocurrir en cualquier pantalla de la aplicación Dpos."],
                    "subrespuesta":[],
                    "notas":[]
                },
                {
                    "title": "¿Qué debes hacer?",
                    "respuesta": ["Realizar nuevamente tu operación (Inicio de sesión, procesamiento de una transacción con tarjeta o pago móvil, consulta de transacciones, etc.).","Si el error persiste por un tiempo prolongado escríbenos a mercantilempresas@bancomercantil.com o comunícate al Centro de Atención Mercantil al número  (0212) 503.25.50 para reportar tu incidencia."],
                    "subrespuesta":[],
                    "notas":[]
                },
            ]
        },

        {
            "icon":"error",
            "mensaje":"El documento de identidad debe contener mínimo 6 dígitos.",
            "preguntas":[
                {
                    "title": "¿Cuándo se genera?",
                    "respuesta": ["Cuando se colocan menos de 6 dígitos en la pantalla de cédula de identidad del cliente, al realizar operaciones con tarjetas de crédito (nacionales e internacionales) o tarjetas de Débito Mercantil o internacionales."],
                    "subrespuesta":[],
                    "notas":[]
                },
                {
                    "title": "¿Qué debes hacer?",
                    "respuesta": ["Verificar el número de documento de identidad ingresado e intentarlo nuevamente."],
                    "subrespuesta":[],
                    "notas":[]
                },
            ]
        },

        {
            "icon":"error",
            "mensaje":"El número de tarjeta es inválido.",
            "preguntas":[
                {
                    "title": "¿Cuándo se genera?",
                    "respuesta": ["Cuando se colocan menos dígitos de los requeridos en la pantalla de número de tarjeta del cliente, al realizar operaciones con tarjetas."],
                    "subrespuesta":[],
                    "notas":[]
                },
                {
                    "title": "Tarjetas de Débito Mercantil:",
                    "respuesta": [],
                    "subrespuesta":["Cuando se colocan menos de 18 dígitos."],
                    "notas":[]
                },
                {
                    "title": "Tarjetas de crédito (nacionales e internacionales) o tarjetas de débito internacionales:",
                    "respuesta": [],
                    "subrespuesta":["Si la tarjeta es Diners, cuando se colocan menos de 14 dígitos.","Si la tarjeta es Mastercard o Visa, cuando se colocan menos de 16 dígitos."]
                },
                {
                    "title": "¿Qué debes hacer?",
                    "respuesta": ["Verificar el número de documento de identidad ingresado e intentarlo nuevamente."],
                    "subrespuesta":[]
                },
            ]
        },

        {
            "icon":"error",
            "mensaje":"El código de seguridad de la tarjeta (CVV) debe ser de 3 dígitos.",
            "preguntas":[
                {
                    "title": "¿Cuándo se genera?",
                    "respuesta": ["Cuando se colocan menos de 3 dígitos en la pantalla de CVV, al realizar operaciones con tarjetas de crédito o tarjetas de Débito Mercantil o internacionales."],
                    "subrespuesta":[]
                },
    
                {
                    "title": "¿Qué debes hacer?",
                    "respuesta": ["Verificar el número de código de seguridad de la tarjeta (CVV) ingresado e intentarlo nuevamente."],
                    "subrespuesta":[]
                },
            ]
        },

        {
            "icon":"error",
            "mensaje":"Ingresa un monto mayor o igual a Bs. <monto_mínimo> y menor o igual a Bs. <monto_máximo>.",
            "preguntas":[
                {
                    "title": "¿Cuándo se genera?",
                    "respuesta": ["Cuando se coloca un monto que está fuera de los límites mínimo y máximo por operación establecidos por banco o se intenta avanzar sin colocar ningún monto, solo se presenta al realizar operaciones con tarjetas de crédito (nacionales e internacionales) o tarjetas de Débito Mercantil o internacionales"],
                    "subrespuesta":[]
                },
    
                {
                    "title": "¿Qué debes hacer?",
                    "respuesta": ["Ingresar un monto dentro del rango permitido e intentarlo nuevamente."],
                    "subrespuesta":[]
                },
            ]
        },

        {
            "icon":"error",
            "mensaje":"La clave debe ser de 4 dígitos.",
            "preguntas":[
                {
                    "title": "¿Cuándo se genera?",
                    "respuesta": ["Cuando se colocan menos de 4 dígitos en la pantalla de ingreso de la Clave o PIN en operaciones con Tarjetas de Débito Mercantil."],
                    "subrespuesta":[]
                },
    
                {
                    "title": "¿Qué debes hacer?",
                    "respuesta": ["Solicitar al cliente que verificar la clave ingresada e intentarlo nuevamente."],
                    "subrespuesta":[]
                },
            ]
        },

        {
            "icon":"error",
            "mensaje":"Tarjeta vencida, verifica e intenta nuevamente.",
            "preguntas":[
                {
                    "title": "¿Cuándo se genera?",
                    "respuesta": ["Cuando la fecha ingresada es menor a la actual, solo se presenta al realizar operaciones con tarjetas de crédito (nacionales e internacionales) o tarjetas de débito internacionales.                    "],
                    "subrespuesta":[]
                },
    
                {
                    "title": "¿Qué debes hacer?",
                    "respuesta": ["Verificar la fecha de vencimiento ingresada e intentarlo nuevamente."],
                    "subrespuesta":[]
                },
            ]
        },

        {
            "icon":"error",
            "mensaje":"Ingresa una fecha de vencimiento válida.",
            "preguntas":[
                {
                    "title": "¿Cuándo se genera?",
                    "respuesta": ["Cuando se coloca un número de mes errado, solo se presenta al realizar operaciones con tarjetas de crédito (nacionales e internacionales) o tarjetas de débito internacionales.                    "],
                    "subrespuesta":[]
                },
    
                {
                    "title": "¿Qué debes hacer?",
                    "respuesta": ["Verificar la fecha de vencimiento ingresada e intentarlo nuevamente."],
                    "subrespuesta":[]
                },
            ]
        },

        {
            "icon":"error",
            "mensaje":"Ingresa un monto mayor a Bs. cero (0).",
            "preguntas":[
                {
                    "title": "¿Cuándo se genera?",
                    "respuesta": ["Cuando al realizar una operación de Pago móvil C2P, en la pantalla de ingreso de monto, colocas cero (0) o intentas avanzar sin introducir ningún monto."],
                    "subrespuesta":[]
                },
    
                {
                    "title": "¿Qué debes hacer?",
                    "respuesta": ["Ingresar un monto mayor a cero (0) e intentarlo nuevamente."],
                    "subrespuesta":[]
                },
            ]
        },

        {
            "icon":"warning",
            "mensaje":"Selecciona el banco pagador.",
            "preguntas":[
                {
                    "title": "¿Cuándo se genera?",
                    "respuesta": ["Cuando en la pantalla de ingreso de datos del pagador de operaciones de Pago móvil C2P se intenta seleccionar el código de área del teléfono del pagador, no se ha seleccionado el banco pagador y se hace clic en el botón Continuar."],
                    "subrespuesta":[]
                },
    
                {
                    "title": "¿Qué debes hacer?",
                    "respuesta": ["Seleccionar el banco pagador."],
                    "subrespuesta":[]
                },
            ]
        },

        {
            "icon":"warning",
            "mensaje":"Selecciona el código de área del teléfono del pagador.",
            "preguntas":[
                {
                    "title": "¿Cuándo se genera?",
                    "respuesta": ["Cuando en la pantalla de ingreso de datos del pagador de operaciones de Pago móvil C2P se intenta ingresar el número de teléfono del pagador, no se ha seleccionado el código de área y se hace clic en el botón Continuar."],
                    "subrespuesta":[]
                },
    
                {
                    "title": "¿Qué debes hacer?",
                    "respuesta": ["Seleccionar el código de área del teléfono del banco pagador."],
                    "subrespuesta":[]
                },
            ]
        },

        {
            "icon":"error",
            "mensaje":"Ingresa el número de teléfono del pagador, debe contener 7 dígitos.",
            "preguntas":[
                {
                    "title": "¿Cuándo se genera?",
                    "respuesta": ["Cuando en la pantalla de ingreso de datos del pagador de operaciones de Pago móvil C2P no se ha ingresado el número de teléfono, se intenta ingresar el número del documento de identidad del pagador o se colocan menos de 7 dígitos y se hace clic en el botón Continuar."],
                    "subrespuesta":[]
                },
    
                {
                    "title": "¿Qué debes hacer?",
                    "respuesta": ["Ingresar el número de teléfono del pagador o verificar el número ingresado."],
                    "subrespuesta":[]
                },
            ]
        },

        {
            "icon":"warning",
            "mensaje":"Selecciona el tipo de documento de identidad del pagador.",
            "preguntas":[
                {
                    "title": "¿Cuándo se genera?",
                    "respuesta": ["Cuando en la pantalla de ingreso de datos del pagador de operaciones de Pago móvil C2P se intenta ingresar el número de documento de identidad del pagador, no se ha seleccionado el tipo de documento y se hace clic en el botón Continuar."],
                    "subrespuesta":[]
                },
    
                {
                    "title": "¿Qué debes hacer?",
                    "respuesta": ["Seleccionar el tipo de documento de identidad del pagador."],
                    "subrespuesta":[]
                },
            ]
        },

        {
            "icon":"error",
            "mensaje":"Ingresa  el número de identidad del pagador, debe contener mínimo 6 dígitos.",
            "preguntas":[
                {
                    "title": "¿Cuándo se genera?",
                    "respuesta": ["Cuando en la pantalla de ingreso de datos del pagador de operaciones de Pago móvil C2P no se ha ingresado el número de teléfono o se colocan menos de 6 dígitos y se hace clic en el botón Continuar."],
                    "subrespuesta":[]
                },
    
                {
                    "title": "¿Qué debes hacer?",
                    "respuesta": ["Ingresar el número de documento de identidad del pagador o verificar el número ingresado."],
                    "subrespuesta":[]
                },
            ]
        },

        {
            "icon":"error",
            "mensaje":"La información ingresada es incorrecta, verifica e intenta nuevamente.",
            "preguntas":[
                {
                    "title": "¿Cuándo se genera?",
                    "respuesta": ["Cuando se colocan menos de 8 dígitos en la clave de pago en la pantalla de ingreso de la clave para autorizar el pago, al realizar operaciones de Pago móvil C2P."],
                    "subrespuesta":[]
                },
    
                {
                    "title": "¿Qué debes hacer?",
                    "respuesta": ["Verificar la clave de pago ingresada e intentarlo nuevamente."],
                    "subrespuesta":[]
                },
            ]
        },

        {
            "icon":"warning",
            "mensaje":"Verifica la conexión al servicio de ubicación de tu dispositivo e intenta nuevamente.",
            "preguntas":[
                {
                    "title": "¿Cuándo se genera?",
                    "respuesta": ["Cuando por un error de hardware o software tu dispositivo pierde la conexión al servicio de ubicación y la plataforma Dpos no puede capturar con éxito las coordenadas de geolocalización del mismo."],
                    "subrespuesta":[]
                },
    
                {
                    "title": "¿Qué debes hacer?",
                    "respuesta": ["Verificar la conexión al servicio de ubicación de tu dispositivo e intentar tu operación nuevamente."],
                    "subrespuesta":[]
                },
            ]
        },


        {
            "icon":"error",
            "mensaje":"Tu operación no puede ser procesada porque te encuentras en una zona no autorizada.",
            "preguntas":[
                {
                    "title": "¿Cuándo se genera?",
                    "respuesta": ["Cuando intentas iniciar sesión o realizar una transacción en una zona que el banco configuró como no autorizada."],
                    "subrespuesta":[]
                },
    
                {
                    "title": "¿Qué debes hacer?",
                    "respuesta": ["No podrás iniciar sesión o realizar una transacción mientras te encuentres en esa zona."],
                    "subrespuesta":[]
                },
            ]
        }
    ]
}