import React, { useState, useEffect } from 'react';

import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import Avatar from '@material-ui/core/Avatar';
import Link from '@material-ui/core/Link';
import { useHistory } from "react-router-dom";
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios'
import { BaseURL, sendMailAdmin, updatePasswordAdmin, MasterKeyLogin, loginApi } from '../constants/constants'
import Backdrop from '@material-ui/core/Backdrop';
import icnLock from '../assets/icn_lock.png'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from "@material-ui/core/IconButton";
import lgMercantil from "../assets/m_cn_rgb_sin_bg.png"
import FooterOut from '../componentUtils/FooterOut';
import { connect } from 'react-redux'
import { getDialogManager } from '../actions';
import { getContextApp } from '../actions/index';
import InputAdornment from '@material-ui/core/InputAdornment';
import Closeeye from '../assets/closeeye.png'
import Openeye from '../assets/openeye.png'
import CryptoJS from 'crypto-js'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import superagent from 'superagent'

const useStyles = makeStyles(theme => ({
  rootTextfieldClass: {
    "& .MuiFormLabel-root.Mui-error": {
      "&.shrink": {
        color: "#fb665a"
      },
      '&:after': { //focused
        borderBottomColor: "#fb665a",
      },

      color: "#fb665a"
    },

    "& .MuiInput-underline.Mui-error:after": {
      borderBottomColor: "#fb665a !important"
    },




  },
  rootTextfield: {

    "&.shrink": {
      color: "#004E9B"
    }
  },
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
  paper: {
    marginTop: theme.spacing(20),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "white",
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: "15%",
  },
  submit: {
    color: "white",
    margin: theme.spacing(3, 0, 2),
    borderRadius: "25px",
    background: "linear-gradient(180deg, #004E9B, #009FDA)",
    textTransform: "capitalize",
    width: "150px",
  },
  buttonProgress: {
    color: "#1f2d3d",
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  logoLockStyle: {

    width: "25px",
    height: "25px",
  },
  gridCentral: {
    marginTop: "5%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  footerGrid: {
    width: "100%",
    position: "fixed",
    zIndex: "1000",
    bottom: "0",
    height: 50,
    paddingLeft: "10px",
    paddingRight: "10px",
    backgroundColor: "#EBEBEB",
  },
  logoMercantilStyle: {
    width: "171px",
    height: "61px",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: "#000048"
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  gridCentral: {
    marginTop: "5%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  iconPass: {
    height: 25,
    width: 25
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

//¡Listo! Has cambiado tu contraseña
function ForgotPassword({ getContextApp, getDialogManager, changeContext, changeDialogManagerValues }) {
  const classes = useStyles();
  let history = useHistory();
  const [email, setEmail] = useState("")
  const [emptymail, setEmptyMail] = useState(false)
  const [loader, setLoader] = useState(false)
  const [loaderBackdrop, setLoaderBackdrop] = useState(false)
  const [onCheck, setOnChecked] = useState(false)
  const [open, setOpen] = useState(false);
  const [msg, setMsgs] = useState("")
  const [password, setPassword] = useState("");
  const [claveUnica, setClaveUnica] = useState("");
  const [emptyClaveUnica, setEmptyClaveUnica] = useState(false);
  const [emptyPassword, setEmptyPassword] = useState(false);
  const [visibilityPass, setVisibilityPass] = useState(false)
  const [token, setToken] = useState("")
  const [codigo, setCodigo] = useState("")
  const [counterValid, setCounterValid] = useState({
    countNumbersValid: false,
    countCharsValid: false,
    countSpecialCharsValid: false,
    countTotalsValid: false,
  })
  let numbers = ""
  let characters = ""
  let especialChars = ""


  useEffect(() => {
    console.log("useEffect ", getDialogManager.value)
    if (getDialogManager.value) {
      login()
      console.log("useEffect ", getDialogManager.value)
    }
  }, [getDialogManager.value])

  function resetPassword(event) {


    event.preventDefault()

    let ismailEmpty = isEmpty(email)
    if (ismailEmpty) {
      return
    }
    setLoader(true)
    let payload = {
      correo: email
    }
    axios.post(BaseURL + sendMailAdmin, payload)
      .then(function (response) {
        setLoader(false)
        if (response.data.exito) {

          setMsgs(response.data.Message)
          setToken(response.data.Token)
          setCodigo(response.data.Codigo)
          let object = {
            context: "change"
          }
          changeContext(object)
        }
        else {
          setMsgs(response.data.Message)
          handleClickOpen("El correo electrónico ingresado no está registrado", "error")

        }

      })
      .catch(function (error) {
        setLoader(false)
        setMsgs("Error en el servidor")
      });
    //history.push("/resetpassword");
  }
  function isEmpty(field) {
    let pattern = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
    if (field === "") {
      setEmptyMail(true)
      return true
    } else {
      if (!pattern.test(field)) {
        handleClickOpen("Verifica el correo electrónico ingresado e intenta nuevamente", "error")
        setEmptyMail(true)
        return true
      } else {
        setEmptyMail(false)
        return false
      }

    }
  }//esta funcion se va adecuar despues

  function fieldEmpty(field, type) {
    let status = false

    console.log("field ", field)
    console.log("codigo ", codigo)
    switch (type) {
      case "claveUnica":
        if (field === "") {
          setEmptyClaveUnica(true)
          // handleClickOpen("Verifica la clave temporal ingresada e intenta nuevamente","error") este mensaje va cuando se verifique la clave unica
          status = true
        } else {
          if (field !== codigo.toString()) {
            handleClickOpen("Verifica la clave temporal ingresada e intenta nuevamente", "error")
            setEmptyClaveUnica(true)
            status = true
          } else {
            setEmptyClaveUnica(false)
            status = false
          }

        }
        break;
      case "contrasena":

        if (field === "") {
          setEmptyPassword(true)
          status = true
        } else {
          if (counterValid.countNumbersValid && counterValid.countCharsValid && counterValid.countSpecialCharsValid && counterValid.countTotalsValid) {
            setEmptyPassword(false)
            status = false
          } else {
            handleClickOpen("La contraseña ingresada no cumple con las condiciones requeridas", "error")
            setEmptyPassword(true)
            status = true
          }


        }
        break;
      default:
        break;
    }
    return status
  }
  const handleClickOpen = (msg, icon) => {
    let object = {
      open: true,
      message: msg,
      type: "alert",
      icon: icon
    }
    changeDialogManagerValues(object)
  };
  const handleClickShowPassword = (e) => {
    e.preventDefault();

    setVisibilityPass(!visibilityPass)


  };
  function resetPasswordGo(event) {
    let isclaveUnicaEmpty = fieldEmpty(claveUnica, "claveUnica")
    let ispasswordEmpty = fieldEmpty(password, "contrasena")
    if (isclaveUnicaEmpty || ispasswordEmpty || !counterValid.countTotalsValid || !counterValid.countCharsValid || !counterValid.countSpecialCharsValid || !counterValid.countSpecialCharsValid) {
      return
    } else {

      setLoader(true)
      var cipherpassword = CryptoJS.AES.encrypt(password, "l061nu53r");
      var payload = {
        password: cipherpassword.toString(),
        mail: email,
        token: token,
        codigo: codigo
      }
      axios.post(BaseURL + updatePasswordAdmin, payload)
        .then(function (response) {
          setLoader(false)
          console.log(response)
          if (response.data.Exito) {

            setMsgs(response.data.Message)

            handleClickOpen("¡Listo! Has cambiado tu contraseña", "success")

          }
          else {
            setMsgs(response.data.Message)

            handleClickOpen("Verifica la clave temporal ingresada e intenta nuevamente", "error")



          }

        })
        .catch(function (error) {
          setLoader(false)
          setMsgs("Error en el servidor")
        });

    }
  }
  const handleTypeCharPassword = (e) => {
    e.preventDefault()

    for (let i = 0; i < e.target.value.length; i++) {
      if (!isNaN(e.target.value.charAt(i))) {
        numbers += e.target.value.charAt(i)
      } else if ((/[a-zA-Z]/).test(e.target.value.charAt(i))) {
        characters += e.target.value.charAt(i)
      } else if ((/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/).test(e.target.value.charAt(i))) {
        especialChars += e.target.value.charAt(i)
      }


    }
    handlerValidate("number", numbers.length)
    handlerValidate("characters", characters.length)
    handlerValidate("spelcialChars", especialChars.length)
    handlerValidate("totalCharacters", e.target.value.length)
  }
  const handlerValidate = (typeChar, lengthString) => {

    switch (typeChar) {
      case "number":
        if (lengthString > 0 && lengthString <= 3) {
          setCounterValid((prevState) => ({
            ...prevState,
            countNumbersValid: true,
          }))

        } else {
          setCounterValid((prevState) => ({
            ...prevState,
            countNumbersValid: false,
          }))
          return
        }
        break;
      case "characters":

        if (lengthString >= 6 && lengthString <= 8) {
          setCounterValid((prevState) => ({
            ...prevState,
            countCharsValid: true,
          }))
        } else {
          setCounterValid((prevState) => ({
            ...prevState,
            countCharsValid: false,
          }))
          return
        }
        break;
      case "spelcialChars":

        if (lengthString === 1) {
          setCounterValid((prevState) => ({
            ...prevState,
            countSpecialCharsValid: true,
          }))
        } else {
          setCounterValid((prevState) => ({
            ...prevState,
            countSpecialCharsValid: false,
          }))
          return
        }
        break;
      case "totalCharacters":

        if (lengthString >= 8) {
          setCounterValid((prevState) => ({
            ...prevState,
            countTotalsValid: true,
          }))
        } else {
          setCounterValid((prevState) => ({
            ...prevState,
            countTotalsValid: false,
          }))
          return
        }
        break;
      default:
        break;
    }
  };

  function login() {


    var ciphertext = CryptoJS.AES.encrypt(email, MasterKeyLogin);
    var cipherpass = CryptoJS.AES.encrypt(password, MasterKeyLogin);
    var apiBaseUrl = BaseURL;
    var payload = {
      "Username": ciphertext.toString(),//"email.username",
      "Clave": cipherpass.toString()//"U2FsdGVkX19N8yY9zG2txQd4FXkCU91FPqVO4ovFAhg="
    }
    console.log("login ", payload)
    setLoaderBackdrop(true)

    superagent
      .post(apiBaseUrl + loginApi)
      .send(payload) // sends a JSON post body
      .then(response => {
        setLoaderBackdrop(false)
        console.log("response login ", response)
        if (response.body.exito) {
          console.log("empresa ", response.body.data_usuario)
          let id = response.body.data_usuario.id_usuario
          let id_empresa = response.body.data_usuario.id_empresa
          let rif = response.body.data_usuario.rif
          let limite_usuarios = response.body.data_usuario.limite_usuarios
          let ultima_sesion = response.body.data_usuario.ultima_sesion
          let razon_social = response.body.data_usuario.razon_social

          let identificador_comercio = response.body.data_usuario.identificador_comercio
          let telefono = response.body.data_usuario.telefono
          let correo = response.body.data_usuario.correo
          let direccion = response.body.data_usuario.direccion
          let cryptorif = CryptoJS.AES.encrypt(rif, MasterKeyLogin);
          let cryptoid = CryptoJS.AES.encrypt(id.toString(), MasterKeyLogin);
          let cryptoidempresa = CryptoJS.AES.encrypt(id_empresa.toString(), MasterKeyLogin);
          let cryptoultimasesion = CryptoJS.AES.encrypt(ultima_sesion.toString(), MasterKeyLogin);
          let cryptorazon_social = CryptoJS.AES.encrypt(razon_social.toString(), MasterKeyLogin);
          let cryptotelefono = CryptoJS.AES.encrypt(telefono.toString(), MasterKeyLogin);
          let cryptoidentificador_comercio = CryptoJS.AES.encrypt(identificador_comercio.toString(), MasterKeyLogin);
          let cryptocorreo = CryptoJS.AES.encrypt(correo.toString(), MasterKeyLogin);
          let cryptodireccion = CryptoJS.AES.encrypt(direccion.toString(), MasterKeyLogin);
          sessionStorage.setItem("_CDAUPOMGKLJL", cryptorif.toString())
          sessionStorage.setItem("_CDAUPOMGKLJLTYJIKH", cryptoid.toString())
          sessionStorage.setItem("_CDAUPOMGKLJLTYJIKHENTER", cryptoidempresa.toString())
          sessionStorage.setItem("_CDAUPOMGKLJLTYJIKHENTERLIM", limite_usuarios.toString())
          sessionStorage.setItem("_CDAUPOMGKLJLTYJIKHENTERLIMETRERT", cryptoultimasesion.toString())
          sessionStorage.setItem("_CDAUPOMGKLJLTYJIKHENTERLIMETRERTRAZN", cryptorazon_social.toString())
          sessionStorage.setItem("_CDAUPOMGKLJLTYJIKHENTERLIMETRERTLFN", cryptotelefono.toString())
          sessionStorage.setItem("_CDAUPOMGKLJLTYJIKHENTERLIMETRERIDFCT", cryptoidentificador_comercio.toString())
          sessionStorage.setItem("_CDAUPOMGKLJLTYJIKHENTERLIMETRERCOR", cryptocorreo.toString())
          sessionStorage.setItem("_CDAUPOMGKLJLTYJIKHENTERLIMETRERDIR", cryptodireccion.toString())

          history.push("/home");
        }

      })
      .catch(error => {
        setLoaderBackdrop(false)
        alert(error)
      })
  }
  return (
    <React.Fragment>
      {

        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Backdrop className={classes.backdrop} open={loaderBackdrop}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <div className={classes.root}>
            <AppBar position="absolute" className={classes.appBar} >
              <Toolbar style={{ background: "linear-gradient(90deg, rgba(0,78,159,1) 0%, rgba(1,154,216,1) 12%, rgba(0,78,155,1) 39%)" }}>
                <IconButton edge="start" color="inherit" className={classes.menuButton}  >
                  {/* <MenuIcon /> */}

                </IconButton>
                <img src={lgMercantil} className={classes.logoMercantilStyle} />
                <Typography variant="h6" className={classes.title}>
                </Typography>
                <label style={{ fontSize: "18px", textTransform: "capitalize", }}>Portal Dpos</label>
              </Toolbar>
            </AppBar>
          </div>

          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              {/* <LockOutlinedIcon /> */}
              <img src={icnLock} className={classes.logoLockStyle}></img>
            </Avatar>
            <Typography component="h1" variant="h6">
              Recupera tu contraseña
            </Typography>
            <Typography component="h3" variant="h7" style={{ fontWeight: "350", color: "gray", textAlign: "justify", paddingTop: "5%" }}>
              {getContextApp === "change" ? "Hemos enviado a tu correo electrónico una clave temporal para que puedas crear tu nueva contraseña e iniciar tu sesión en el Portal Dpos" : "Ingresa el correo electrónico con el que estás registrado en el Portal Dpos y una clave temporal te será enviada a tu cuenta"}

            </Typography>

            <form className={classes.form} >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  {
                    getContextApp === "change" ?
                      <TextField
                        error={emptyClaveUnica}
                        required
                        fullWidth
                        id="clave"
                        label="Clave temporal"
                        value={claveUnica}
                        name="clave"
                        autoComplete="off"
                        disabled={loader}
                        className={classes.rootTextfieldClass}
                        inputProps={{ maxLength: 30 }}
                        InputLabelProps={{
                          classes: {
                            root: classes.rootTextfield,
                            focused: "focused",
                            shrink: "shrink"
                          }
                        }}
                        onChange={e => setClaveUnica(e.target.value)}
                      /> :
                      <TextField
                        error={emptymail}
                        required
                        fullWidth
                        id="email"
                        label="Correo electrónico"
                        value={email}
                        name="email"
                        autoComplete="off"
                        disabled={loader}
                        className={classes.rootTextfieldClass}
                        inputProps={{ maxLength: 30 }}
                        InputLabelProps={{
                          classes: {
                            root: classes.rootTextfield,
                            focused: "focused",
                            shrink: "shrink"
                          }
                        }}
                        onChange={e => setEmail(e.target.value)}
                      />

                  }
                </Grid>
                {getContextApp === "change" &&
                  <>

                    <Grid item xs={12}>
                      <TextField
                        error={emptyPassword}
                        required
                        fullWidth
                        id="clave"
                        label="Contraseña"
                        value={password}
                        name="clave"
                        className={classes.rootTextfieldClass}
                        type={visibilityPass ? 'text' : 'password'}
                        disabled={loader}
                        inputProps={{ maxLength: 30 }}
                        onChange={(e) => { setPassword(e.target.value); handleTypeCharPassword(e) }}
                        InputLabelProps={{
                          classes: {
                            root: classes.rootTextfield,
                            focused: "focused",
                            shrink: "shrink"
                          }
                        }}
                        InputProps={{
                          endAdornment: (

                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={e => handleClickShowPassword(e, "confirm")}
                              // onMouseDown={handleMouseDownPassword}
                              >
                                <img alt="edit" src={visibilityPass ? Openeye : Closeeye} className={classes.iconPass} />

                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                    </Grid>
                    <Grid container >
                      <Grid item xs={6}>

                        {counterValid.countTotalsValid ? <CheckCircleIcon style={{ color: "#004E9B", marginLeft: 10, marginTop: 10 }} fontSize="small" /> : <CancelIcon style={{ color: "#fb665a", marginLeft: 10, marginTop: 10 }} fontSize="small" />}


                        <Typography variant="p" style={{ marginLeft: 10 }}>De 8 a 10 caracteres</Typography>

                      </Grid>
                      <Grid item xs={6}>
                        {counterValid.countNumbersValid ? <CheckCircleIcon style={{ color: "#004E9B", marginLeft: 10, marginTop: 10 }} fontSize="small" /> : <CancelIcon style={{ color: "#fb665a", marginLeft: 10, marginTop: 10 }} fontSize="small" />}


                        <Typography variant="p" style={{ marginLeft: 10 }}>1 a 3 números</Typography>
                      </Grid>

                      <Grid item xs={6}>
                        {counterValid.countCharsValid ? <CheckCircleIcon style={{ color: "#004E9B", marginLeft: 10, marginTop: 10 }} fontSize="small" /> : <CancelIcon style={{ color: "#fb665a", marginLeft: 10, marginTop: 10 }} fontSize="small" />}

                        <Typography variant="p" style={{ marginLeft: 10 }}>Mínimo 6 letras</Typography>

                      </Grid>
                      <Grid item xs={6}>
                        {counterValid.countSpecialCharsValid ? <CheckCircleIcon style={{ color: "#004E9B", marginLeft: 10, marginTop: 10 }} fontSize="small" /> : <CancelIcon style={{ color: "#fb665a", marginLeft: 10, marginTop: 10 }} fontSize="small" />}


                        <Typography variant="p" style={{ marginLeft: 10, textAlign: "center" }}>1 caracter especial</Typography>
                        <br></br>
                        <Typography variant="p" style={{ marginLeft: 25, textAlign: "center" }}>ejemplo : (*,_,@,#,$,^,-)</Typography>
                      </Grid>

                    </Grid>

                  </>
                }
              </Grid>

              <Grid container className={classes.gridCentral}>
                <Grid item xs>
                  <Button
                    type="button"
                    fullWidth
                    color="white"
                    className={classes.submit}
                    disabled={loader}
                    onClick={
                      (event) => {
                        if (getContextApp === "change") {
                          resetPasswordGo(event)
                        } else {
                          resetPassword(event)
                        }

                      }
                    }
                  >
                    {loader && <CircularProgress size={24} className={classes.buttonProgress} />}
                    {getContextApp !== "change" ? "Enviar" : "Ingresar"}
                  </Button>
                </Grid>
              </Grid>



              {getContextApp !== "change" &&
                <Grid container className={classes.gridCentral}>
                  <Grid item xs={12}>
                    <Link href="./login" variant="body2">
                      Inicia tu sesión
                    </Link>
                  </Grid>
                </Grid>
              }


            </form>
          </div>
          {/* <Box mt={5}>
          <Copyright />
        </Box> */}

          <FooterOut page="forgotpassword" />

        </Container>
      }
    </React.Fragment>

  );
}
const mapStateToProps = state => {

  return {
    getContextApp: state.getContext.getContext.context,
    getDialogManager: state.getDialogManager.dialogManager.getDialogManager,
  };
}

const mapDispatchToProps = (dispatch) => ({
  changeContext(args) {
    dispatch(getContextApp(args))
  },

  changeDialogManagerValues(args) {
    dispatch(getDialogManager(args))
  },

})
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)