import { MENU_DRAWER } from "../constants/typesRedux";
const initialState = {
    menuDrawerStatus:
        {
            open: false
        }
    
}

const getMenuDrawerManager = (state = initialState, action) => {
    
    
    switch (action.type) {

        case MENU_DRAWER:
                state = {
                    menuDrawerStatus:{
                        open: action.menuDrawerStatus
                    }
                }
            break;       
        default:
            break;
    }
    return state
};


export default getMenuDrawerManager;