import React,{useEffect,useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import  Grid  from '@material-ui/core/Grid';
//import Accordeon from './components/Accordeon';
import Typography from '@material-ui/core/Typography';
import ic_instructivo from '../../../assets/ic_instructivo.png';
import download from '../../../assets/download.png';
import Card from '@material-ui//core/Card';
import CardActions from '@material-ui//core/CardActions';
import CardContent from '@material-ui//core/CardContent';
import Button from '@material-ui/core/Button';

import JsFileDownloader from 'js-file-downloader';

const fileUrl =  process.env.REACT_APP_DPOS_INSTRUCTIVO


const useStyles = makeStyles(theme => ({
    title: {
        color :"#004E9B",
        marginTop:40,
        marginBottom:20,
        fontSize :18,
    },
    subtitle:{
        color:"gray",
        fontSize :15,
    
    },
    image:{
        height:25,
        paddingLeft: "10px"
    },

    imageInstructivo:{

        height:140
    },
    imageDescargar:{
        height:40,
        width:41
    }
}))

function handleClickDownload(){


    new JsFileDownloader({ 
        url: fileUrl
      })
      .then(function () {
        // Called when download ended
      })
      .catch(function (error) {
          
        // Called when an error occurred
      });
}

export default function Instructivo() {
   
    const classes = useStyles();
    
   
    return (
        <React.Fragment>
            <Grid container spacing={5} style={{marginLeft:"5%"}} >
                <Grid item xs={12} spacing={6}>
                    <Typography variant="h5" className={classes.title}>Instructivos </Typography>
                    <Typography variant="p" className={classes.subtitle}>En esta sección encontrarás los pasos para realizar distintas operaciones y solicitudes con </Typography> <strong style={{color: "#004E9B"}}>Dpos</strong>

                </Grid>
               
                
                    <Card sx={{ minWidth: 275 }} style={{marginLeft:"2%"}} >
                        <CardContent justifyContent="center" style={{width:"200px",height:"300px"}}>
                            
                            <Grid content justifyContent direction="column" style={{textAlign:"center",marginTop:"20%"}}>
                                <Grid item xs>
                                        <img src={ic_instructivo} className={classes.imageInstructivo}/>
                                </Grid>
                                <Grid item xs>
                                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom style={{marginTop:"10%"}}>
                                        Instructivo Dpos
                                    </Typography>
                                </Grid>
                                <Grid item xs>
                                    <Typography sx={{ fontSize: 10 }} variant="h8" component="div">
                                        Versión 1.0
                                    </Typography>
                                </Grid>
                                
                            </Grid>
                            
                            </CardContent>
                            <CardActions style={{justifyContent: 'center',marginBottom:"5%"}}>
                                <img src={download} className={classes.imageDescargar} onClick={handleClickDownload}/>

                                {/* <Button onClick={handleClickDownload} size="small" style={{backgroundColor:"#009FDA",color:"white",transform:"capitalize"}}>Descargar</Button> */}
                            </CardActions>
                    </Card>

                    {/* <Accordeon/> */}
                
            </Grid>
        </React.Fragment>
        
    );
}