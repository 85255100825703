/* eslint-disable no-console */
import React, { useState } from 'react';

import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
//import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import Avatar from '@material-ui/core/Avatar';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import jwt from  'jsonwebtoken'
import { useHistory } from "react-router-dom";
import axios from 'axios'
import CryptoJS from 'crypto-js'
import icnLock from '../assets/icn_lock.png'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Buttons from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import {BaseURL, updatePasswordAdmin, MasterKeyLogin} from '../constants/constants'
//import axios from 'axios';
import FooterOut from '../componentUtils/FooterOut';
import { AppBar, IconButton ,Toolbar} from '@material-ui/core';
import lgMercantil from "../assets/m_cn_rgb_sin_bg.png"
import  InputAdornment  from '@material-ui/core/InputAdornment';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';



const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
    paper: {
      marginTop: theme.spacing(20),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: "white",
    },
    logoLockStyle:{

      width:"25px",
      height:"25px",
    },
    gridCentral:{
      marginTop:"5%",
      display:"flex",
      alignItems:"center",
      flexDirection:"column",
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(3),
    },
    submit: {
      color:"white",
      margin: theme.spacing(3, 0, 2),
      borderRadius:"25px",
      background: "linear-gradient(180deg, rgba(75,185,227,1) 0%, rgba(76,130,184,1) 100%)",
      textTransform:"capitalize",
      width:"150px",
    },
      buttonProgress: {
        color: "#1f2d3d",
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
      },
      logoMercantilStyle:{
        width:"171px",
        height:"61px",
      },
      appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor:"#000048"
      },
  }));

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

//class ForgotPassword extends Component {
    export default function ResetPassword(props){
        const classes = useStyles();
        const [password, setPassword] = useState("");
        const [password2, setPassword2] = useState("");
        const [emptyPassword,setEmptyPassword]= useState(false)
        const [loader,setLoader]=useState(false)
        const [open, setOpen] =useState(false);
        const [msg, setMsgs]= useState("")
        const [visibilityPass,setVisibilityPass] = useState(false)
        const [visibilityConfirm,setVisibilityConfirm] = useState(false)
        let history = useHistory();
       let token = props.match.params.token
       
       let crypto_mail = props.match.params.mail
      
       var cipherformat2 = crypto_mail.replace("Por21Ld",/\//g)
      
       var decrypt_mail  = CryptoJS.AES.decrypt(cipherformat2, 'l061nu53r');
      
       var mail = decrypt_mail.toString(CryptoJS.enc.Utf8);

       
   
       
       jwt.verify(token, 'secret key', function(err, user) {
        if (err) {
          
          history.push("/404");
       }
       })
    
       function resetPassword(event){
        if(password ===""|| password2 ===""){
          setEmptyPassword(true)
          return
         }
         if(password != password2){
          setEmptyPassword(false)
          handleClickOpen()
        }else{
          setEmptyPassword(false)
          setLoader(true)
          var cipherpassword = CryptoJS.AES.encrypt(password, "l061nu53r");
          var payload= {
            password : cipherpassword.toString(),
            mail: mail
          }
          axios.post(BaseURL+updatePasswordAdmin, payload)
          .then(function (response) {
            setLoader(false)
                if(response.data.Exito){
                  setMsgs(response.data.Message)
                  
                  handleClickOpen()
                  
                }
                else{   
                  setMsgs(response.data.Message)
                  handleClickOpen()
                }
               
          })
          .catch(function (error) {
            setLoader(false)
            setMsgs("Error en el servidor")
          });

        }
       }
       const handleClickOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
        document.getElementById("password1").value = ""
        document.getElementById("password2").value = ""
        
      };

      const handleClickShowPassword =(e,type) => {
        e.preventDefault();
        if(type === "pass"){
          setVisibilityPass(!visibilityPass)
        }else{
          setVisibilityConfirm(!visibilityConfirm)
        }
        
      };
    return (
      <React.Fragment>
<Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">{"Mensaje"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
             {msg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Buttons onClick={handleClose} color="primary">
              Ok
            </Buttons>
          </DialogActions>
        </Dialog>
        <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.root}>
          
          <AppBar position="absolute" className={classes.appBar} >
          <Toolbar style={{background :"linear-gradient(90deg, rgba(0,78,159,1) 0%, rgba(1,154,216,1) 12%, rgba(0,78,155,1) 39%)"}}>
            <IconButton edge="start" color="inherit" className={classes.menuButton}  >
              {/* <MenuIcon /> */}
             
            </IconButton>
            <img src={lgMercantil} className={classes.logoMercantilStyle}></img>
            <Typography variant="h6" className={classes.title}>
            </Typography>
            <label style={{fontSize:"18px",textTransform:"capitalize"}}>Portal Dpos</label>
          </Toolbar>
        </AppBar>
        </div>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
          <img src={icnLock} className={classes.logoLockStyle}></img>
          </Avatar>
          <Typography component="h1" variant="h6">
            Recupera tu contraseña
          </Typography>
          <Typography component="h3" variant="h7" style={{fontWeight: "350",color: "gray",textAlign: "justify",paddingTop: "5%"}}>
          Hemos enviado a tu correo electrónico una clave temporal para que puedas crear tu nueva contraseña e iniciar tu sesión en el Portal Dpos
          </Typography>
          

        <form className={classes.form} >
          <Grid container spacing={2}>
          <Grid item xs={12}>
                  <TextField
                      error = {emptyPassword}
                     
                      margin="normal"
                      required
                      fullWidth
                      name="Clave temporal"
                      label="Clave temporal"
                      type={'text' }
                      id="password1"
                      disabled = {loader}
                      //onChange ={e => setPassword(e.target.value)}
                      helperText={emptyPassword?"Campo vacio":""}
                    
                    />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                      error = {emptyPassword}
                     
                      margin="normal"
                      required
                      fullWidth
                      name="correo"
                      label="Correo electrónico"
                      type={'text'}
                      id="correo"
                      disabled = {loader}
                      onChange ={e => setPassword(e.target.value)}
                      helperText={emptyPassword?"Campo vacio":""}
                      
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                          error = {emptyPassword}
                          
                          margin="normal"
                          required
                          fullWidth
                          name="Contrasena2"
                          label="Confirmar Contraseña"
                          type={visibilityConfirm ? 'text' : 'password'}
                          id="password2"
                          disabled = {loader}
                          onChange ={e => setPassword2(e.target.value)}
                          helperText={emptyPassword?"Campo vacio":""}
                          InputProps={{
                            endAdornment:(
                            
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={e=>handleClickShowPassword(e,"confirm")}
                                  // onMouseDown={handleMouseDownPassword}
                                >
                                  {!visibilityConfirm ? <VisibilityOffIcon/>:<VisibilityIcon />}
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                        />
                </Grid>
               
            </Grid>
            <Grid container className={classes.gridCentral}>
              <Grid item xs>
            <Button
              
              type="button"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              //className={classes.submit}
              disabled = {loader}
              onClick={(event) => resetPassword(event)}
            >
              {loader && <CircularProgress size={24} className={classes.buttonProgress} />}
              Guardar
            </Button>
            </Grid>
            </Grid>

        </form>
        </div>
       <FooterOut page = "forgotpassword"/>
        </Container>
      </React.Fragment>
      
    );
  // render} 
}

//export default ForgotPassword;