import React from 'react';
import {makeStyles,Grid} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
 
    footerGrid: {
        width: "100%",
        position:"fixed",
        zIndex:"1000",
        bottom:"0",
        height: 40,
        paddingLeft:"10px",
        paddingRight:"10px",
        backgroundColor: "#EBEBEB",
      },
    
}))

export default function FooterOut ({page}) {
const classes = useStyles();

return (

<React.Fragment>
{
    page === "login"?
            <Grid container justify="space-between" >
              <Grid item xs={12} alignItems="center" className={classes.footerGrid}>
                {/* <Copyright /> */}
                <p style={{position:"fixed",textAlign:"center",fontSize:"10px",wordWrap:"break-word",}}>Mercantil C.A. Banco Universal
    Rif J-00002961-0 © Copyright - Todos los derechos reservados por Appinventor Rif: J-40713049-8
                </p> 
                {/* {' '}{new Date().getFullYear()}{'.'} {"\n"}From NextPOS ®            */}
              </Grid>
            </Grid>:
    page === "dashboard"? 
    <Grid container style={{display:"flex",flexDirection:"column",alignItems:"center"}} >
    <Grid item xs={12} alignItems="center" className={classes.footerGrid}>
      {/* <Copyright /> */}
      <p style={{textAlign:"center",fontSize:"10px",wordWrap:"break-word",}}>La información mostrada en esta página es confidencial
      </p>            
    </Grid>
  </Grid>
    :  
            <Grid container style={{display:"flex",flexDirection:"column",alignItems:"center" }} >
            <Grid item xs={12} alignItems="center" className={classes.footerGrid}>
              {/* <Copyright /> */}
              <p style={{textAlign:"center",fontSize:"10px",wordWrap:"break-word",}}>Mercantil C.A. Banco Universal
    Rif J-00002961-0 © Copyright - Todos los derechos reservados por Appinventor Rif: J-40713049-8
              </p>            
            </Grid>
      </Grid>

}
   
</React.Fragment>
);
}