import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import PeopleIcon from '@material-ui/icons/People';
import BarChartIcon from '@material-ui/icons/BarChart';
import LayersIcon from '@material-ui/icons/Layers';
import AssignmentIcon from '@material-ui/icons/Assignment';
import LibraryBooksOutlinedIcon from '@material-ui/icons/LibraryBooksOutlined';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import { Link } from "react-router-dom";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import logoDpos from "../assets/dpos_icon2.png"
import ic_user from "../assets/ic_user.png"

import FindInPageOutlinedIcon from '@material-ui/icons/FindInPageOutlined';

const logout = event => {
    
  localStorage.removeItem("_CDAUPOMGKLJL") || sessionStorage.removeItem("_CDAUPOMGKLJL")
  localStorage.removeItem("_CDAUPOMGKLJLTYJIKH") || sessionStorage.removeItem("_CDAUPOMGKLJLTYJIKH")
  window.location.reload();
};

let open = false;

  const handleClick = () => {
    open = !open;
  };
export const mainListItems =  (

  <div>
    <ListItem button component = {Link} to="/home">
      <ListItemIcon>
          <img src={logoDpos} style={{ height:45,width:50 }}></img>
      </ListItemIcon>
      <ListItemText secondary="Resumen transacciones" />

      
    </ListItem>
    <ListItem button component = {Link} to="/create">
      <ListItemIcon>
          <img src={ic_user} style={{ height:30,width:30 }}></img>
      </ListItemIcon>
      <ListItemText secondary="Gestionar usuarios" />

      
    </ListItem>

  </div>
  
);

export const secondaryListItems = (
  <div>
    <ListItem button onClick={handleClick}>
      <ListItemIcon>
        <FindInPageOutlinedIcon  style={{ color: "#1a80e6"}}/>
      </ListItemIcon>
      <ListItemText secondary="Reportes de ventas" />
      {open ? <ExpandLess /> : <ExpandMore />}
      
    </ListItem>
    <ListItem button component = {Link} to="/create">
      <ListItemIcon>
        <LibraryBooksOutlinedIcon style={{ color: "#1a80e6"}}/>
      </ListItemIcon>
      <ListItemText secondary="Instructivos" />
    </ListItem>

    <ListItem button component = {Link} to="/create">
      <ListItemIcon>
        <HelpOutlineOutlinedIcon style={{ color: "#1a80e6"}}/>
      </ListItemIcon>
      <ListItemText secondary="Ayuda" />
    </ListItem>
    <ListItem button onClick={logout} type="submit">
      <ListItemIcon>
        <ExitToAppOutlinedIcon style={{ color: "#1a80e6"}}/>
      </ListItemIcon>
      <ListItemText secondary="Cerrar sesión" />
    </ListItem>
    
  </div>
);


