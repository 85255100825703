import React,{useEffect} from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Buttons from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import {connect} from 'react-redux'
import { getDialogManager } from '../actions/index';
import IcError from '../assets/ic_error.png';
import IcWarning from '../assets/ic_warning.png';
import IcSuccess from '../assets/ic_success.png';
import checkout from '../assets/checkout.png';
import { useMediaQuery } from 'react-responsive';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });


  const useStyles = makeStyles(theme => ({
   
    btnAccept: {
      margin: theme.spacing(3, 0, 2),
      borderRadius:"25px",
      background: "linear-gradient(180deg, #004E9B, #009FDA)",
      textTransform:"capitalize",
      width:"150px",
      color:"#fff",
    },
    icClassAlert:{
      position: "fixed",
      width: "45px",
      height: "45px",
      left:0,
      right:0,
      justifyContent:"center",
      margin:"auto",
      marginTop:"-40px"
    
    },
    
    
  }));
function AlertMod({getDialogManager,getContextApp,changeDialogManagerValues}) {
    const classes = useStyles();

    const isDesktopOrLaptop = useMediaQuery({
      minWidth: 1024,
     maxWidth:1440
    })
    const isDesktop = useMediaQuery({
      minWidth: 1440,
      maxWidth:1536
      
    })
    const isBigScreen = useMediaQuery({
      query: '(min-device-width:1536px)'
    })
    const handleClose = () => {
      let object = {
        open: false,
        message:getDialogManager.message,
        type:getDialogManager.type,
        icon:getDialogManager.icon,
        value:getContextApp=== "change" && getDialogManager.message==="¡Listo! Has cambiado tu contraseña"?"closeLogin":""
        
        
      };
      changeDialogManagerValues(object)
    };
  
    return (
      <div>
       
        <Dialog
          PaperProps={{
            style: {
              borderRadius:"20px",
              maxWidth : isDesktopOrLaptop ? "30%":isDesktop?"35%":isBigScreen?"20%":"80%",
              minWidth : isDesktopOrLaptop ? "30%":isDesktop?"35%":isBigScreen?"20%":"80%",
            },

          }}
         
          open={getDialogManager.open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          
        >
          <DialogTitle id="alert-dialog-slide-title" style={{justifyContent:"center"}}>{""}
          {getDialogManager.icon === "error"? 
          <img className={classes.icClassAlert} src={IcError}></img>:
          getDialogManager.icon === "warning"?
          <img className={classes.icClassAlert} src={IcWarning}/>:
          <img className={classes.icClassAlert} src={checkout}/>}
          
          </DialogTitle>
          <DialogContent >
            <DialogContentText id="alert-dialog-slide-description" style={{paddingTop:"10%",textAlign:"center"}}>
            {getDialogManager.message}
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{justifyContent:"center"}}>
            <Buttons
            type="button"
            variant="contained"
            
            className={classes.btnAccept}
            // disabled = {loader}
            onClick={handleClose}
            >
              Aceptar
            </Buttons>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
  const mapStateToProps = state => {

		

		return {


			getDialogManager : state.getDialogManager.dialogManager.getDialogManager,
      getContextApp : state.getContext.getContext.context
		};

	}

	
	const mapDispatchToProps =(dispatch) =>({

		changeDialogManagerValues(args){
      
			dispatch(getDialogManager(args))

		}

	})

	export default connect(mapStateToProps,mapDispatchToProps)(AlertMod)