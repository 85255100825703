import { DIALOG } from "../constants/typesRedux"; // type actions in constants (optional)

const initialState = {
    dialogManager:

    {

        getDialogManager:{
            open:false,
            message:"",
            type:"",
            icon:"",
            value:""

        }

    }
}
const getDialogManager  = (state = initialState, action) => {
        console.log("dialogManager ", action)
        switch (action.type) {
            case  DIALOG:
                state = {
                    dialogManager:{

                        getDialogManager:{
                                open:action.dialog.open,
                                message:action.dialog.message,
                                type:action.dialog.type,
                                icon:action.dialog.icon,
                                value:action.dialog.value,
                            
                            }

                        }
                    }
                break;
                default:
                break;
            }
            return state
        };
export default getDialogManager;