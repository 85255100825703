import React from 'react';
import { ResponsiveContainer,LineChart,CartesianGrid,XAxis,YAxis,Tooltip,Line } from 'recharts';
import { Container, Row, Col } from 'react-grid-system';
import { Chart } from "react-google-charts";
import {BaseURL, getTrxMonth , MasterKeyLogin} from '../../../constants/constants'
import axios from 'axios'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import BarComponent from '../Charts/BarComponent';
import { useMediaQuery } from 'react-responsive';

const useStyles = makeStyles(theme => ({
  titleChart:{
    fontWeight:'bold',
    marginBottom:40,
    color:"#004E9B",
       [theme.breakpoints.down('md')]: {
          textAlign:'center',
      },
      [theme.breakpoints.only('md')]: {
        textAlign:'center',
        marginTop:20,
        marginBottom:10,
    },
  },
  rechartsWrapper: {
    //width:'100%',
    //  [theme.breakpoints.down('md')]: {
    //     overflow: 'hidden',
    //     height:200
    //   },
    margin :"0 auto",
    [theme.breakpoints.down('md')]: {
      margin :"0",
      height:200
    },
  },

}))

export default function Graph(props) {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 1024px)'
  })
    const classes = useStyles();
    let rifMerchant = props.rif
    let rif = rifMerchant

    let apiBaseUrl =BaseURL;
   
    const [datachar, setStateDataChart]=React.useState({
        chartData: [],
      })
   
    React.useEffect((props) => {
    
        axios.get(apiBaseUrl+getTrxMonth+"?rif="+rif)
        .then(res => {
          //totalAmount = res.data.Sum
          //setStateTable({data:totalAmount})
          const data = res.data.message
          
         
          setStateDataChart({chartData:data})
        })
        .catch(err=>{
          
        })
      
      },[])
    return (
        <React.Fragment>
          {}
          <div className={classes.rechartsWrapper}>
           
          <ResponsiveContainer width={isDesktopOrLaptop && 700} height={isDesktopOrLaptop && 700}>
            <Container>
              <Typography className= {classes.titleChart}>
              Ventas Bs.
              </Typography>
              {datachar.chartData.length>0 && <BarComponent  datos ={datachar.chartData} tipo = {1}/>}
              
            
            </Container>
          </ResponsiveContainer>
         
        </div>
        </React.Fragment>
      );
}