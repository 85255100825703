import React, { useEffect, useState } from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import Orders from '../homeComponents/Orders';
import FilterComponent from '../reportsComponents/FilterComponent'

const useStyles = makeStyles(theme => ({
}))
export default function Reports({rif,flag,id,razon_social}) {

    
    return (

        <React.Fragment>
            
            <FilterComponent rif={rif} flag={flag} id={id} razon_social={razon_social}/>

            {/* <Orders rif = {rif} flag={"reports"}/> */}
        </React.Fragment>
    );
}