import React, { useState, useEffect } from 'react';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import PeopleIcon from '@material-ui/icons/People';
import BarChartIcon from '@material-ui/icons/BarChart';
import LayersIcon from '@material-ui/icons/Layers';
import AssignmentIcon from '@material-ui/icons/Assignment';
import LibraryBooksOutlinedIcon from '@material-ui/icons/LibraryBooksOutlined';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import { Link } from "react-router-dom";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import FindInPageOutlinedIcon from '@material-ui/icons/FindInPageOutlined';
import logoDpos from "../../../assets/dpos_icon2.png"
import ic_user from "../../../assets/ic_user.png"
import ic_consulta from "../../../assets/ic_consulta.png"
import ic_instructivo from "../../../assets/ic_instructivo.png"
import ic_about from "../../../assets/ic_about.png"
import ic_logout from "../../../assets/ic_logout.png"
import ic_identification from "../../../assets/icn_identificacion.png"
import { makeStyles } from '@material-ui/core/styles';
import { CSVLink } from "react-csv";
import axios from 'axios';
import { BaseURL, getTrxDetail } from '../../../constants/constants';
import ReactExport from "react-export-excel";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const useStyles = makeStyles(theme => ({
  nested: {
    paddingLeft: theme.spacing(3),
    fontSize: 10
  },

}));
export default function ListSecondaryItem({ rif }) {
  const classes = useStyles();
  const logout = event => {

    localStorage.removeItem("_CDAUPOMGKLJL") || sessionStorage.removeItem("_CDAUPOMGKLJL")
    localStorage.removeItem("_CDAUPOMGKLJLTYJIKH") || sessionStorage.removeItem("_CDAUPOMGKLJLTYJIKH")
    window.location.reload();
  };

  // const headers = [
  //   { label: 'Razón social', key: 'razon_social' },
  //   { label: 'Código de afiliación', key: 'identificador_comercio' },
  //   { label: 'Región', key: 'region_oficina' },
  //   { label: 'Código de oficina', key: 'cod_oficina' },
  //   { label: 'Oficina', key: 'region_oficina' },
  //   { label: 'Usuario', key: 'username' },
  //   { label: 'Modalidad de pago', key: 'modalidad' },
  //   { label: 'Tipo de transacción', key: 'descripcion' },
  //   { label: 'Estatus', key: 'estatus' },
  //   { label: 'Fecha', key: 'fecha' },
  //   { label: 'Hora', key: 'hora' },
  //   { label: 'Monto Transacción (Bs.)', key: 'monto' },
  //   { label: 'Lote', key: 'lote' },
  //   { label: 'Referencia', key: 'num_referencia' },
  //   { label: 'Tarjeta', key: 'tarjeta' },
  //   { label: 'Geolocalización', key: 'geolocalizacion' },
  //   { label: 'Marca', key: 'tipo_tarjeta' },
  // ];
  const [dataTrx, setDataTrx] = useState([])

  const [open, setOpen] = useState(false)

  const handleClick = () => {
    setOpen(!open)
  };

  useEffect(() => {
    getDetailTransactions()
  }, [])

  const getDetailTransactions = () => {
    let schema = {
      rif: rif
    }
    axios.post(BaseURL + getTrxDetail, schema)
      .then(res => {

        setDataTrx(res.data.data)
      })
      .catch(err => {

      })
  }

  return (

    <div>
      <ListItem button component={Link} to="/home">
        <ListItemIcon>
          <img src={logoDpos} style={{ height: 45, width: 45 }}></img>
        </ListItemIcon>
        <ListItemText secondary="Resumen de transacciones" />


      </ListItem>
      <ListItem button component={Link} to="/profile">
        <ListItemIcon>
          <img src={ic_identification} style={{ height: 20, width: 25 }}></img>
        </ListItemIcon>
        <ListItemText secondary="Datos del comercio" />


      </ListItem>
      <ListItem button component={Link} to="/create">
        <ListItemIcon>
          <img src={ic_user} style={{ height: 25, width: 25 }}></img>
        </ListItemIcon>
        <ListItemText secondary="Gestionar usuarios" />


      </ListItem>
      <ListItem button onClick={handleClick}>
        <ListItemIcon>
          {/* <FindInPageOutlinedIcon  style={{ color: "#1a80e6"}}/> */}
          <img src={ic_consulta} style={{ height: 25, width: 25 }}></img>
        </ListItemIcon>
        <ListItemText secondary="Reportes de transacciones" />
        {open ? <ExpandMoreIcon /> : <ChevronRightIcon />}

      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>

        {/* <ListItem button className={classes.nested} onClick={getDetailTransactions}> */}
        <ListItem button className={classes.nested} component={Link} to="/reports">
          <ListItemIcon>

          </ListItemIcon>
          <ListItemText secondary="Reporte detalle de transacciones" />
          {/* <ExcelFile element={<ListItemText secondary="Reporte detalles transacciones" />} filename="Reporte detalles transacciones">
                <ExcelSheet data={dataTrx} name="Reporte detalles transacciones">
                    <ExcelColumn label="Razón social" value="razon_social"/>
                    <ExcelColumn label="Código de afiliación" value="identificador_comercio"/>
                    <ExcelColumn label="Región" value="region_oficina"/>
                    <ExcelColumn label="Código de oficina" value="cod_oficina"/>
                    <ExcelColumn label="Oficina" value="region_oficina"/>
                    <ExcelColumn label="Usuario" value="username"/>
                    <ExcelColumn label="Modalidad de pago" value="modalidad"/>
                    <ExcelColumn label="Tipo de transacción" value="descripcion"/>
                    <ExcelColumn label="Estatus" value="estatus"/>
                    <ExcelColumn label="Fecha" value="fecha"/>
                    <ExcelColumn label="Hora" value="hora"/>
                    <ExcelColumn label="Monto Transacción (Bs.)" value="monto"/>
                    <ExcelColumn label="Lote" value="lote"/>
                    <ExcelColumn label="Referencia" value="num_referencia"/>
                    <ExcelColumn label="Tarjeta" value="tarjeta"/>
                    <ExcelColumn label="Geolocalización" value="geolocalizacion"/>
                    <ExcelColumn label="Marca" value="tipo_tarjeta"/>
                </ExcelSheet>
              
            </ExcelFile> */}

          {/* <CSVLink data={dataTrx} headers = {headers} filename={"reporte detalles transaciones.xls"}><ListItemText secondary="Reporte detalles transacciones" /></CSVLink>; */}
        </ListItem>
        <ListItem button className={classes.nested} component={Link} to="/reportsSoldDay">
          <ListItemIcon>

          </ListItemIcon>
          <ListItemText secondary="Reporte de ventas diarias" />

        </ListItem>

      </Collapse>
      <ListItem button component={Link} to="/instructivo">
        <ListItemIcon>
          {/* <LibraryBooksOutlinedIcon style={{ color: "#1a80e6"}}/> */}
          <img src={ic_instructivo} style={{ height: 25, width: 25 }}></img>
        </ListItemIcon>
        <ListItemText secondary="Instructivos" />
      </ListItem>

      <ListItem button component={Link} to="/help">
        <ListItemIcon>
          {/* <HelpOutlineOutlinedIcon style={{ color: "#1a80e6"}}/> */}
          <img src={ic_about} style={{ height: 25, width: 25 }}></img>
        </ListItemIcon>
        <ListItemText secondary="Ayuda" />
      </ListItem>
      <ListItem button onClick={logout} type="submit">
        <ListItemIcon>
          {/* <ExitToAppOutlinedIcon style={{ color: "#1a80e6"}}/> */}
          <img src={ic_logout} style={{ height: 25, width: 25 }}></img>
        </ListItemIcon>
        <ListItemText secondary="Cerrar sesión" />
      </ListItem>


    </div>

  );

}