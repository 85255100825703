import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { FormControl, InputLabel, Select } from '@material-ui/core';
const useStyles = makeStyles(theme => ({
    divContainer: {
        marginTop: -30,
        [theme.breakpoints.down('md')]: {
            marginTop: 20,
           
        }

    },
    divSelectsContainer: {
        marginTop: -60,
        marginRight: "-10%",
        textAlign: "right",
        [theme.breakpoints.down('md')]: {
            marginTop: 0,
            marginRight: "0%",
            textAlign: "center",
        }
    },
    formControl: {
        height: 5,
        minWidth: 120,
        [theme.breakpoints.down('md')]: {
            marginTop: 0,
          
            minWidth: 20,
        }
    },

}));

export default function BarComponent(props) {
    const [data, setData] = useState()
    const classes = useStyles();
    const [tipo,setTipo]= useState(1)
    useEffect(() => {

        console.log("props ",props)
        let labelsArray = []
        
        // let month =[]
        let dataCountTrx = []
        let dataArray = []
       
        let date;
        let dateFinal;
        props.datos.map((data, index, array) => {
            date = new Date(array[index].day.split("T")[0])
            //array[index].sumtrx = new Intl.NumberFormat("de-DE",{ minimumFractionDigits: 0}).format(parseFloat( array[index].sumtrx) )
          
            // date.setMonth(date.getMonth() + 1)
            // date.setDate(date.getDate() + 1)
            dateFinal = (date.getDate() + 1).toString().padStart(2, "0")+ "/" +(date.getMonth()+1).toString().padStart(2, "0")+ "/" + date.getFullYear()
            labelsArray.push(dateFinal)
            //monthsReducer.push(new Date(array[index].day.split("T")[0]).getMonth() + 1)
            dataArray.push(array[index].sumtrx)
            dataCountTrx.push(array[index].numrecords)
        })
       
        // console.log("dataArray ",dataArray)
        const dat = {
            labels: labelsArray,

            datasets: [
                {
                    
                    data:(props.tipo ===1) ?dataArray:dataCountTrx,
                    backgroundColor: props.tipo ===1 ?['#004E9B']:['#FF5800']
                },
                // {
                //     label: "Cantidad Transacciones",
                //     data: dataCountTrx,
                //     backgroundColor: ['#004E9B']
                // },
            ]
        }


        console.log("DAT ", dat)

        setData(dat)
    }, [tipo])

    const handleChange = (e) => {
        console.log("TIPPO ",e.target.value)
        setTipo(parseInt(e.target.value))
    }



    return (
        <Grid container spacing={3}>
          
            <Grid item xs={11}>
                <div className={classes.divContainer}>

                    <Bar data={data}
                        options={
                            {
                                title: {
                                    display: false,
                                },
                                plugins: {
                                    legend: {
                                        display: false
                                    },
                                },

                                responsive: true,
                                scales: {
                                    xAxes: [{
                                        stacked: true,
                                    }],
                                    yAxes: [{
                                        type:"integer",
                                        stacked: true
                                    }]
                                },
                              locale: 'de-DE'

                            }}
                    />

                </div>
            </Grid>
        </Grid>


    );

}