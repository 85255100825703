import React, { useEffect, useState } from 'react';
import { makeStyles, withStyles, Paper, Grid, Typography, Select, FormControl, InputLabel, MenuItem, Button } from '@material-ui/core';
import clsx from 'clsx';
import calendario from '../../../assets/calendario.png'
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { es} from 'date-fns/locale'
import axios from 'axios';
import { BaseURL, getAllUserDPOS,getTransactionsByFilter } from '../../../constants/constants'
import TableReport from '../Tables/TableReport';
import { connect } from 'react-redux';
import { getDialogManager } from '../../../actions';
import CircularProgress from '@material-ui/core/CircularProgress';
const useStyles = makeStyles(theme => ({
    paper: {
        textAlign: 'center',
        marginTop: theme.spacing(3, 0, 0, 0),
        padding: theme.spacing(8, 2, 8, 3),
        display: 'flex',
        overflow: 'hidden',

        flexDirection: 'column',
        [theme.breakpoints.down('lg')]: {
            overflow: 'auto',
        },
    },
    fixedHeight: {
        height: "100%",
    },

    formControl: {
        margin: theme.spacing(3, 1, 0, 1),
        minWidth: 220,
    },
    btn: {
        // paddingTop:"2%",
        marginTop: theme.spacing(2, 0, 0, 0),
        width: "10%",
        marginLeft: "5%",
        background: "linear-gradient(180deg, #004E9B, #009FDA)",
        color: "#FFF"
    },
    buttonProgress: {
        color: "#ffffff",
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
        
      },
      input: {
        color: "#004E9B"
      }
}))
function FilterComponent({ rif, flag, id ,razon_social,changeDialogManagerValues}) {
    const classes = useStyles();
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
    
    
    const [filter, setFilter] = useState({
        TipoTrx: "ventas",
        Modalidad: "todos",
        Empresa:rif,
        Usuario: "todos",
        Estatus: "todos",
        FechaInicio:new Date(),
        FechaFin: new Date(),

    })

    const [errorField, setErrorField] = useState({
        TipoTrx: false,
        Modalidad: false,
        Empresa: false,
        Usuario: false,
        Estatus: false,
        FechaInicio: false,
        FechaFin: false,
    })

    const [usersDpos, setUsersDpos] = useState()

    const [dataTable,setDataTable] = useState()

    

    const [isTableVisibility,setIsTableVisibility] = useState(false)

    const [isLoading,setIsLoading] = useState(false)

 
    const ColorButton = withStyles(theme => ({
        root: {
            borderRadius: '20px',

            marginTop: '90px',

            color: "#FFF",
            backgroundColor: 'transparent',
            '&:hover': {
                backgroundColor: '#000048',
                color: "white"
            },
            textTransform: "capitalize",

        },
    }))(Button);



    useEffect(() => {

        let payload = {
            id_user_admin: id
        }
        let today
        let arrUsers = []
        axios.post(BaseURL + getAllUserDPOS, payload)

            .then(res => {

                console.log(res)
                if (res.data[0] !== undefined) {
                    let datos = res.data
                    let str = "";
                    datos.forEach(function (e) {
                        e["app"] = "DPOS"
                        // 
                        str = e.fecha.split("T")
                        e.fecha = str[0]
                        today = new Date(e.fecha)

                        e.fecha = today.toLocaleDateString("en-GB")
                        e.hora = str[1].split(".")[0]

                        if (e.estatus === 1) {
                            arrUsers.push(e)

                        }

                    })
                    console.log(arrUsers)
                    setUsersDpos(arrUsers)
                }
            })

            
    }, [filter])

    useEffect(()=>{
        
        if(flag !== "detail"){
            console.log("detail !=")
            setFilter(prevState => ({
                ...prevState,
                "Usuario": ""
            }))
        }else{
            console.log("detail ==")
            setFilter(prevState => ({
                ...prevState,
                "Usuario": "todos"
            }))
        }
    },[flag])
   
    const sendData = (e)=>{
        //setClick(false)
        //setRenderer(true)
        e.preventDefault()
        if(validateSelect() === undefined){
            setIsLoading(true)
           
            let payload = {
                filter
            }
            console.log("PAYLOAD ",payload)
            payload.filter.FechaFin = formatDate(payload.filter.FechaFin)
            payload.filter.FechaInicio = formatDate(payload.filter.FechaInicio)
           
            setIsTableVisibility(false)
            flag=== "detail"?
            
            axios.post(BaseURL + getTransactionsByFilter, filter)
            
            .then(res => {
                    console.log("RESULT ", res)
                    let today
                    let datos = res.data.message
                    let str = "";
                    if(res.data.exito){
                        datos.forEach(function(e){
                            str = e.fecha.split("T")
                            e.fecha = str[0]
                            today= new Date(e.fecha)
                            today.setDate(today.getDate()+1);
                            console.log("fecha  ",e.fecha)
                            e.monto =new Intl.NumberFormat("de-DE",{
                                minimumFractionDigits: 2,      
                                maximumFractionDigits: 2
                        }).format(parseFloat(e.monto))
                            // e.monto = parseFloat(e.monto)
                            e.fecha =today.toLocaleDateString("en-GB")
                            e.hora = str[1].split(".")[0]
                            if(e.estatus === 1){
                            e.estatus = "Aprobado"
                            }else if (e.estatus ===2){
                            e.estatus = "Rechazado"
                           
                            }
                            if(e.tipo_tarjeta==="Mastercard" || e.tipo_tarjeta==="Visa" || e.tipo_tarjeta==="Diners Club" || e.tipo_tarjeta === "American Express"){
                            e.modalidad = "Tarjeta de Crédito"
                            e.ultimos4_digitos = "**** "+  e.ultimos4_digitos 
                            e.motivo_rechazo = e.estatus === "Aprobado"?"":codeErrCrearPago(e.codigo_rechazo)
                           
                            }else if(e.tipo_tarjeta==="Maestro"){
                            e.modalidad = "Tarjeta de Débito Mercantil"
                            e.ultimos4_digitos = "**** "+  e.ultimos4_digitos 
                            e.motivo_rechazo = e.estatus === "Aprobado"?"":codeErrCrearPago(e.codigo_rechazo)
                            //e.motivo_rechazo = codeErrCrearPago(e.codigo_rechazo)
                            }else if(e.tipo_tarjeta==="C2P Compra"){
                            e.modalidad = "Pago móvil C2P"
                            e.motivo_rechazo = e.estatus ==="Aprobado"?"": codeErrC2P(e.codigo_rechazo)
                            
                            }
                        
                            e.tipo_operacion = "Venta"
                        
                        })
                        setIsLoading(false)
                        setDataTable(datos)
                        console.log(datos)
                        setIsTableVisibility(true)
                    }else{
                        setIsLoading(false)
                        handleOpenDialogReduxState("No hay registros para mostrar","warning")
                        setIsTableVisibility(false)
                    }
                    
                
            })
            :
            axios.post(BaseURL + getTransactionsByFilter, filter)

            .then(res => {
                    console.log(res)
                    let today
                    let datos = res.data.message
                    let str = "";
                    let arr =[]
                    let suma = 0
                    setIsLoading(true)
                    if(res.data.exito){
                        setIsLoading(false)
                        datos.forEach(function(e,index,array){
                            str = e.fecha.split("T")
                            e.fecha = str[0]
                            today= new Date(e.fecha)
                            today.setDate(today.getDate()+1);
                            console.log("fecha  ",e.fecha)
                            e.monto =new Intl.NumberFormat("de-DE",{
                                minimumFractionDigits: 2,      
                                maximumFractionDigits: 2
                        }).format(parseFloat(e.monto))
                            // e.monto = parseFloat(e.monto)
                            e.fecha =today.toLocaleDateString("en-GB")
                        
                            array[index].fecha = e.fecha
                            // if(array[index].fecha === array[index+1].fecha){
                            //     suma= array[index].monto + array[index+1].monto
                                
                            // }
                            console.log("Suma ", suma)
                            console.log("fecha ", array[index].fecha)
                            // e.hora = str[1].split(".")[0]
                            // if(e.estatus === 1){
                            //   e.estatus = "Aprobado"
                            // }else if (e.estatus ===2){
                            //   e.estatus = "Rechazado"
                            // }
                           
                            if(e.tipo_tarjeta==="Mastercard" || e.tipo_tarjeta==="Visa" || e.tipo_tarjeta==="Diners Club" || e.tipo_tarjeta === "American Express"){
                            e.modalidad = "Tarjeta de Crédito"
                            e.ultimos4_digitos = "**** "+  e.ultimos4_digitos 
                            e.motivo_rechazo = e.estatus === "Aprobado"?"":codeErrCrearPago(e.codigo_rechazo)
                            //e.motivo_rechazo = codeErrCrearPago(e.codigo_rechazo)
                            }else if(e.tipo_tarjeta==="Maestro"){
                            e.modalidad = "Tarjeta de Débito Mercantil"
                            e.ultimos4_digitos = "**** "+  e.ultimos4_digitos 
                            e.motivo_rechazo = e.estatus === "Aprobado"?"":codeErrCrearPago(e.codigo_rechazo)
                            //e.motivo_rechazo = codeErrCrearPago(e.codigo_rechazo)
                            }else if(e.tipo_tarjeta==="C2P Compra"){
                            e.modalidad = "Pago móvil C2P"
                            e.motivo_rechazo = e.estatus === "Aprobado"?"":codeErrC2P(e.codigo_rechazo)
                            //e.motivo_rechazo = codeErrC2P(e.codigo_rechazo)
                            }  
                        
                            e.tipo_operacion = "Venta"
                        
                        })
                        setDataTable(datos)
                        console.log(datos)
                        setIsTableVisibility(true)
                    }else{
                        setIsLoading(false)
                        handleOpenDialogReduxState("No hay registros para mostrar","warning")
                        setIsTableVisibility(false)
                    }
                    
                
            })
        }
            
    }

    const codeErrCrearPago = (code) => {
        let errMssg ="";
      
        switch (code){
            case "0341":
            case "2426":
                errMssg = "Cédula inválida";
                break;
            case "9014":
                errMssg = "Clave inválida o vencida";  //TODO : ESPERAR Q EL BANCO MANDE ESTE MSJ YA QUE AUN NO HAN APROBADO EL PIN
                break;
            case "0080":
            case "9013":
                errMssg = "Número de tarjeta inválido";
                break;
            case "0120":
            case "B2454":
                errMssg = "Tarjeta vencida";
                break;
            case "0130":
                errMssg = "CVV inválido";
                break;
            case "0140":
            case "0200":
            case "0311":
                errMssg = "Tipo de cuenta inválida";
                break;
            case "0150":
                errMssg = "Clave errada";
                break;
            case "0160":
            case "0371":
            case "B24T1":
                errMssg = "Monto inválido";
                break;
            case "0245":
            case "9015":
                errMssg = "Clave inválida";
                break;
            case "0364":
            case "0374":
            case "0375":
            case "0376":
            case "B2451":
                errMssg = "Saldo insuficiente";
                break;
            case "B24P9":
                errMssg = "Excede el límite de crédito";
                break;
            case "0365":
                errMssg = "Cliente excede monto diario";
                break;
            case "0369":
                errMssg = "Cliente excede cantidad de transacciones diarias";
                break;

            case "":
            case "0049":
            default:
                errMssg = "Transaccion Rechazada";
        }
        return  errMssg;
    }

    const codeErrC2P = (code) => {
        let errMssg= "";
            switch (code){
                case "9005":
                    errMssg = "Clave de autorización incorrecta";
                    break;
                case "9006":
                    errMssg = "Número de documento de identidad destino inválida";
                    break;
                case "9007":
                    errMssg ="Número de documento de identidad origen inválida";
                    break;
                case "9008":
                    errMssg = "Número de teléfono destino inválido";
                    break;
                case "9009":
                    errMssg="Número de teléfono origen inválido";
                    break;
                case "9010":
                case "9019":
                    errMssg="Banco seleccionado no es válido";
                    break;
                case "9011":
                case "9023":
                    errMssg="Cliente excede cantidad de transacciones diarias";
                    break;
                case "9018":
                    errMssg="Monto inválido";
                    break;
                case "9020":
                    errMssg ="Cliente no afiliado al servicio Pago móvil";
                    break;
                case "9021":
                    errMssg = "Número de teléfono inválido";
                    break;
                case "9022":
                    errMssg = "Saldo insuficiente";
                    break;
                case "9024":
                    errMssg = "Banco emisor no responde";
                    break;
                case "9025":
                    errMssg = "Cliente excede monto diario";
                    break;
                default:
                    errMssg = "Transacción Rechazada";
            }
            return errMssg;
    }

    const validateSelect = () => {
        if (flag === "detail") {
            if (filter.TipoTrx === "" || filter.TipoTrx === undefined) {
                handleChangeErrorField("TipoTrx", true)
                return false
            }
            if (filter.Modalidad === "" || filter.Modalidad === undefined) {
                handleChangeErrorField("Modalidad", true)
                return false
            }
            if (filter.Empresa === "" || filter.Empresa === undefined) {
                handleChangeErrorField("Empresa", true)
                return false
            }
            if (filter.Usuario === "" || filter.Usuario === undefined) {
                handleChangeErrorField("Usuario", true)
                return false
            }
            if(filter.Estatus === "" || filter.Estatus === undefined){
                handleChangeErrorField("Estatus", true)
                return false
            }
        } else {
            if (filter.TipoTrx === "" || filter.TipoTrx === undefined) {
                handleChangeErrorField("TipoTrx", true)
                return false
            }
            if (filter.Modalidad === "" || filter.Modalidad === undefined) {
                handleChangeErrorField("Modalidad", true)
                return false
            }
            if (filter.Empresa === "" || filter.Empresa === undefined) {
                handleChangeErrorField("Empresa", true)
                return false
            }

        }

    }
  
    // const handleChangeDateDesde = (date)=>{
    //     setDate(date)
    // }
    const formatDate = (date)=>{
        let defDate;
        console.log("format date ",date)
        if(date.toString().includes("/")){
            let dateParts = date.split("/");
            if(dateParts[2].length > 2){
                let d = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]),
                month = "" + (d.getMonth() + 1).toString().padStart(2, "0"),
                day = "" + d.getDate().toString().padStart(2, "0"),
                year = d.getFullYear().toString().padStart(2, "0");
                defDate = [year,month,day].join("/")  
            } else{
                let d = new Date(+dateParts[0], dateParts[1] - 1, +dateParts[2]),
                month = "" + (d.getMonth() + 1).toString().padStart(2, "0"),
                day = "" + d.getDate().toString().padStart(2, "0"),
                year = d.getFullYear().toString().padStart(2, "0");
                defDate = [year,month,day].join("/")  
            }
           
              
              
        }else{
            let da = new Date(date)

            let months = "" + (da.getMonth() + 1).toString().padStart(2, "0")
            let days = "" + da.getDate().toString().padStart(2, "0")
            let years = da.getFullYear().toString().padStart(2, "0");
            defDate =  [years,months,days].join("/")  
        }
      return defDate
        
    }

   
    const handleChange = (e) => {

        let name = e.target.name
        let value = e.target.value

        handleChangeErrorField(name, false)

        setFilter(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    const handleChangeDate = (e, date, name) => {
        console.log("Date ", e)
        
        setFilter(prevState => ({
            ...prevState,
            [name]: e
        }));
    }

    const handleChangeErrorField = (n, v) => {

        let name = n
        let value = v
        setErrorField(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    const handleOpenDialogReduxState = (msg,ic) => {
        let object = {
          open:true,
          message:msg,
          type: "alert",
          icon:ic
      }
        changeDialogManagerValues(object)
    }
    return (

        <React.Fragment>
            {console.log("FILTER ",filter.FechaFin)}
            <Grid container spacing={3} style={{ marginTop: 30 }}>

                <Grid item xs={12} md={12} lg={12}>
                    <Paper className={fixedHeightPaper}>
                        <Grid item xs={12} md={12} lg={12}>
                            <Typography variant="p" style={{ color: "#004E9B", fontSize: "20px", marginTop: 80 }}>{flag === "detail" ? "Reporte detalle de transacciones" : "Reporte de ventas diarias"}</Typography>
                            <br></br>
                            <br></br>
                            <Typography variant="p" style={{ color: 'gray', fontSize: "17px", marginTop: 40 }}>Selecciona los criterios de filtrado y haz clic en el botón <strong>Buscar</strong></Typography>

                        </Grid>
                        {
                            flag === "detail" ?
                           
                                
                                <>

                                    <Grid item xs={12} md={12} lg={12}>

                                        <FormControl className={classes.formControl}>
                                            <InputLabel id="demo-simple-select-label">Tipo de transacción</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                name="TipoTrx"
                                               value={filter.TipoTrx}
                                                disabled={isLoading}
                                                error={errorField.TipoTrx}
                                                onChange={handleChange}
                                            >
                                                <MenuItem value="ventas" selected={true}>Ventas</MenuItem>

                                            </Select>
                                        </FormControl>


                                        <FormControl className={classes.formControl}>
                                            <InputLabel id="demo-simple-select-label">Comercio</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                name="Empresa"
                                                disabled={isLoading}
                                                value={filter.Empresa}
                                                error={errorField.Empresa}
                                                onChange={handleChange}
                                            >
                                                <MenuItem value={rif} selected={true}>{razon_social}</MenuItem>


                                            </Select>
                                        </FormControl>

                                    </Grid>


                                    <Grid item xs={12} md={12} lg={12}>
                                        <FormControl className={classes.formControl}>
                                            <InputLabel id="demo-simple-select-label">Modalidad de pago</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                name="Modalidad"
                                                disabled={isLoading}
                                                value={filter.Modalidad}
                                                error={errorField.Modalidad}
                                                onChange={handleChange}
                                            >
                                                <MenuItem value={"todos"}>{"Todos"}</MenuItem>
                                                <MenuItem value={"credito"}>Tarjeta de crédito</MenuItem>
                                                <MenuItem value={"debito"}>Débito Mercantil</MenuItem>
                                                <MenuItem value={"c2p"}>Pago móvil C2P</MenuItem>
                                            </Select>
                                        </FormControl>

                                        <FormControl className={classes.formControl}>
                                            <InputLabel id="demo-simple-select-label">Usuario</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                name="Usuario"
                                                disabled={isLoading}
                                                value={filter.Usuario}
                                                error={errorField.Usuario}
                                                onChange={handleChange}
                                            >
                                                <MenuItem value={"todos"}>{"Todos"}</MenuItem>
                                                {
                                                    
                                                    usersDpos !==undefined && usersDpos.map((value, index) => 
                                                            <MenuItem value={value.username}>{value.username}</MenuItem>
                                                    )
                                                    
                                                }

                                        
                                            </Select>
                                        </FormControl>

                                      
                                    </Grid>

                                    <Grid item xs={12} md={12} lg={12}>
                                    <FormControl className={classes.formControl}>
                                            <InputLabel id="demo-simple-select-label">Estatus de transacciones</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                name="Estatus"
                                                disabled={isLoading}
                                                value={filter.Estatus}
                                                error={errorField.Estatus}
                                                onChange={handleChange}
                                            >
                                                <MenuItem value={"todos"}>{"Todos"}</MenuItem>
                                                <MenuItem value={1}>Aprobadas</MenuItem>
                                                <MenuItem value={2}>Rechazadas</MenuItem>

                                        
                                            </Select>
                                        </FormControl>

                                    </Grid>
                                </> :

                                <>

                                    <Grid item xs={12} md={12} lg={12}>

                                        <FormControl className={classes.formControl}>
                                            <InputLabel id="demo-simple-select-label">Tipo de transacción</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                name="TipoTrx"
                                                disabled={isLoading}
                                                value={filter.TipoTrx}
                                                error={errorField.TipoTrx}
                                                onChange={handleChange}
                                            >

                                                <MenuItem value="ventas" selected>Ventas</MenuItem>
                                            </Select>
                                        </FormControl>




                                        <Grid item xs={12} md={12} lg={12}>

                                            <FormControl className={classes.formControl}>
                                                <InputLabel id="demo-simple-select-label">Comercio</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    name="Empresa"
                                                    disabled={isLoading}
                                                    value={filter.Empresa}
                                                    error={errorField.Empresa}
                                                    onChange={handleChange}
                                                >
                                                    <MenuItem value={rif}>{razon_social}</MenuItem>
                                                </Select>
                                            </FormControl>

                                        </Grid>

                                        <Grid item xs={12} md={12} lg={12}>

                                            <FormControl className={classes.formControl}>
                                                <InputLabel id="demo-simple-select-label">Modalidad de pago</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    name="Modalidad"
                                                    disabled={isLoading}
                                                    value={filter.Modalidad}
                                                    error={errorField.Modalidad}
                                                    onChange={handleChange}
                                                >
                                                    <MenuItem value={"todos"}>{"Todos"}</MenuItem>
                                                    <MenuItem value={"credito"}>Tarjeta de crédito</MenuItem>
                                                    <MenuItem value={"debito"}>Débito Mercantil</MenuItem>
                                                    <MenuItem value={"c2p"}>Pago móvil C2P</MenuItem>
                                                </Select>
                                            </FormControl>

                                        </Grid>




                                    </Grid>
                                </>


                        }


                        <Grid item xs={12} md={12} lg={12} style={{ marginTop: 20 }}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale ={es}>
                                <Grid container justify="space-around">
                                    <Grid item xs={12}>
                                        <KeyboardDatePicker
                                            autoOk
                                            disableToolbar
                                            disabled={isLoading}
                                            variant="inline"
                                            format="dd/MM/yyyy"
                                            margin="normal"
                                            id="to"
                                            label="Desde"
                                            name="FechaInicio"
                                            value={filter.FechaInicio}
                                            disableFuture
                                            onChange={(e, date, name) => handleChangeDate(e, date, "FechaInicio")}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                            keyboardIcon={<img src={calendario} style={{ width: 20, height: 20 }} />}
                                        //error ={dateError}
                                        InputProps={{ className: classes.input }}
                                        />
                                        <KeyboardDatePicker
                                            autoOk
                                            disableToolbar
                                            disabled={isLoading}
                                            variant="inline"
                                            format="dd/MM/yyyy"
                                            margin="normal"
                                            id="from"
                                            label="Hasta"
                                            name="FechaFin"
                                            disableFuture
                                            value={filter.FechaFin}
                                            onChange={(e, date, name) => handleChangeDate(e, date, "FechaFin")}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                            keyboardIcon={<img src={calendario} style={{ width: 20, height: 20 }} />}
                                            InputProps={{ className: classes.input }}
                                        />

                                    </Grid>

                                </Grid>
                            </MuiPickersUtilsProvider>
                        </Grid>


                        <Grid item xs={12} md={12} lg={12}>
                            <ColorButton variant="contained" disabled ={isLoading} className={classes.btn} onClick={sendData}>Buscar{isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}</ColorButton>
                        </Grid>
                    </Paper>
                </Grid>


                <Grid item xs={12} md={12} lg={12}>
                    {
                        dataTable !== undefined && isTableVisibility &&
                        <TableReport data={dataTable} flag= {flag}/>
                    }
                    
                </Grid>
            </Grid>

        </React.Fragment>
    );
}

const mapDispatchToProps =(dispatch) =>({
    changeDialogManagerValues(args){
        dispatch(getDialogManager(args))
    },
    
  })


export default connect(null,mapDispatchToProps)(FilterComponent)
