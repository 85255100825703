import React,{useEffect,useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import  Grid  from '@material-ui/core/Grid';
import Accordeon from './components/Accordeon';
import Typography from '@material-ui/core/Typography';
import ic_about from '../../../assets/ic_about.png';

const useStyles = makeStyles(theme => ({
    title: {
        color :"#004E9B",
        marginTop:40,
        marginBottom:20,
        fontSize:18
    },
    subtitle:{
        color :"gray",
        fontSize :15,
    
    },
    image:{
        height:25
    }
}))
export default function Help() {
   
    const classes = useStyles();
    
    useEffect(()=>{

    },[])
    return (
        <React.Fragment>
            <Grid container spacing={5} style={{marginLeft:"5%"}} >
                <Grid item xs={12} spacing={6}>
                    <Typography variant="h5" className={classes.title}>Ayuda  </Typography>
                    <Typography variant="p" className={classes.subtitle}>En esta sección encontrarás respuesta a algunas situaciones o mensajes presentados en la aplicación Dpos</Typography>

                </Grid>
               
                <Grid item xs={10} lg = {8}>
                    <Accordeon/>
                </Grid>
            </Grid>
        </React.Fragment>
        
    );
}