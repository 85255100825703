import React, { useEffect, useState } from 'react';
import { makeStyles, Grid, Paper, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { Container, Row, Col } from 'react-grid-system';
const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(4),
        marginTop: 90,
        display: 'flex',
        overflow: 'hidden',

        flexDirection: 'column',
        [theme.breakpoints.down('lg')]: {
            overflow: 'auto',
        },
    },
    subPapers: {
        padding: theme.spacing(4),
        marginTop: 10,
        display: 'flex',
        overflow: 'hidden',
     
        flexDirection: 'column',
        [theme.breakpoints.down('lg')]: {
            overflow: 'auto',
        },
    },
    titleCard: {
        color: "#004E9B",
        fontSize: 18,
        textAlign: "left",
        marginLeft: 20
    },
    textTitle: {
        fontSize: 14,
        textAlign: "left",
        fontWeight: "bold",
        marginLeft: 20,
    },
    text: {
        fontSize: 14,
        textAlign: "left",
        marginLeft: 20,
        color:"gray"
    }
}))
export default function Profile({rif,razon_social,telefono,identificador_comercio,correo,direccion}) {

    const [datos, setDatos] = useState()
    const classes = useStyles();
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
    const fixedHeightSubPaper = clsx(classes.subPapers, classes.fixedHeight);
    telefono = telefono.replace("58","0")
    telefono = telefono.substring(0,4).concat(" ",telefono.substring(4,7)," ",telefono.substring(7,13))
    useEffect(() => {

    }, [])
    return (

        <React.Fragment>
            <Grid container spacing={0}>
                <Grid item xs={12} md={12} lg={12}>
                    <Paper className={fixedHeightPaper}>
                        <Typography className={classes.titleCard}>Datos del comercio</Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <Paper className={fixedHeightSubPaper}>
                        <Grid container spacing={0}>
                            <Grid item xs={12} md={12} lg={12}>
                                <Typography className={classes.textTitle}>Razón social: <a className={classes.text}> {razon_social}</a></Typography>
                                </Grid>
                                
                        </Grid>
                            
                                    
                        <Grid container spacing={1} style = {{marginTop:10}}>
                            <Grid item xs={12} md={12} lg={12}>
                                <Typography className={classes.textTitle}>Rif: <a className={classes.text}> {rif}</a></Typography>
                                </Grid>
                                
                        </Grid>  
                            
                        
                        <Grid container spacing={1} style = {{marginTop:10}}>
                            <Grid item xs={12} md={12} lg={12}>
                                <Typography className={classes.textTitle}>Código afiliación: <a className={classes.text}> {identificador_comercio}</a></Typography>
                            </Grid>
                                
                        </Grid>  

                        <Grid container spacing={1} style = {{marginTop:10}}>
                            <Grid item xs={12} md={12} lg={12}>
                                <Typography className={classes.textTitle}>Correo electrónico: <a className={classes.text}> {correo}</a></Typography>
                                </Grid>
                                
                        </Grid>
                            
                                    
                        <Grid container spacing={1} style = {{marginTop:10}}>
                            <Grid item xs={12} md={12} lg={12}>
                                <Typography className={classes.textTitle}>Dirección: <a className={classes.text}> {direccion}</a></Typography>
                                </Grid>
                                
                        </Grid>  
                            
                        
                        <Grid container spacing={1} style = {{marginTop:10}}>
                            <Grid item xs={12} md={12} lg={12}>
                                <Typography className={classes.textTitle}>Teléfono C2P: <a className={classes.text}> {telefono}</a></Typography>
                            </Grid>
                                
                        </Grid>  
                     
                     
                    </Paper>
                </Grid>
                
                {/* <Grid item xs={12} md={12} lg={12}>
                    <Paper className={fixedHeightSubPaper}>
                       
                    </Paper>
                </Grid> */}
            </Grid>
        </React.Fragment>
    );
}