import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Chart from './Chart';
import Graph from './Graph';
import Deposits from './Deposits';
import Orders from './Orders';
import Resumen from './ResumenSection.js/Resumen';
import Graph2 from './Graph2';
import { Typography } from '@material-ui/core';


const useStyles = makeStyles(theme => ({
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'hidden',
   
      flexDirection: 'column',
      [theme.breakpoints.down('lg')]: {
        overflow: 'auto',
      },
    },
    paperGraph:{
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'hidden',
   
      flexDirection: 'column',
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(0),
      },
    },
    fixedHeight: {
      height: 240,
      overflow: 'hidden',
      [theme.breakpoints.down('md')]: {
        height: 600,
      },
    },
    fixedHeightGraph: {
      height: 390,
      overflow: 'hidden',

      [theme.breakpoints.down('md')]: {
        overflow: 'hidden',
       
      },
    },
    resumeTitle:{
      marginTop: "5mm",
      color:"#004E9B",
      fontWeight: "light",
    },
    resumeSubTitle:{
      color:"#004E9B",
      fontWeight: "extrabold"
    }
  }));

export default function Home (props){
    
    let defrif = props.rif
   
    const classes = useStyles();
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
    const fixedHeightGraph = clsx(classes.paperGraph, classes.fixedHeightGraph)

  
    return(
      
      <Grid container spacing={3}>
        
        {/* RESUMENT TRX  */}
        <Grid item xs={12} md={12} lg={12}>
         
            <Resumen rif = {defrif}  id= {props.id}/>
        
        </Grid>
      {/* Gestionar usuarios */}
      {/* <Grid item xs={12} md={8} lg={9}>
        <Paper className={fixedHeightPaper}>
          <Chart />
        </Paper>
      </Grid> */}
      {/* Total trx */}
      {/* <Grid item xs={12} md={4} lg={3}>
        <Paper className={fixedHeightPaper}>
          <Deposits rif = {defrif}/>
        </Paper>
      </Grid> */}
      {/* Charts */}

      <Grid item xs={12} md={12} lg={12}>
          
          <Typography component = 'p' variant='h6' className={classes.resumeTitle}>Historial de ventas </Typography>  
          <Typography variant='h8' className={classes.resumeSubTitle}>Últimos 90 días </Typography> 
       
        
      </Grid>
      <Grid item xs={12}>
        <Paper className={fixedHeightGraph}>
          <Graph rif = {defrif} />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className={fixedHeightGraph}>
          <Graph2 rif = {defrif}  />
        </Paper>
      </Grid>
     
      <Grid item xs={12} md={12} lg={12}>
          
          <Typography component = 'p' variant='h6' className={classes.resumeTitle}>Últimas 10 transacciones</Typography>  
          
        
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Orders rif = {defrif} />
        </Paper>
      </Grid>
    </Grid>
  ) 
}  