import React, { useState, useEffect } from 'react';
import { ResponsiveContainer, PieChart, Pie, Sector, Cell, } from 'recharts';
import { Container, Row, Col } from 'react-grid-system';
import { useHistory } from 'react-router-dom'
//button custom
import { makeStyles,withStyles } from '@material-ui/core/styles';
import { useMediaQuery } from 'react-responsive'
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios'
import { BaseURL, SumTrxApi, getAllTransactions, SumTrxDate, MasterKeyLogin, getAllUserDPOS, getAllTransactionsByDateUser } from '../../../../constants/constants'
import searchIcon from '../../../../assets/ic_search.png'
import calendario from '../../../../assets/calendario.png'
import calendario_circular from '../../../../assets/calendario_circular.png'
import PieComponent from '../../Charts/PieComponent';
import { width } from '@material-ui/system';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { FormControl, InputLabel, Paper, Select, Typography } from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { es } from 'date-fns/locale'
import DateFnsUtils from '@date-io/date-fns';
import { Grid, Button } from '@material-ui/core'
import clsx from 'clsx';
import  Divider  from '@material-ui/core/Divider';
import Backdrop from '@material-ui/core/Backdrop';
const useStyles = makeStyles(theme => ({
  resume: {
    //fontFamily:"Roboto-Bold",
    fontStyle: "bold",
    fontSize: "large",
    paddingLeft: "0mm",
    marginTop: "5mm",
    marginInline: "initial",
    color: "#009BD7"
  },
  backdrop: {
   width: '100%',
   height: '50%',
    color: '#fff',
  },
  amount: {
    //fontFamily:"Roboto-Bold",
    fontStyle: "bold",
    fontSize: "x-large",
   
    marginInline: "initial",
    color: "gray"
  },
  totalTrx: {
    //fontFamily:"Roboto-Bold",
    fontStyle: "bold",
    fontSize: "x-large",
    [theme.breakpoints.down('lg')]: {
      fontSize: "medium",
      paddingLeft: "5mm",
      paddingRight: "5mm",
    },
    paddingLeft: "8mm",
    paddingRight: "8mm",
    marginInline: "initial",
    color: "gray"
  },
  contentFontSecondaryText:{
    fontSize: "small",
    [theme.breakpoints.down('lg')]: {
      fontSize: "12px",
      paddingLeft: "1mm",
      paddingRight: "1mm",
      marginTop: "-2mm",
    },
    paddingLeft: "8mm",
    paddingRight: "8mm",
    marginTop: "-8mm",
    marginInline: "initial",
    color: "gray"
  },
  total_trx_aproved: {
    //fontFamily:"Roboto-Bold",
    fontStyle: "bold",
    fontSize: "medium",
    paddingLeft: "5mm",
    marginTop: "1mm",
    marginInline: "initial",
    color: "#6e6e6e"
  },
  submit: {
    color: "white",
    margin: "auto",
    marginTop: 20,
    borderRadius: "25px",
    background: "linear-gradient(180deg, #004E9B, #009FDA)",
    textTransform: "capitalize",
    width: "150px",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,

  },
  input: {
    color: "#004E9B"
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'hidden',

    flexDirection: 'column',
    [theme.breakpoints.down('lg')]: {
      overflow: 'auto',
    },
  },
  fixedHeight: {
   height:200,
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      height: 200,
    },
  },

  cardsTitles:{
    fontSize: "medium",
    marginInline: "initial",
    color: "#004E9B",
  },
  cardsSubtitles:{
    fontSize: "small",
    marginInline: "initial",
    color:"gray"
  },
  wrapTitle:{
    paddingLeft: "5mm",
    paddingTop: "5mm",
    
  },
  wrapContent:{
    paddingLeft: "5mm",
    paddingTop: "10mm",
    paddingBottom: "5mm",
   
  },
  wrapTrxContent:{
    paddingLeft: "5mm",
    paddingTop: "10mm",
    textAlign: "center",
  },
  wrapTrxTextSecondaryContent:{
    paddingLeft: "5mm",
    paddingBottom: "5mm",
    textAlign: "center",
  },
  resumeTitle:{
    marginTop: "20mm",
    color:"#004E9B",
  }
}));
const SearchButton = withStyles((theme) => ({
  root: {
    height: '25px',
    color: 'white',
    marginLeft: '5mm',
    [theme.breakpoints.down('md')]: {
      marginLeft: '0mm',

    },
    [theme.breakpoints.only('md')]: {
      marginLeft: '5mm',

    },
    backgroundColor: '#004E9B',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#004E9B',
    },
  },
}))(Button);
export default function Resumen(props) {
  const classes = useStyles();
  let history = useHistory();

  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 1224px)'
  })
  /* 
  
  const isDesktopOrLaptop = useMediaQuery({ minDeviceWidth: 1224 })
  const isBigScreen = useMediaQuery({ minDeviceWidth: 1824 })
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 })
  const isTabletOrMobileDevice = useMediaQuery({ maxDeviceWidth: 1224 })
  const isPortrait = useMediaQuery({ orientation: 'portrait' })
  const isRetina = useMediaQuery({ minResolution: '2dppx' })
  
  */
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 })
  let rifMerchant = props.rif
  let payload = {
    "rif": rifMerchant
  }

  let totalAmount

  const [loaderAmount, setLoaderAmount] = useState(false)
  const [loaderDataTrx, setLoaderDataTrx] = useState(false)
  const [totalTrxAmount, setTotalTrxAmount] = useState(0)
  const [dataPieChart, setDataPieChart] = useState([

    { name: 'Debito', value: 0.00 },
    { name: 'Credito', value: 0.00 },
    { name: 'C2P', value: 0.00 },


  ])
  const [open, setOpen] = useState(false);
  const [totalTrxAproved, setTotalTrxAproved] = useState(0)
  const [totalTrxRejected, setTotalTrxRejected] = useState(0)
  const [selectedDateTo, setSelectedDateTo] = React.useState(new Date());
  const [selectedDateFrom, setSelectedDateFrom] = React.useState(new Date());
  const [dateError, setDateError] = React.useState(false)
  const [loader, setLoader] = React.useState(false)
  const [allUser, setAllUser] = useState({
    users: []
  }
  )
  const [idUser, setIdUser] = useState()

  useEffect((props) => {
    getTotalTrxBs()
    getTotalTrxData()
    getAllUser()
  }, [])
  useEffect((props) => {
    if (loader) {
      setLoader(false)
    }
  }, [loader])

  const getTotalTrxBs = () => {
    let wrap = document.getElementById("wrap")
    wrap.style.opacity = 0.5
    setLoaderAmount(true)
    axios.post(BaseURL + SumTrxApi, payload)
      .then(res => {

        wrap.style.opacity = 1
        setLoaderAmount(false)

        if (res.data.Exito) {
          totalAmount = res.data.Sum
        } else {
          totalAmount = 0.00
        }
        setTotalTrxAmount(totalAmount)


      })
      .catch(err => {

        wrap.style.opacity = 1
        setLoaderAmount(false)

      })
  }

  const getTotalTrxData = () => {
    let totalAproved = 0.0
    let totalRejected = 0.0
    let totalDebit = 0.0
    let totalCredit = 0.0
    let totalC2P = 0.0
    setTotalTrxAproved(0)
    setTotalTrxRejected(0)
    setLoaderDataTrx(true)
    axios.get(BaseURL + getAllTransactions + rifMerchant)

      .then(res => {

        setLoaderDataTrx(false)
        if (res.data[0] !== undefined) {
          let datos = res.data

          datos.forEach(function (e) {
            if (e.estatus === 1) {
              setTotalTrxAproved(++totalAproved)
              if (e.tipo_tarjeta === 'Maestro') {
                totalDebit = parseFloat(totalDebit).toFixed(2) + parseFloat(e.monto).toFixed(2)
                //totalDebit=new Intl.NumberFormat("de-DE",{ minimumFractionDigits: 2 }).format(totalDebit)

              } else if (e.tipo_tarjeta === 'Visa' || e.tipo_tarjeta === 'Mastercard' || e.tipo_tarjeta === 'Diners Club' || e.tipo_tarjeta === 'American Express') {
                totalCredit = parseFloat(totalCredit) + parseFloat(e.monto)
                // totalCredit=new Intl.NumberFormat("de-DE",{ minimumFractionDigits: 2 }).format(totalCredit)
              } else if (e.tipo_tarjeta === 'C2P Compra') {
                totalC2P = parseFloat(totalC2P) + parseFloat(e.monto)
                //totalC2P=new Intl.NumberFormat("de-DE",{ minimumFractionDigits: 2 }).format(totalC2P)
              }


            } else {
              setTotalTrxRejected(++totalRejected)
            }
          })



          setDataPieChart([
            { "name": "Debito", "value": parseFloat(totalDebit).toFixed(2) },
            { "name": "Credito", "value": parseFloat(totalCredit).toFixed(2) },
            { "name": "C2P", "value": parseFloat(totalC2P).toFixed(2) }
          ])


        }

      })
      .catch(err => {
        setLoaderDataTrx(false)

      })
  }



  const getTotalTrxDataByDate = () => {
    let totalAproved = 0
    let totalRejected = 0
    let totalDebit = 0
    let totalCredit = 0
    let totalC2P = 0

    let to = document.getElementById("to")
    let from = document.getElementById("from")
    setTotalTrxAproved(0)
    setTotalTrxRejected(0)
    if (selectedDateTo > selectedDateFrom) {
      setDateError(true)
      return
    }

    let schema = {
      "rif": rifMerchant,
      "id_usuario": idUser,
      "desde": formatDate(to.value),
      "hasta": formatDate(from.value)
    }
    console.log(schema)
    setLoaderDataTrx(true)
    axios.post(BaseURL + getAllTransactionsByDateUser, schema)

      .then(res => {

        setLoaderDataTrx(false)
        if (res.data !== undefined) {
          if (res.data.length > 0) {
            let datos = res.data
            console.log(datos)
            datos.forEach(function (e) {
              if (e.estatus === 1) {
                setTotalTrxAproved(++totalAproved)
                if (e.tipo_tarjeta === 'Maestro') {
                  totalDebit = parseFloat(totalDebit) + parseFloat(e.monto)


                } else if (e.tipo_tarjeta === 'Visa' || e.tipo_tarjeta === 'Mastercard' || e.tipo_tarjeta === 'Diners Club' || e.tipo_tarjeta === 'American Express') {
                  totalCredit = parseFloat(totalCredit) + parseFloat(e.monto)

                } else if (e.tipo_tarjeta === 'C2P Compra') {
                  totalC2P = parseFloat(totalC2P) + parseFloat(e.monto)

                }


              } else {
                setTotalTrxRejected(++totalRejected)
              }
            })
          } else {
            setTotalTrxRejected(0)
            setTotalTrxAproved(0)
          }




          setDataPieChart([
            { "name": "Debito", "value": totalDebit },
            { "name": "Credito", "value": totalCredit },
            { "name": "C2P", "value": totalC2P }
          ])


        } else {

          setTotalTrxAproved(0)
          setDataPieChart([
            { "name": "Debito", "value": 0 },
            { "name": "Credito", "value": 0 },
            { "name": "C2P", "value": 0 }
          ])
        }

      })
      .catch(err => {

        setLoaderDataTrx(false)

      })
  }

  const getAllUser = () => {

    let payload = {
      id_user_admin: props.id
    }
    let arra = []

    axios.post(BaseURL + getAllUserDPOS, payload)

      .then(res => {
        setLoader(false)

        if (res.data[0] !== undefined) {
          let datos = res.data
          let str = "";
          datos.forEach(function (e) {
            e["app"] = "DPOS"
            // 
            str = e.fecha.split("T")
            e.fecha = str[0]
            e.hora = str[1]

            if (e.estatus === 1) {
              arra.push({ id: e.id, username: e.username })
            }


          })


          setAllUser({ users: arra })
          return res.data;
        } else {
          setAllUser({ users: [] })
          return 'Null';
        }

      })
      .catch(err => {
        setLoader(false)

      })
  }

  const onClickCapture = (e) => {
    e.preventDefault()
    history.push('/create')
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDateChangeTo = date => {

    setSelectedDateTo(date)
  };

  const handleDateChangeFrom = date => {

    setSelectedDateFrom(date)
  };

  const getTransactionDate = () => {
    let to = document.getElementById("to")
    let from = document.getElementById("from")

    if (selectedDateTo > selectedDateFrom) {
      setDateError(true)
      return
    }




    let schema = {
      "rif": rifMerchant,
      "id_usuario": idUser,
      "to": formatDate(to.value),
      "from": formatDate(from.value)
    }
    console.log(schema)

    handleClose()
    setLoader(true)
    axios.post(BaseURL + SumTrxDate, schema)
      .then(res => {
        console.log("BS por Fecha ", res)
        setLoader(false)
        totalAmount = res.data.Sum

        setTotalTrxAmount(totalAmount)

      })
      .catch(err => {

        setLoader(false)

      })
  };

  const formatDate = (date) => {
    let dateParts = date.split("/") || date.split("-");
    let d = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]),
      month = "" + (d.getMonth() + 1).toString().padStart(2, "0"),
      day = "" + d.getDate().toString().padStart(2, "0"),
      year = d.getFullYear().toString().padStart(2, "0");
    return [year, month, day].join("/")
  }

  const handleChange = (e) => {
    setIdUser(e.target.value)
  }
  return (
    <React.Fragment>
      {/* <Title>Today</Title> */}

      <Dialog
        open={open}
        // TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{""}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Selecciona los criterios de filtrado y haz clic en el botón <strong>Buscar</strong>
          </DialogContentText>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
            <Grid container justify="space-around">
              <Grid item xs={12}>
                <KeyboardDatePicker
                  autoOk
                  disableToolbar
                  disableFuture
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  id="to"
                  label="Desde"
                  value={selectedDateTo}
                  onChange={handleDateChangeTo}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  keyboardIcon={<img src={calendario} style={{ width: 20, height: 20 }} />}
                  InputProps={{ className: classes.input }}
                  error={dateError}
                />
                <KeyboardDatePicker
                  autoOk
                  disableToolbar
                  disableFuture
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  id="from"
                  label="Hasta"
                  value={selectedDateFrom}
                  onChange={handleDateChangeFrom}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  InputProps={{ className: classes.input }}
                  keyboardIcon={<img src={calendario} style={{ width: 20, height: 20 }} />}
                />

              </Grid>
              <Grid item xs={2}><Typography component="p" style={{ marginTop: 20 }}>Usuario:</Typography></Grid>
              <Grid item xs={10}>

                <FormControl variant="outlined" className={classes.formControl}>

                  <Select
                    native
                    //value={state.age}
                    onChange={handleChange}

                    inputProps={{
                      name: 'age',
                      id: 'outlined-age-native-simple',
                    }}
                  >
                    <option aria-label="Todos" value="" >Todos</option>
                    {
                      allUser.users.map((value, index) => (
                        <option key={index} value={value.id}>{value.username}</option>
                      ))
                    }


                  </Select>
                </FormControl>
              </Grid>

            </Grid>
          </MuiPickersUtilsProvider>
        </DialogContent>
        <DialogActions>

          <Button
            fullWidth
            color="white"
            className={classes.submit}
            disabled={loader}
            onClick={() => { getTransactionDate(); getTotalTrxDataByDate(); }}
          >
            {loader && <CircularProgress size={24} className={classes.buttonProgress} />}
            Buscar
          </Button>
        </DialogActions>
      </Dialog>




      {
        // <ResponsiveContainer width="100%" height="100%" id = "wrap" >
        //   <Container>
        //     <Row style={{marginTop:"10mm"}}> 
        //     <Paper className = {classes.fixedHeightPaper}>
        //         <Col>
        //             <Row>
        //               <div>
        //                   <label className={classes.resume}>Resumen de transacciones</label>
        //               </div>
        //             </Row >


        //             <Row>
        //             { loaderDataTrx ? <CircularProgress size={24} className={classes.buttonProgress}  opacity = "1" />:<label className={classes.total_trx_aproved} >{totalTrxAproved} transacciones aprobadas</label>}


        //             </Row>
        //             <Row>
        //             <img src={searchIcon} style={{width:"28px",height:"25px",marginLeft:"5%",marginTop:"6%"}} onClick={handleClickOpen}></img><Typography style={{color:"gray",marginLeft:"2%",marginTop:"6%"}}>{"Buscar"}</Typography>
        //             </Row>
        //             {isTabletOrMobile &&  
        //               <Row style={{paddingTop:"25mm", marginLeft:-90}}>

        //                       {dataPieChart[0].value !==0 && dataPieChart[1].value!==0 &&dataPieChart[2].value!==0 && !loaderDataTrx?<PieComponent datos={dataPieChart}/>:<CircularProgress size={24} className={classes.buttonProgress}  opacity = "1" /> }   

        //                 </Row>
        //             } 
        //         </Col>
        //         </Paper>
        //         <Paper className = {classes.fixedHeightPaper}>
        //         <Col>

        //             <Row>
        //             { loaderAmount ? <CircularProgress size={24} className={classes.buttonProgress}  opacity = "1" />:<label className={classes.amount} >Bs. {new Intl.NumberFormat("de-DE",{ minimumFractionDigits: 2 }).format(totalTrxAmount)}</label>}

        //             </Row>

        //         </Col>    
        //         </Paper>

        //         {!isTabletOrMobile &&  
        //             <Col>
        //                   <Row style={{paddingLeft:"50mm"}}>

        //                       {console.log("dataPieChart ", dataPieChart)}
        //                       {console.log("loader ", loaderDataTrx)}
                              // {
                              //   (dataPieChart[0].value >0 || dataPieChart[1].value>0 ||dataPieChart[2].value>0 )&& !loaderDataTrx ?<PieComponent datos={dataPieChart}/>:
                              //   (dataPieChart[0].value ==0 && dataPieChart[1].value==0 && dataPieChart[2].value==0 )&& !loaderDataTrx ?"":
                              //   <CircularProgress size={24} className={classes.buttonProgress}  opacity = "1" /> 
                              // } 

        //                   </Row>
        //             </Col>
        //         }

        //     </Row>
        //   </Container>

        // </ResponsiveContainer>
        
        <Grid container spacing={3}>
         

          <Grid item xs={12} md={12} lg={12}>
            
              <Typography variant='h6' className={classes.resumeTitle}>Resumen de transacciones<SearchButton variant="contained" onClick={handleClickOpen} endIcon ={<img src={calendario_circular} style={{width:"20px",height:"20px"}} ></img>}>Consultar resumen por fecha</SearchButton></Typography>  

          </Grid>
          <Grid item xs={12} md={4} lg={4}>

          
            <Paper className={fixedHeightPaper} id="wrap" >
                <Grid item xs={12} className={classes.wrapTitle}>
                  <label className={classes.cardsTitles}>Ventas Bs</label>
                  <br></br>
                  <label className={classes.cardsSubtitles}>Aprobadas</label>
                </Grid>

                <Grid item xs={12} className={classes.wrapContent}> 
                  {loaderAmount ? <CircularProgress size={24} className={classes.buttonProgress} opacity="1" /> : <label className={classes.amount} >Bs. {new Intl.NumberFormat("de-DE", { minimumFractionDigits: 2 }).format(totalTrxAmount)}</label>}
                </Grid>
              

              
            </Paper>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
          <Paper className={fixedHeightPaper} id="wrap" >
                <Grid item xs={12} className={classes.wrapTitle}>
                  <label className={classes.cardsTitles}>Cant. Transacciones</label>
                  <br></br>
                  
                </Grid>
                <Grid item xs={12} className={classes.wrapTrxContent}> 
                
                { loaderDataTrx ? <CircularProgress size={24} className={classes.buttonProgress}  opacity = "1" />:<><label className={classes.totalTrx} >{totalTrxAproved} </label> <label className={classes.totalTrx}>|</label> <label className={classes.totalTrx} >{totalTrxRejected}</label></>}
                </Grid>
                <Grid item xs={12} className={classes.wrapTrxTextSecondaryContent} > 
                
                { loaderDataTrx ? <CircularProgress size={24} className={classes.buttonProgress}  opacity = "1" />:<><label className={classes.contentFontSecondaryText} >Aprobadas </label> <label className={classes.contentFontSecondaryText} >Rechazadas</label></>}
                </Grid>

              
            </Paper>
          </Grid>

          <Grid item xs={12} md={4} lg={4}>
          <Paper className={fixedHeightPaper} id="wrap" >
              
                <Grid item xs={12} className={classes.wrapContent}> 
                
                {
                                (dataPieChart[0].value >0 || dataPieChart[1].value>0 ||dataPieChart[2].value>0 )&& !loaderDataTrx ?<PieComponent datos={dataPieChart}/>:
                                (dataPieChart[0].value ==0 && dataPieChart[1].value==0 && dataPieChart[2].value==0 )&& !loaderDataTrx ?"":
                                <CircularProgress size={24} className={classes.buttonProgress}  opacity = "1" /> 
                              } 
                </Grid>
              

              
            </Paper>
          </Grid>
          {/* <Backdrop className={classes.backdrop} open={true} >
        <CircularProgress color="primary" />
      </Backdrop> */}
        </Grid>

      }    </React.Fragment>

  );
}