import React , { useState }from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import axios from 'axios'
import CryptoJS from 'crypto-js'
import CircularProgress from '@material-ui/core/CircularProgress';
import Slide from '@material-ui/core/Slide';
import home from '../assets/home.png';
import checkout from '../assets/checkout.png'
import {BaseURL, loginApi, MasterKeyLogin} from '../constants/constants'
import { useHistory } from "react-router-dom";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from "@material-ui/core/IconButton";
import m_cn_rgb_sin_bg from '../assets/m_cn_rgb_sin_bg.png'
import FooterOut from '../componentUtils/FooterOut';
import LogoRegistro from '../assets/icn_identificacion.png'
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://nextpos.us/">
        NextPOS
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor:"#000048"
  },
  logoMercantilStyle:{
    width:"171px",
    height:"61px",
  },
  container:{
   
    marginTop: '10px'
  },
  paper: {
    // marginTop: theme.spacing(20),
    margin:0,
    display: 'flex',
    position: 'absolute',
    flexDirection: 'column',
    alignItems: 'center',
    top: "37%",
    left: "40%",
  
  },
  home:{
    width:"35px",
    height:"25px",
  },
  check:{
    marginTop:"25px",
    width: "40px"
  },
  enrrolled:{
    color:'gray',
    marginTop:'30px'

  },
  shopName:{
    marginTop:'10px',
    fontWeight: 'bold',
    fontSize:'20px'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
   
  },
  submit: {
    left: "25%",
    color:"white",
    margin: theme.spacing(3, 0, 2),
    borderRadius:"25px",
    background: "linear-gradient(180deg, #004E9B, #009FDA)",
    textTransform:"capitalize",
    width:"150px",

  },
  buttonProgress: {
    color: "#1f2d3d",
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  title: {
    flexGrow: 1,
  },
}));

export default function GetStarted(props) {
  let history = useHistory();
  let user;
  let password;
  const classes = useStyles();

  const [loader,setLoader]=useState(false)

  
 if(props.location.state !== undefined){
  if(props.location.state.user === undefined || props.location.state.user === '' || props.location.state.password ===undefined || props.location.state.password ===''){
    history.push("/login")
  }else{
    user = props.location.state.user
    password = props.location.state.password
  }
}else{
  history.push("/login")
}

  function handleClick(event){
       
    event.preventDefault()
        console.log("Datos",user,password)
        
        var ciphertext = CryptoJS.AES.encrypt(user, MasterKeyLogin);
        var cipherpass = password;
        var apiBaseUrl =BaseURL;
        var payload={
          "Username":ciphertext.toString(),//"email.username",
          "Clave":cipherpass//"U2FsdGVkX19N8yY9zG2txQd4FXkCU91FPqVO4ovFAhg="
        }
        setLoader(true)
        
        axios.post(apiBaseUrl+loginApi, payload)
        
        .then(function (response) {
              setLoader(false)
              console.log("RESPONSE",response)
              if(response.data.exito){
                //setMsgs(response.data.Message)
                //handleClickOpen()
                let id = response.data.data_usuario.id_usuario
                let rif = response.data.data_usuario.rif
                let id_empresa = response.data.data_usuario.id_empresa
                let limite_usuarios = response.data.data_usuario.limite_usuarios
                let ultima_sesion = response.data.data_usuario.ultima_sesion
                let razon_social = response.data.data_usuario.razon_social
                let identificador_comercio = response.data.data_usuario.identificador_comercio
                let telefono = response.data.data_usuario.telefono
                let correo = response.data.data_usuario.correo
                let direccion = response.data.data_usuario.direccion
                let cryptorif = CryptoJS.AES.encrypt(rif, MasterKeyLogin);
                let cryptoid = CryptoJS.AES.encrypt(id.toString(), MasterKeyLogin);
                let cryptoidempresa = CryptoJS.AES.encrypt(id_empresa.toString(), MasterKeyLogin);
                let cryptoultimasesion = CryptoJS.AES.encrypt(ultima_sesion.toString(), MasterKeyLogin);
                let cryptorazon_social = CryptoJS.AES.encrypt(razon_social.toString(), MasterKeyLogin);
                let cryptotelefono = CryptoJS.AES.encrypt(telefono.toString(), MasterKeyLogin);
                let cryptoidentificador_comercio = CryptoJS.AES.encrypt(identificador_comercio.toString(), MasterKeyLogin);
                let cryptocorreo = CryptoJS.AES.encrypt(correo.toString(), MasterKeyLogin);
                let cryptodireccion = CryptoJS.AES.encrypt(direccion.toString(), MasterKeyLogin);
                sessionStorage.setItem("_CDAUPOMGKLJL",cryptorif.toString())
                    sessionStorage.setItem("_CDAUPOMGKLJLTYJIKH",cryptoid.toString())
                    sessionStorage.setItem("_CDAUPOMGKLJLTYJIKHENTER",cryptoidempresa.toString())
                    sessionStorage.setItem("_CDAUPOMGKLJLTYJIKHENTERLIM",limite_usuarios.toString())
                    sessionStorage.setItem("_CDAUPOMGKLJLTYJIKHENTERLIMETRERT",cryptoultimasesion.toString())
                    sessionStorage.setItem("_CDAUPOMGKLJLTYJIKHENTERLIMETRERTRAZN",cryptorazon_social.toString())
                    sessionStorage.setItem("_CDAUPOMGKLJLTYJIKHENTERLIMETRERTLFN",cryptotelefono.toString())
                    sessionStorage.setItem("_CDAUPOMGKLJLTYJIKHENTERLIMETRERIDFCT",cryptoidentificador_comercio.toString())
                    sessionStorage.setItem("_CDAUPOMGKLJLTYJIKHENTERLIMETRERCOR",cryptocorreo.toString())
                    sessionStorage.setItem("_CDAUPOMGKLJLTYJIKHENTERLIMETRERDIR",cryptodireccion.toString())
                      



                     
                    
                history.push("/home");
                
                 
                 
                 
                  
                      
              }
              else{   
                history.push("/login")
              }
              
        })
        .catch(function (error) {
          setLoader(false)
          console.log()
          //setMsgs("Error en el servidor")
        });
  }

    return (
      <React.Fragment>
        <div className={classes.root}>
          <AppBar position="absolute" className={classes.appBar}>
          <Toolbar style={{background :"linear-gradient(90deg, rgba(0,78,159,1) 0%, rgba(1,154,216,1) 12%, rgba(0,78,155,1) 39%)"}}>
            <IconButton edge="start" color="inherit" className={classes.menuButton}  >
            </IconButton>
            <img src={m_cn_rgb_sin_bg} className={classes.logoMercantilStyle}></img>
            <Typography variant="h6" className={classes.title}>
            </Typography>
            <Button color="inherit"><label style={{fontSize:"18px",textTransform:"capitalize",fontFamily:"Roboto"}}>Portal Dpos</label></Button>
          </Toolbar>
        </AppBar>
        </div>
      
      <Container className = {classes.container } component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
           
            <img src={LogoRegistro} className={classes.home} alt="home"  />
          
            <img src = {checkout} className={classes.check} alt = "check"/>
          <Typography component="subtitle1" variant="subtitle1" className={classes.enrrolled}>
          ¡Listo! Tu registro fue exitoso
          </Typography>
          <Typography component="subtitle1" variant="subtitle1" className={classes.shopName}>
            {user} 
          </Typography>
          <form className={classes.form} noValidate>
            <Button
              
              
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled ={loader}
              onClick={(event) => handleClick(event)}
            >
              {loader && <CircularProgress size={24} className={classes.buttonProgress} />}
             Iniciar
            </Button>
            
          </form>
        </div>
        
      </Container>

      <FooterOut page = "signup"/>
      </React.Fragment>
    );
  
}
