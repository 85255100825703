import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import { useMediaQuery } from 'react-responsive'

import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';

import Grid from '@material-ui/core/Grid';

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import login from '../assets/login_bm2_m2.jpg'
import loginPC from '../assets/login_bm2_m2.jpg'

import {BaseURL, loginApi, MasterKeyLogin} from '../constants/constants'
import { useHistory } from "react-router-dom";

import Slide from '@material-ui/core/Slide';

import CircularProgress from '@material-ui/core/CircularProgress';

import CryptoJS from 'crypto-js'
import superagent from 'superagent'
import LogoUser from '../assets/icn_user.png'
import Closeeye from '../assets/closeeye.png'

import Openeye from '../assets/openeye.png'

import FooterOut from '../componentUtils/FooterOut';
import { getDialogManager } from '../actions';
import {connect} from 'react-redux'

import InputAdornment  from '@material-ui/core/InputAdornment';
import  IconButton  from '@material-ui/core/IconButton';



const useStyles = makeStyles(theme => ({
  rootTextfieldClass:{
    "& .MuiFormLabel-root.Mui-error": {
      "&.shrink": {
        color: "#fb665a"
      },
      '&:after': { //focused
        borderBottomColor: "#fb665a",
       },
   
       color: "#fb665a"
    },
    "& .MuiInput-underline.Mui-error:after": {
      borderBottomColor: "#fb665a !important"
    },

   
    
  },
  rootTextfield: {
   
    "&.shrink": {
      color: "#004E9B"
    }
  },
  root: {
    height: '100vh',
  },
  imgBackground:{
    width:"100%",
    // height: '100%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'inherit',
  },
  image: {
    width:'100%',
    height:'100vh',

    [theme.breakpoints.only('md')]: {
      backgroundSize: 'contain',
      position:'relative',
      height:'100vh',
    },
    [theme.breakpoints.only('sm')]: {
      display: 'none'
    },
    [theme.breakpoints.only('xs')]: {
      display: 'none'
    }
  },

  paperBg: {
    backgroundImage: 'url('+login+')',
},

  image2:{
      textAlign: "center",
      display: "block",
      justifyContent: "center",
      alignItems: "center",
      margin: "auto",
      width: "100%",
  },
  paper: {
    //margin: theme.spacing(8, 4),
    marginTop:"17%",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "white",
    borderColor:"#007EC0",
    borderWidth:"2px",
    borderStyle:'solid',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    borderRadius:"25px",
    background: "linear-gradient(180deg, #004E9B, #009FDA)",
    textTransform:"capitalize",
    width:"150px",
  },
  btnAccept: {
    margin: theme.spacing(3, 0, 2),
    borderRadius:"25px",
    backgroundColor: "#004E9B",
    textTransform:"capitalize",
    width:"150px",
  },
  buttonProgress: {
    color: "#1f2d3d",
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
    
  },
  logoUserStyle:{

    width:"25px",
    height:"25px",
  },

  gridBarButton:{
    marginTop:"3%"
  },
  footerGrid: {
    width: "100%",
    position:"fixed",
    zIndex:"1000",
    bottom:"0",
    height: 35,
    paddingLeft:"5px",
    paddingRight:"5px",
    backgroundColor: "#EBEBEB",
  },
  icClassAlert:{
    position: "fixed",
    width: "45px",
    height: "45px",
    left:0,
    right:0,
    justifyContent:"center",
    margin:"auto",
    marginTop:"-40px"
  
  },
  diagStyle:{
    borderRadius:"20px",
  },
  iconPass:{
    height:25,
    width:25
  }
  
}));


/*onkeypress = (event) =>{
  
  let username = document.getElementById("email")
  username.maxLength = 30;
      if ((event.keyCode < 64 || event.keyCode > 90) && (event.keyCode <97 || event.keyCode > 122)) {
        return false;
      }
    
}*/

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


function SignInSide({changeDialogManagerValues}) {

  const isDesktopPC = useMediaQuery({
    minWidth: 960,
   maxWidth:1279
  })

  const isDesktopOrLaptop = useMediaQuery({
    minWidth: 1280
  
  })

  const classes = useStyles();
  let history = useHistory();
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("");
  const [datauser, setDataUser] = useState({});
  const [open, setOpen] =useState(false);
  const [loader,setLoader]=useState(false)
  const [emptyMail,setEmptyMail] = useState(false)
  const [onCheck,setOnChecked]=useState(false)
  const [emptyPassword,setEmptyPassword]= useState(false)
  const [showPassword,setShowPassword]=useState(false)

  if(localStorage.getItem("_CDAUPOMGKLJL") !=null || sessionStorage.getItem("_CDAUPOMGKLJL")){
    history.push("/home");
  }

  function btnForgotPassword(event){
    history.push("/forgotpassword");
  }
      
  function handleClick(event){
        event.preventDefault()
        var ismailEmpty = isEmpty(email,"mail")
        var isPassEmpty = isEmpty(password,"password")
        let isMailMatch = matchEmail(email)
        //let keepSessionActived = document.getElementById("keepSession")

        if(ismailEmpty || isPassEmpty || isMailMatch){
          if(isMailMatch){
            handleClickOpen("Verifica el correo electrónico ingresado e intenta nuevamente", "error")
          }
          return
        }
        var ciphertext = CryptoJS.AES.encrypt(email, MasterKeyLogin);
        var cipherpass = CryptoJS.AES.encrypt(password, MasterKeyLogin);
        var apiBaseUrl =BaseURL;
        var payload={
          "Username":ciphertext.toString(),//"email.username",
          "Clave":cipherpass.toString()//"U2FsdGVkX19N8yY9zG2txQd4FXkCU91FPqVO4ovFAhg="
        }
        setLoader(true)
       
        superagent
            .post(apiBaseUrl+loginApi)
            .send(payload) // sends a JSON post body
            .then(response =>{
              setLoader(false)
              
              if(response.body.exito){
                console.log("empresa ", response.body.data_usuario)
                let id = response.body.data_usuario.id_usuario
                let id_empresa = response.body.data_usuario.id_empresa
                let rif = response.body.data_usuario.rif
                let limite_usuarios = response.body.data_usuario.limite_usuarios
                let ultima_sesion = response.body.data_usuario.ultima_sesion
                let razon_social = response.body.data_usuario.razon_social
                let telefono = response.body.data_usuario.telefono
                let identificador_comercio = response.body.data_usuario.identificador_comercio
                let correo = response.body.data_usuario.correo
                let direccion = response.body.data_usuario.direccion
                let cryptorif = CryptoJS.AES.encrypt(rif, MasterKeyLogin);
                let cryptoid = CryptoJS.AES.encrypt(id.toString(), MasterKeyLogin);
                let cryptoidempresa = CryptoJS.AES.encrypt(id_empresa.toString(), MasterKeyLogin);
                let cryptoultimasesion = CryptoJS.AES.encrypt(ultima_sesion.toString(), MasterKeyLogin);
                let cryptorazon_social = CryptoJS.AES.encrypt(razon_social.toString(), MasterKeyLogin);
                let cryptotelefono = CryptoJS.AES.encrypt(telefono.toString(), MasterKeyLogin);
                let cryptoidentificador_comercio = CryptoJS.AES.encrypt(identificador_comercio.toString(), MasterKeyLogin);
                let cryptocorreo = CryptoJS.AES.encrypt(correo.toString(), MasterKeyLogin);
                let cryptodireccion = CryptoJS.AES.encrypt(direccion.toString(), MasterKeyLogin);
                    sessionStorage.setItem("_CDAUPOMGKLJL",cryptorif.toString())
                    sessionStorage.setItem("_CDAUPOMGKLJLTYJIKH",cryptoid.toString())
                    sessionStorage.setItem("_CDAUPOMGKLJLTYJIKHENTER",cryptoidempresa.toString())
                    sessionStorage.setItem("_CDAUPOMGKLJLTYJIKHENTERLIM",limite_usuarios.toString())
                    sessionStorage.setItem("_CDAUPOMGKLJLTYJIKHENTERLIMETRERT",cryptoultimasesion.toString())
                    sessionStorage.setItem("_CDAUPOMGKLJLTYJIKHENTERLIMETRERTRAZN",cryptorazon_social.toString())
                    sessionStorage.setItem("_CDAUPOMGKLJLTYJIKHENTERLIMETRERTLFN",cryptotelefono.toString())
                    sessionStorage.setItem("_CDAUPOMGKLJLTYJIKHENTERLIMETRERIDFCT",cryptoidentificador_comercio.toString())
                    sessionStorage.setItem("_CDAUPOMGKLJLTYJIKHENTERLIMETRERCOR",cryptocorreo.toString())
                    sessionStorage.setItem("_CDAUPOMGKLJLTYJIKHENTERLIMETRERDIR",cryptodireccion.toString())
 
                history.push("/home");
              }
              else{   
               
                   handleClickOpen("Ingresa un usuario o contraseña correcta","error")
                
               
              }
            })
            .catch(error =>{
              setLoader(false)
              alert(error)
            })
  }

  function btnSignUp(event){
  
    history.push("/signup");
  }
  function matchEmail(value){
    let pattern = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
   
      if (!pattern.test(value)) {
        
        setEmptyMail(true)
        return true
      } else {
        setEmptyMail(false)
        return false
      }

    
  }

  function isEmpty(field,type){
    if(field===""){
      if(type === "mail"){
        setEmptyMail(true)
        return true
      }else if(type ==="password"){
        setEmptyPassword(true)
        return true
      }
      return true
    }else{
      if(type === "mail"){
        setEmptyMail(false)
        return false
      }else if( type ==="password"){
        setEmptyPassword(false)
        return false
      }
      return false
    }
  }

  const handleClickOpen = (msg) => {
    let object = {
      open:true,
      message:msg,
      type: "alert",
      icon:"error"
  }
    changeDialogManagerValues(object)
    // setOpen(true);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }


const styles = {
  paperContainer: {
      backgroundImage: `url(${login})`,
      backgroundSize:'contain',
      with:'100%',
      height:'100%',
      position: 'relative',
      
     
  }
};
  
  return (
    
    <React.Fragment>
  
        
        
    <Grid container  component="main" className={classes.root} >
      <CssBaseline />
      {/* className={classes.image} */}

      <Grid item xs={false} sm={false} md={7} lg={6} >
        <img src={login} className={classes.image}/>
      </Grid>


      <Grid item xs={12} sm={12} md={5} lg ={6} component={Paper} elevation={6}  square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            {/* <LockOutlinedIcon /> */}
            <img src={LogoUser} className={classes.logoUserStyle}></img>
          </Avatar>
          <Typography component="h1" variant="h5" style={{marginTop:"3%"}}>
            Inicia tu sesión
          </Typography>
          <form className={classes.form} >

            <Grid container  alignItems="center" justify="center" >
              
              <Grid item xs={8} >
                <TextField
                 className={classes.rootTextfieldClass}
                  error = {emptyMail}
                  fullWidth
                  margin="normal"
                 
                  required
                  id="email"
                  label="Correo Electrónico"
                  name="email"
                  onChange ={e => setEmail(e.target.value)}
                  disabled = {loader}
                  InputLabelProps={{
                    classes: {
                      root: classes.rootTextfield,
                      focused: "focused",
                      shrink: "shrink"
                    }
                  }}
                  autoFocus
                  helperText={emptyMail?"Campo vacio":""}
                />
              </Grid>


            </Grid>

            <Grid container  alignItems="center" justify="center" >
                
              <Grid item xs={8}>
                <TextField
                  error = {emptyPassword}
                  fullWidth
                  margin="normal"
                  required
                  name="password"
                  label="Contraseña"
                  type={showPassword ? "text":"password"}
                  id="password"
                  className={classes.rootTextfieldClass}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      handleClick(e)
                    }
                  }}
                  InputLabelProps={{
                    classes: {
                      root: classes.rootTextfield,
                      focused: "focused",
                      shrink: "shrink"
                    }
                  }}
                  InputProps={{
                   
                    endAdornment: <InputAdornment position="end"> <IconButton  onClick={handleClickShowPassword}><img alt="edit" src={showPassword? Openeye: Closeeye} className={classes.iconPass} /></IconButton></InputAdornment>,
                
                  }}
                 
                  disabled = {loader}
                  onChange ={e => setPassword(e.target.value)}
                  helperText={emptyPassword?"Campo vacio":""}
                />
              </Grid>
            </Grid>
            
          
            <Grid container  alignItems="center" justify="center" className={classes.gridBarButton} >                
              <Grid item xs={2.5} alignItems="center">
              <Button
                type="button"
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled = {loader}
                onClick={(event) => handleClick(event)}
              >Ingresar
                {loader && <CircularProgress size={24} className={classes.buttonProgress} />}

              </Button>
              </Grid>
            </Grid>

            <Grid container alignItems="center" justify="center" className={classes.gridBarButton}>

              <Grid item xs={4} style={{textAlign:"left"}}>
                <Link href="" onClick={(event) => btnForgotPassword(event)}  variant="body2" style={{color:"#004E9B"}}>
                  ¿Olvidaste tu contraseña?
                </Link>
              </Grid>

              <Grid item xs={4} style={{textAlign:"right"}} > 
                <Link a href="" onClick={(event) => btnSignUp(event)} variant="body2" style={{color:"#004E9B"}}>
                  {"Regístrate aquí"}
                </Link>
              </Grid>

            </Grid>

            
          </form>
          

        </div>
        
            
        <FooterOut page = "login"/>



      </Grid>
    </Grid>
    
    </React.Fragment>

  );
}
const mapDispatchToProps =(dispatch) =>({
  changeDialogManagerValues(args){
      dispatch(getDialogManager(args))
  },
  
})
export default connect(null,mapDispatchToProps)(SignInSide)
