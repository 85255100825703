import React, { useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/core/styles';
import numeral from 'numeral';
const useStyles = makeStyles(theme => ({
  divContainer: {
    marginTop: -30,

  }

}));

export default function PieComponent(props) {
  const [data, setData] = useState()
  const classes = useStyles();
  useEffect(() => {


    const dat = {
      labels: [props.datos[0].name + " Bs", props.datos[1].name + " Bs", props.datos[2].name + " Bs"],
      datasets: [
        {

          data: [Number(props.datos[0].value), Number(props.datos[1].value), Number(props.datos[2].value)],
          backgroundColor: [
            '#FF5800',
            '#004E9B',
            '#F9E300',

          ],
          borderColor: [
            '#FF5800',
            '#004E9B',
            '#F9E300',

          ],
          borderWidth: 1,

        },
      ],

    };

    setData(dat)
  }, [props])

  return (

    <div className={classes.divContainer}>

      {data !== undefined ? <Pie data={data} width={500} height={170} options={{
        maintainAspectRatio: false, locale: 'de-DE', plugins: {
          legend: {
            display: true,
            labels: {
              usePointStyle: true,
              boxWidth: 6
            }
          }
        }
      }} /> : ""}
    </div>

  );

}