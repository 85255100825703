
export const  BaseURL =  "https://api.nextpos.us/dpos/v1/" //"https://api.sandbox.nextpos.us/dpos/v1/"//"https://api.nextpos.us/Usuarios/"//"http://localhost:3000/Usuarios/" // "https://api.sandbox.nextpos.us/dpos/v1/"
export const  getAllTransactions = "getTodasTransaccionesEmpresasDashboard?rif="
export const  updatePasswordAdmin = "updatePassword"
export const  addUserDPOS = "addUserDPOSNuevo"
export const  deleteUserDPOS = "deleteUserDPOS"
export const  clearDeviceIdDPOS = "clearDeviceIdDPOS"
export const  updatePasswordDPOS = "updatePasswordDPOS"
export const  sendMailAdmin = "sendRecuperarClave"
export const  getAllUserDPOS = "getAllUserDPOS"
export const  addNewUserAdmin = "addNewUserAdmin"
export const  loginApi = "login_admin"
export const  SumTrxApi = "sum_trx"
export const  MasterKeyLogin = "l061nu53r"
export const  SumTrxDate = "sum_trx_to_date"
export const  getTransactionsByDate = "getTransaccionesFecha"
export const  getTrxMonth = "get_transacciones_mes"
export const  getTrxDetail = "getReporteTransaccionesComercio"
export const  getAllTransactionsByDateUser = "getAllTransactionsByDateUser"
export const  getTransactionsByFilter = "getTransactionsByFilter"
