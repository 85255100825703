import  React, { createContext } from "react";
import './App.css';
import Routes from './routes/routes'
import {Provider} from 'react-redux'
import store from './store/store';
import AlertMod from './modals/alertMod';
import { createMuiTheme } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';

const datePickerMaterialThemeLightBlue = createMuiTheme({
 
  overrides: {
      
      MuiPickersDay: {
        
          daySelected: {
              backgroundColor:"#004E9B"
          },
         
          current: {
              color: "#004E9B"
          },
          
      },
   
      MuiInput: {
       
        underline: {
          '&:hover:not($disabled):after': {
            borderBottomColor: "#004E9B",
          },
          '&:hover:not($disabled):before': {
            borderBottomColor: "#004E9B",          // String should be terminated
          },
           '&:after': { //focused
            borderBottomColor: "#004E9B",
            
           },
          
        },
        
        
       
     },
    
    //  MuiInputLabel: {
     
      
    //     shrink: {
    //       outlined: {
    //         color:"gray"
    //       }
    //    }
      
      
    // }
    
       
     

      
  },
  
});
function App() {
 
  
  return (
    <ThemeProvider theme={datePickerMaterialThemeLightBlue}>
      <Provider store ={store}>
            <AlertMod/>
          <Routes/>
      </Provider> 
    </ThemeProvider>
   
      
  
    
        
    
    
      /*<BrowserRouter>
        <Switch>
          <Route path="/login" component={SignInSide} />
          <Route path="/home" component={Dashboard} />
        </Switch>
      </BrowserRouter>*/
      

    
    //<SignInSide/>
    //<Dashboard/>
  );
}

export default App;
