import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import axios from 'axios'
import CryptoJS from 'crypto-js'
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Buttons from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import { BaseURL, addNewUserAdmin, MasterKeyLogin } from '../constants/constants'
import LogoRegistro from '../assets/icn_identificacion.png'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from "@material-ui/core/IconButton";
import m_cn_rgb_sin_bg from '../assets/m_cn_rgb_sin_bg.png'
import Closeeye from '../assets/closeeye.png'
import Openeye from '../assets/openeye.png'
import IcWarning from '../assets/ic_warning.png';
import IcError from '../assets/ic_error.png';
import FooterOut from '../componentUtils/FooterOut';
import InputAdornment from '@material-ui/core/InputAdornment';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import Box from '@material-ui/core/Box'
import { connect } from 'react-redux';
import { getDialogManager } from '../actions';
import { Paper } from '@material-ui/core';
const useStyles = makeStyles(theme => ({
  rootTextfieldClass: {
    "& .MuiFormLabel-root.Mui-error": {
      "&.shrink": {
        color: "#fb665a"
      },
      '&:after': { //focused
        borderBottomColor: "#fb665a",
      },

      color: "#fb665a"
    },

    "& .MuiInput-underline.Mui-error:after": {
      borderBottomColor: "#fb665a !important"
    },
    width: '40ch',





  },
  rootTextfield: {

    "&.shrink": {
      color: "#004E9B",

    }
  },
  root: {
    flexGrow: 1,

  },
  title: {
    flexGrow: 1,
  },
  paper: {

    display: 'block',
    //flexDirection: 'column',
    alignItems: 'center',
    //height:'100vh'

  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "white",
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    paddingTop: '5%',
  },
  submit: {
    color: "white",
    marginTop: "10%",
    borderRadius: "25px",
    background: "linear-gradient(180deg, #004E9B, #009FDA)",
    textTransform: "capitalize",
    width: "150px",


  },

  footerGrid: {
    width: "100%",
    position: "fixed",
    zIndex: "1000",
    bottom: "0",
    height: 25,
    paddingLeft: "10px",
    paddingRight: "10px",
    backgroundColor: "#EBEBEB",
  },

  buttonProgress: {
    color: "white",
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,

  },
  gridCentral: {


    textAlign:"center",
 margin:0

    // position:"fixed",
    // bottom:"0",
    // marginBottom:"15%"

  },

  gridLink: {
    marginTop: "30%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  logoRegistroStyle: {

    width: "35px",
    height: "25px",
    margin: "auto",
    display: "block"
  },
  logoMercantilStyle: {
    width: "171px",
    height: "61px",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  appBar: {
    position: "fixed",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: "#000048"
  },
  btnAccept: {
    margin: theme.spacing(3, 0, 2),
    borderRadius: "25px",
    backgroundColor: "#004E9B",
    textTransform: "capitalize",
    width: "150px",
  },
  icClassAlert: {
    position: "fixed",
    width: "45px",
    height: "45px",
    left: 0,
    right: 0,
    justifyContent: "center",
    margin: "auto",
    marginTop: "-40px"
  },
  iconPass: {
    height: 25,
    width: 25
  }

}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function SignUp(props) {
  const classes = useStyles();
  const [idcommerce, setIdCommerce] = useState("")
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("")
  const [loader, setLoader] = useState(false)
  const [emptyIdCommerce, setEmptyIdCommerce] = useState(false)
  const [emptyMail, setEmptyMail] = useState(false)
  const [emptyPassword, setEmptyPassword] = useState(false)
  const [visibility, setVisibility] = useState(false)
  //const [countNumbers, setCountNumber] = useState(0)
  const [counterValid, setCounterValid] = useState({
    countNumbersValid: false,
    countCharsValid: false,
    countSpecialCharsValid: false,
    countTotalsValid: false,
  })

  let numbers = ""
  let characters = ""
  let especialChars = ""
  function handleClick(event) {
    var isidEmpty = isEmpty(idcommerce, "id_commerce")
    var ismailEmpty = isEmpty(email, "mail")
    var isPassEmpty = isEmpty(password, "password")

    if (isidEmpty || ismailEmpty || isPassEmpty || !counterValid.countTotalsValid || !counterValid.countCharsValid || !counterValid.countSpecialCharsValid || !counterValid.countSpecialCharsValid) {
      return
    }

    var cipherpass = CryptoJS.AES.encrypt(password, MasterKeyLogin);
    var apiBaseUrl = BaseURL;
    var payload = {
      "id_commerce": idcommerce,
      "mail": email,
      "password": cipherpass.toString()
    }

    setLoader(true)
    axios.post(apiBaseUrl + addNewUserAdmin, payload)

      .then(function (response) {
        setLoader(false)
        if (response.data.Exito) {
          //setMsgs(response.data.Message)
          props.history.push({
            pathname: '/getStarted',
            state: { user: email, password: cipherpass.toString() }
          })
          //handleClickOpen("¡Tu comercio ha sido registrado exitosamente! Ya puedes iniciar tu sesión","success")
        }
        else {
          if (response.data.Message === "codigo de comercio invalido") {
            return handleClickOpen("No te encuentras afiliado al servicio de Punto de Venta Mercantil con Dpos. Para conocer cómo hacerlo, ingresa a nuestra página web www.mercantilbanco.com y ubica en el menú la opción Empresas > Pagos y Cobranzas > Puntos de Venta y Soluciones de Pago > Dpos.", "warning")

          } else if (response.data.Message === "email ya registrado") {
            return handleClickOpen("Email ya registrado", "error")


          }
          handleClickOpen(response.data.Message, "error")
        }

      })
      .catch(function (error) {
        setLoader(false)
        handleClickOpen("Algo ha salido mal… Intenta realizar esta operación más tarde", "error")

      });
  }

  function isEmpty(field, type) {
    if (field === "") {
      if (type === "id_commerce") {
        setEmptyIdCommerce(true)
        return true
      } else if (type === "mail") {
        setEmptyMail(true)
        return true
      } else if (type === "password") {
        if (!counterValid.countNumbersValid || !counterValid.countCharsValid || !counterValid.countSpecialCharsValid || !counterValid.countTotalsValid) {
          setEmptyPassword(true)
        }

        return true
      }
      return true
    } else {
      if (type === "id_commerce") {
        if (field.length < 6) {
          handleClickOpen("El código de afiliación debe contener 6 dígitos", "error")
          setEmptyIdCommerce(true)
          return true
        } else {
          setEmptyIdCommerce(false)
          return false
        }

      } else if (type === "mail") {

        let mail_format = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
        if (!mail_format.test(field)) {
          handleClickOpen("Verifica el correo electrónico ingresado e intenta nuevamente", "error")
          setEmptyMail(true)
          return true
        } else {
          setEmptyMail(false)
          return false
        }

      } else if (type === "password") {
        if (counterValid.countNumbersValid && counterValid.countCharsValid && counterValid.countCharsValid && counterValid.countTotalsValid) {
          setEmptyPassword(false)
          return false
        } else {
          handleClickOpen("La contraseña ingresada no cumple con las condiciones requeridas", "error")
          setEmptyPassword(true)
          return true
        }


      }
      return false
    }
  }
  const handleClickOpen = (msg, icon) => {
    let object = {
      open: true,
      message: msg,
      type: "alert",
      icon: icon
    }
    props.changeDialogManagerValues(object)
  };

  const handleClickAlert = () => {
    handleClickOpen("No te encuentras afiliado al servicio de Punto de Venta Mercantil con Dpos. Para conocer cómo hacerlo, ingresa a nuestra página web www.mercantilbanco.com y ubica en el menú la opción Empresas > Pagos y Cobranzas > Puntos de Venta y Soluciones de Pago > Dpos.", "warning")
  }

  const handleTypeCharPassword = (e) => {
    e.preventDefault()

    for (let i = 0; i < e.target.value.length; i++) {
      if (!isNaN(e.target.value.charAt(i))) {
        numbers += e.target.value.charAt(i)
      } else if ((/[a-zA-Z]/).test(e.target.value.charAt(i))) {
        characters += e.target.value.charAt(i)
      } else if ((/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/).test(e.target.value.charAt(i))) {
        especialChars += e.target.value.charAt(i)
      }


    }
    handlerValidate("number", numbers.length)
    handlerValidate("characters", characters.length)
    handlerValidate("spelcialChars", especialChars.length)
    handlerValidate("totalCharacters", e.target.value.length)

  }

  const handlerValidate = (typeChar, lengthString) => {

    switch (typeChar) {
      case "number":
        if (lengthString > 0 && lengthString <= 3) {
          setCounterValid((prevState) => ({
            ...prevState,
            countNumbersValid: true,
          }))
        } else {
          setCounterValid((prevState) => ({
            ...prevState,
            countNumbersValid: false,
          }))
        }
        break;
      case "characters":

        if (lengthString >= 6 && lengthString <= 8) {
          setCounterValid((prevState) => ({
            ...prevState,
            countCharsValid: true,
          }))
        } else {
          setCounterValid((prevState) => ({
            ...prevState,
            countCharsValid: false,
          }))
        }
        break;
      case "spelcialChars":

        if (lengthString === 1) {
          setCounterValid((prevState) => ({
            ...prevState,
            countSpecialCharsValid: true,
          }))
        } else {
          setCounterValid((prevState) => ({
            ...prevState,
            countSpecialCharsValid: false,
          }))
        }
        break;
      case "totalCharacters":

        if (lengthString >= 8) {
          setCounterValid((prevState) => ({
            ...prevState,
            countTotalsValid: true,
          }))
        } else {
          setCounterValid((prevState) => ({
            ...prevState,
            countTotalsValid: false,
          }))
        }
        break;
      default:
        break;
    }
  };

  const handleClickShowPassword = () => {
    setVisibility(!visibility)
  };

  return (
    <React.Fragment>



      <AppBar className={classes.appBar}>
        <Toolbar style={{ background: "linear-gradient(90deg, rgba(0,78,159,1) 0%, rgba(1,154,216,1) 12%, rgba(0,78,155,1) 39%)" }}>
          <IconButton edge="start" color="inherit" className={classes.menuButton}  >
          </IconButton>
          <img src={m_cn_rgb_sin_bg} className={classes.logoMercantilStyle}></img>
          <Typography variant="h6" className={classes.title}>
          </Typography>
          <Button color="inherit"><label style={{ fontSize: "18px", textTransform: "capitalize" }}>Portal Dpos</label></Button>
        </Toolbar>
      </AppBar>

      <CssBaseline />

      {/* <Container component="main" maxWidth="xs" style={{backgroundColor:"silver"}}> */}
      {/* <CssBaseline /> */}

      <Paper elevation={0} style={{ backgroundColor: "transparent", maxWidth: "400px", margin: "auto", display: "block", marginTop: "8%" }}>

        <Grid container className={classes.paper}  >

          <Grid item alignItems='center'   >

            <Grid item xs textAlign='center' >

              <img src={LogoRegistro} className={classes.logoRegistroStyle}></img>
              <Typography component="h1" variant="h6" style={{ textAlign: 'center', paddingTop: "3%" }}>
              Regístrate
            </Typography>

            </Grid>

            {/* <LockOutlinedIcon /> */}


           

            <form className={classes.form} noValidate autoComplete="off">

              <Grid container spacing={1.5}  style={{textAlign:"center"}}>
                <Grid item xs={12}>
                  <TextField
                    error={emptyIdCommerce}
                    autoComplete="fname"
                    name="firstName"
                    className={classes.rootTextfieldClass}
                    required

                    id="idCommerce"
                    label="Código de afiliación"
                    labelFontSize={10}
                    fontSize={10}
                    autoFocus
                    disabled={loader}
                    inputProps={{ maxLength: 6 }}
                    onChange={e => setIdCommerce(e.target.value)}
                    InputLabelProps={{
                      classes: {
                        root: classes.rootTextfield,
                        focused: "focused",
                        shrink: "shrink"
                      }
                    }}
                  />

                </Grid>

                <Grid item xs={12} style={{textAlign:"center"}}>
                  <Link

                    onClick={handleClickAlert}
                    variant="body2"
                    style={{ color: "#004E9B",paddingRight:"20%" }}
                  >
                    ¿No tienes un código de afiliación?
                  </Link>
                </Grid>


                <Grid item xs={12}>
                  <TextField
                    error={emptyMail}
                    required
                    
                    id="email"
                    label="Correo electrónico"
                    name="email"
                    className={classes.rootTextfieldClass}
                    disabled={loader}
                    inputProps={{ maxLength: 80 }}
                    onChange={e => setEmail(e.target.value)}
                    InputLabelProps={{
                      classes: {
                        root: classes.rootTextfield,
                        focused: "focused",
                        shrink: "shrink"
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12}>

                  <TextField
                    error={emptyPassword}
                    required
                    
                    name="password"
                    label="Contraseña"
                    type={visibility ? 'text' : 'password'}
                    id="password"
                    autoComplete="current-password"
                    disabled={loader}
                    inputProps={{ maxLength: 10 }}
                    className={classes.rootTextfieldClass}
                    onChange={e => { setPassword(e.target.value); handleTypeCharPassword(e) }}
                    InputLabelProps={{
                      classes: {
                        root: classes.rootTextfield,
                        focused: "focused",
                        shrink: "shrink"
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                          // onMouseDown={handleMouseDownPassword}
                          >
                            <img alt="edit" src={visibility ? Openeye : Closeeye} className={classes.iconPass} />

                          </IconButton>
                        </InputAdornment>
                      )
                    }}

                  />
                </Grid>

                

              </Grid>

              <Grid container style={{marginTop:"1%",paddingLeft:"8%",paddingRight:"8%",fontSize:"12px"}}>
                  <Grid item xs={6}>

                    {counterValid.countTotalsValid ? <CheckCircleIcon style={{ color: "#004E9B", marginLeft: 10, marginTop: 10 }} fontSize="small" /> : <CancelIcon style={{ color: "#fb665a", marginLeft: 10, marginTop: 10 }} fontSize="small" />}


                    <Typography variant="p" style={{ marginLeft: 10 }}>De 8 a 10 caracteres</Typography>

                  </Grid>
                  <Grid item xs={6}>
                    {counterValid.countNumbersValid ? <CheckCircleIcon style={{ color: "#004E9B", marginLeft: 10, marginTop: 10 }} fontSize="small" /> : <CancelIcon style={{ color: "#fb665a", marginLeft: 10, marginTop: 10 }} fontSize="small" />}


                    <Typography variant="p" style={{ marginLeft: 10 }}>1 a 3 números</Typography>
                  </Grid>

                  <Grid item xs={6}>
                    {counterValid.countCharsValid ? <CheckCircleIcon style={{ color: "#004E9B", marginLeft: 10, marginTop: 10 }} fontSize="small" /> : <CancelIcon style={{ color: "#fb665a", marginLeft: 10, marginTop: 10 }} fontSize="small" />}

                    <Typography variant="p" style={{ marginLeft: 10 }}>Mínimo 6 letras</Typography>

                  </Grid>
                  <Grid item xs={6}>
                    {counterValid.countSpecialCharsValid ? <CheckCircleIcon style={{ color: "#004E9B", marginLeft: 10, marginTop: 10 }} fontSize="small" /> : <CancelIcon style={{ color: "#fb665a", marginLeft: 10, marginTop: 10 }} fontSize="small" />}


                    <Typography variant="p" style={{ marginLeft: 10, textAlign: "center" }}>1 caracter especial</Typography>
                    <br></br>
                    <Typography variant="p" style={{ marginLeft: 25, textAlign: "center" }}>ejemplo : (*,_,@,#,$,^,-)</Typography>
                  </Grid>

                </Grid>

            

              <Grid container className={classes.gridCentral}>
                <Grid item xs>
                  <Button
                    fullWidth
                    color="white"
                    className={classes.submit}
                    disabled={loader}
                    onClick={(event) => handleClick(event)}
                  >
                    {loader && <CircularProgress size={24} className={classes.buttonProgress} />}
                    Registrar
                  </Button>

                  <Grid item xs style={{ textAlign: 'center',paddingTop:"4%" }}>
                    <Link href="./login" variant="body2" style={{ color: "#004E9B" }} >
                      ¿Ya te registraste? Inicia tu sesión
                    </Link>
                  </Grid>


                </Grid>
              </Grid>




            </form>

          </Grid>



        </Grid>

      </Paper>




      {/* </Container> */}

      <FooterOut page="signup" />



    </React.Fragment>
  );

}
const mapDispatchToProps = (dispatch) => ({
  changeDialogManagerValues(args) {
    dispatch(getDialogManager(args))
  },

})
export default connect(null, mapDispatchToProps)(SignUp)
