import React, { useState} from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import MaterialTable from 'material-table';
import TextField from '@material-ui/core/TextField';

//import UserRegister from './UserRegister';
import { ResponsiveContainer } from 'recharts';
import { Container, Row, Col } from 'react-grid-system';
//import RegisterUser from './RegisterUser';

import {  withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { useMediaQuery } from 'react-responsive'

import Slide from '@material-ui/core/Slide';
import {BaseURL,getAllUserDPOS,addUserDPOS,deleteUserDPOS,clearDeviceIdDPOS,updatePasswordDPOS,MasterKeyLogin} from '../../../constants/constants'
import axios from 'axios';
import CryptoJS from 'crypto-js' 
import Typography from '@material-ui/core/Typography';
import { Tooltip } from '@material-ui/core';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IcWarning from '../../../assets/ic_warning.png';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from "@material-ui/core/IconButton";
//import { green } from '@material-ui/core/colors';
//import { InputAdornment } from '@material-ui/core/InputAdornment';
import ic_device from '../../../assets/ic_device.png'
import ic_delete from '../../../assets/ic_delete.png'
import ic_password from '../../../assets/ic_password.png'
import ic_search from '../../../assets/ic_search.png'
import Openeye from '../../../assets/openeye.png'
import Closeeye from '../../../assets/closeeye.png'
import { connect } from 'react-redux';
import { getDialogManager } from '../../../actions';
import socket from '../../../constants/socket';
import Snackbar from '@material-ui/core/Snackbar';


function TransitionLeft(props) {
  return <Slide {...props} direction="left" />;
}

// function TransitionUp(props) {
//   return <Slide {...props} direction="up" />;
// }

// function TransitionRight(props) {
//   return <Slide {...props} direction="right" />;
// }


const useStyles = makeStyles(theme => ({
  rootTextfieldClass:{
    "& .MuiFormLabel-root.Mui-error": {
      "&.shrink": {
        color: "#fb665a"
      },
      '&:after': { //focused
        borderBottomColor: "#fb665a",
       },
   
       color: "#fb665a"
    },

    "& .MuiInput-underline.Mui-error:after": {
      borderBottomColor: "#fb665a !important"
    },
    

   
    
  },
  rootTextfield: {
    
    "&.shrink": {
      color: "#004E9B"
    }
  },
    root:{
      width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
    },
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
      
    },
    fixedHeight: {
      height: 240,
    },
    fixedHeightUser: {
      height: 300,
    },
    alert:{
      backgroundColor: 'green'
    },
    successText:{
      color:'#004E9B'
    },
    
    rejectText:{
      color : '#fb665a'
    },
    title_principal:{

      color: "#004E9B",
      marginTop:"3%",
      marginLeft:"0%",
      fontSize: "18px",
    },
    title_usuario:{
      color: "#004E9B",
      marginTop:"2%",
      marginBottom:"1%",
      marginLeft:"3%",
      fontSize: "18px",
      
    },
    title_detalle:{
      
      paddingTop:"2%",
      marginLeft:"0%",
      fontSize: "15px",
    },
    btn_crear_user:{
      // paddingTop:"2%",
      marginLeft:"0%",
      background: "linear-gradient(180deg, #004E9B, #009FDA)",
      color:"#FFF"
    },
   
    txtUser:{
      //paddingTop:"2%",
      // '& .MuiTextField-root': {
      //   color: "#004E9B",
      // },
      paddingTop:"2%",
      marginLeft:"3%",
      [theme.breakpoints.down('md')]: {
       
        marginTop:"2%",}
    },
    txtPassword:{
      "& .MuiFormLabel-root.Mui-error": {
        "&.shrink": {
          color: "#fb665a"
        },
        '&:after': { //focused
          borderBottomColor: "#fb665a",
         },
     
         color: "#fb665a"
      },
  
      "& .MuiInput-underline.Mui-error:after": {
        borderBottomColor: "#fb665a !important"
      },

      marginLeft:"3%",
      [theme.breakpoints.down('md')]: {
        // margin:"0 auto",
        // marginTop:"5%",
        width:"50%"
      }
    },
    submit: {
      color:"white",
      margin: theme.spacing(3, 4, 2),
      borderRadius:"25px",
      background: "linear-gradient(180deg, #004E9B, #009FDA)",
      textTransform:"capitalize",
      width:"150px",
      [theme.breakpoints.down('md')]: {
        // margin:"0 auto",
        // marginTop:"15%",
      }
      //marginLeft:"2.5%",
    },
    icClassAlert:{
      position: "fixed",
      width: "45px",
      height: "45px",
      left:0,
      right:0,
      justifyContent:"center",
      margin:"auto",
      marginTop:"-40px"
    
    },
    dialogAccept: {
      margin: theme.spacing(3, 4, 2),
      borderRadius:"25px",
      background: "linear-gradient(180deg, #004E9B, #009FDA)",
      textTransform:"capitalize",
      width:"120px",
      color:"#FFF"

    },
    dialogCancel: {
      margin: theme.spacing(3, 4, 2),
      borderRadius:"25px",
      backgroundColor: "#FFF",
      textTransform:"capitalize",
      width:"120px",
      color:"#004E9B",
      border:"1px solid #004E9B",
      

    },
    iconPass:{
      height:25,
      width:25
    },
    imageIcons:{
      height:24
    }
  }));





 function Create (props){
    
    let defrif = props.rif
    let deid = props.id
    let deidempresa = props.id_empresa
    let limite_usuarios = props.limite_usuarios
    const [open,setOpen] = useState(false)
    const [openSnackBar,setOpenSnackBarSnackBar] = useState(false)
    const [openDialog,setOpenDialog] = useState(false)
    const [dialogMessage,setDialogMessage] = useState("")
    const [openAlert, setOpenAlert] = useState(false)
    const [success,setSuccess] = useState(false)
    const [successUserDeleted,setSuccessUserDeleted] = useState(false)
    const [successClearDeviceId,setSuccessClearDeviceId] = useState(false)
    const [successChangePassword,setSuccessChangePassword] = useState(false)
    const [loader,setLoader] = useState(false)
    const [loaderTable, setLoaderTable]= useState(false)
    const [table, setStateTable]=React.useState({
      data: [
        //{ fecha: 'Mehmet', monto: 'Baran', aprobacion: 1987, referencia: 63, lote: 63 },
        //{ fecha: 'Zerya Betül',monto: 'Baran',aprobacion: 2017,referencia: 34,lote: 34},
        
      ],
  
    })
   
    const[contextDialog, setContextDialog] = useState()
    const [add, setAdd] =useState(false)
    const [idUserDPOS, setIdUserDPOS] = useState()
    const[update,setUpdate] = useState(false)
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [passwordChange, setPasswordChange] = useState("")
    const [emptyMail,setEmptyMail] = useState(false)
    const [emptyPassword,setEmptyPassword]= useState(false)
    const [emptyPasswordChange,setEmptyPasswordChange]= useState(false)
    const [visibility,setVisibility] = useState(false)
    const [visibilityNewPass,setVisibilityNewPass] = useState(false)
    const classes = useStyles();
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
    const fixedHeightPaperUser = clsx(classes.paper, classes.fixedHeightUser);
   
    const isDesktopOrLaptop = useMediaQuery({
      minWidth: 1024,
     maxWidth:1440
    })
    const isDesktop = useMediaQuery({
      minWidth: 1440,
      maxWidth:1536
      
    })
    const isBigScreen = useMediaQuery({
      query: '(min-device-width:1536px)'
    })
    const handleClickOpen = (e) => {
      e.preventDefault()
      if(email !== "" && password !==""){
        setEmail("")
        setPassword("")
      }
      if(!open){
        setOpen(true)
      }else{
        setOpen(false)
      }
    };

    React.useEffect(() => {
       
        let id = deid
        let payload = {
          id_user_admin : id
        }
        let today
        axios.post(BaseURL+getAllUserDPOS,payload)
        
        .then(res => {
            setLoader(false)
            
            if (res.data[0] !== undefined) {
               let datos  = res.data
               console.log("DATOS ",datos)
               let str = "";
               datos.forEach(function(e){
                e["app"] = "DPOS"
                // 
                str = e.fecha.split("T")
                e.fecha = str[0]
                today= new Date(e.fecha)
                today.setDate(today.getDate()+1);
                e.fecha =today.toLocaleDateString("en-GB")
                
                e.hora = str[1].split(".")[0]
                
                if(e.estatus === 1){
                  e.estatus = "Aprobado"
                  
                }else if (e.estatus ===2){

                  e.estatus = "Pendiente"
                  // <Tooltip title="En espera por aprobación del banco" aria-label="add">
                  //                   <a>
                  //                     Pendiente
                  //                   </a>   
                  //                 </Tooltip>
                }else{
                  e.estatus= "No califica"
                  // <a className ={classes.rejectText}>
                  //                  No califica
                  //               </a>
                }
               
              })
              
              
              
             
              
               setStateTable({data:datos})
                return res.data;
              } else {
                
                return 'Null';
              }
              
        })
        .catch(err=>{
          setLoader(false)
          
        })
    
    },[update])

    if(deidempresa !== undefined){
      socket.on("respuesta para cliente "+deidempresa.toString(), () => {
        console.log("usuario aprobado")
        setUpdate(true)
        handleOpenSnackBar()
        
      })
    }else{
      socket.disconnect()
    }

    socket.on('disconnect', function () {
      console.log("LOG: just disconnected: " + socket.id)
      socket.emit('se desconecto la empresa',deidempresa)
    })
    /*const handleToUpdate = () => {
        setLoader(true)
    }*/

    function isEmpty(field,type){
      if(field===""){
        if(type === "mail"){
          setEmptyMail(true)
          return true
        }else if(type ==="password"){
          setEmptyPassword(true)
          return true
        }else if (type ==="passwordChange"){
          setEmptyPasswordChange(true)
          return true
        }
        return true
      }else{
        if(type === "mail"){
          setEmptyMail(false)
          return false
        }else if( type ==="password"){
          setEmptyPassword(false)
          return false
        }
        else if( type ==="passwordChange"){
          setEmptyPasswordChange(false)
          return false
        }
        return false
      }
    }
    
    function handleToUpdate(){
       
        setLoader(true)
        
    } 

    const handleOpenSnackBar =() => {
      setOpenSnackBarSnackBar(true);
    };
  
    const handleCloseSnackBar = () => {
      setOpenSnackBarSnackBar(false);
    };
    const handleClickShowPassword =() => {
      setVisibility(!visibility)
    };  
    const handleClickShowNewPassword =() => {
      setVisibilityNewPass(!visibilityNewPass)
    };  
    const ColorButton = withStyles(theme => ({
        root: {
          borderRadius:'20px',
      
          marginTop: '50px',
          
          color:"#FFF", 
          backgroundColor: 'transparent',
          '&:hover': {
            backgroundColor: '#000048',
            color:"white"
          },
          textTransform:"capitalize",
  
        },
    }))(Button);
  
    const handleClickConfirm = (e)=>{
      e.preventDefault()
     
      let ismailEmpty = isEmpty(email,"mail")
      let isPassEmpty = isEmpty(password,"password")
      if(ismailEmpty || isPassEmpty){
        return
      }
      setDialogMessage("El usuario creado sólo funcionará en el primer dispositivo donde inicia sesión.")
      setContextDialog(4)
                                    
      setOpenDialog(true)
    }
    const handleClickSend = () => {
          //e.preventDefault()
          
          let mailField = document.getElementById('email')
          let passField = document.getElementById('password')
          mailField.value = ""
          passField.value = ""
          let ismailEmpty = isEmpty(email,"mail")
          let isPassEmpty = isEmpty(password,"password")
  
          if(ismailEmpty || isPassEmpty){
            return
          }

          let cipherpass = CryptoJS.AES.encrypt(password, MasterKeyLogin);
          let apiBaseUrl =BaseURL;
          
          
          let payload={
            "id_user_admin":deid,
            "id_empresa":deidempresa,
            "user":email.toLowerCase(),
            "password":cipherpass.toString(),//"U2FsdGVkX19N8yY9zG2txQd4FXkCU91FPqVO4ovFAhg="
            "limite_usuario":limite_usuarios
          }
          setLoader(true)
         
          axios.post(apiBaseUrl+addUserDPOS, payload)
          
          .then(function (response) {
            
            
                if(response.data.Exito===true){
                  setUpdate(true)
                  setLoader(false)
                  setUpdate(false)
                  //setOpenAlert(true)
                  console.log("RESPONSE ",table.data)
                  let aprobados = []
                  table.data.map((value,index,array)=>{
                    if(value.estatus === "Aprobado"){
                      aprobados.push(array[index])
                    }
                  })
                  console.log("DATO ",  aprobados.length)
                  if(aprobados.length >= parseInt(limite_usuarios) ){
                    console.log("primera condicion")
                    if(response.data.Condition === "no es primera vez"){
                      handleOpenDialog("¡El usuario ha sido creado con éxito!","success")
                    }else{
                      handleOpenDialog("Tu solicitud será procesada en los próximos 4 días hábiles","warning")
                    }
                    
                  }else{
                    console.log("segunda condicion")
                  
                    handleOpenDialog("¡El usuario ha sido creado con éxito!","success")
                    
                  }
                  //
                  // setSuccess(true)
                  socket.emit("emitir peticion a banco")
                }
                else{
                  setUpdate(false)
                  setLoader(false)   
                  setOpenAlert(true)
                  // setSuccess(false)
                  
                  handleOpenDialog("Ya existe un usuario registrado con ese nombre, por favor ingresa otro e intenta nuevamente","warning")
                 

                }

                setEmail("")
                setPassword("")
                
          })
          .catch(function (error) {
              setUpdate(false)
              setLoader(false) 
              setOpenAlert(true)
              // setSuccess(false)
              
              handleOpenDialog("Algo ha salido mal… Intenta realizar esta operación más tarde","error")
              setEmail("")
              setPassword("")
          });
    };

    const handleClickDeleteUserDPOS = (e,id) => {
      e.preventDefault()
   
      let apiBaseUrl =BaseURL;
      
      
      let payload={
        "id_user":id,
        "id_empresa":deidempresa
        
      }
      setLoader(true)
      axios.post(apiBaseUrl+deleteUserDPOS, payload)
      
      .then(function (response) {
        
            if(response.data.exito===true){
              setUpdate(true)
              setLoader(false)
              setUpdate(false)
              setOpenAlert(true)
              setSuccessUserDeleted(true)
              
              handleOpenDialog("¡El usuario fue eliminado con éxito!","success")
             
            }
            else{
              setUpdate(false)
                setLoader(false)   
                setOpenAlert(true)
                setSuccessUserDeleted(false)
              if(response.data.message === "Ya haz excedido el límite de ediciones por mes"){
                
                handleOpenDialog("Has alcanzado la cantidad máxima mensual de eliminaciones realizadas con este usuario","warning")
              }else{
                handleOpenDialog("Algo ha salido mal… Intenta realizar esta operación más tarde","error")
              }
            }
            
            setIdUserDPOS()
      })
      .catch(function (error) {
          setUpdate(false)
          setLoader(false) 
          setOpenAlert(true)
          setSuccessUserDeleted(false)
          
          handleOpenDialog("Algo ha salido mal… Intenta realizar esta operación más tarde","error")
          setIdUserDPOS()
      });
};

    const handleClickClearUserDPOS = (e,id) => {
          e.preventDefault()
      
          let apiBaseUrl =BaseURL;
          
          
          let payload={
            "id_user":id,
            "id_empresa":deidempresa,
          }
          setLoader(true)
          axios.post(apiBaseUrl+clearDeviceIdDPOS, payload)
          
          .then(function (response) {
            
                if(response.data.exito===true){
                  setUpdate(true)
                  setLoader(false)
                  setUpdate(false)
                  setOpenAlert(true)
                  setSuccessClearDeviceId(true)
                  handleOpenDialog("¡Listo! Ya puedes utilizar tu usuario en otro dispositivo","success")
                }
                else{
                  setUpdate(false)
                  setLoader(false)   
                  setOpenAlert(true)
                  setSuccessClearDeviceId(false)
                  handleOpenDialog("Has alcanzado la cantidad máxima mensual de cambios de dispositivos realizados con este usuario","warning")
                
                }
                
                setIdUserDPOS()
          })
          .catch(function (error) {
              setUpdate(false)
              setLoader(false) 
              setOpenAlert(true)
              setSuccessClearDeviceId(false)
              
              setIdUserDPOS()
              handleOpenDialog("Algo ha salido mal… Intenta realizar esta operación más tarde","error")
          });
    };

    const handleClickChangePassword = (e,id)=>{
          e.preventDefault()
              
              
          

          var cipherpass = CryptoJS.AES.encrypt(passwordChange, MasterKeyLogin);
          var apiBaseUrl =BaseURL;
          
          
          var payload={
            "id_user":id,
            "id_empresa":deidempresa,
            "password":cipherpass.toString()//"U2FsdGVkX19N8yY9zG2txQd4FXkCU91FPqVO4ovFAhg="
          }
          setLoader(true)
          axios.post(apiBaseUrl+updatePasswordDPOS, payload)
          
          .then(function (response) {
            
                if(response.data.exito===true){
                  setUpdate(true)
                  setLoader(false)
                  setUpdate(false)
                  setOpenAlert(true)
                 
                  handleOpenDialog("¡Listo! Has cambiado tu contraseña","success")
                  setSuccessChangePassword(true)
                }
                else{
                  setUpdate(false)
                  setLoader(false)   
                  setOpenAlert(true)
                  handleOpenDialog("Algo ha salido mal… Intenta realizar esta operación más tarde","error")
                  setSuccessChangePassword(false)
                
                }
                
          })
          .catch(function (error) {
              setUpdate(false)
              setLoader(false) 
              setOpenAlert(true)
              setSuccessChangePassword(false)
              
              handleOpenDialog("Algo ha salido mal… Intenta realizar esta operación más tarde","error")
          });
    };
    
    const handleCloseAlert = () =>{
      setOpenAlert(false)
      setSuccessUserDeleted(false)
      setSuccessClearDeviceId(false)
      setSuccessChangePassword(false)
    };

    const handleCloseDialog = (e,respuesta) =>{
      
      if(contextDialog !== 1 ){
        setOpenDialog(false)
      }
       
       if(respuesta === 'eliminar' && contextDialog === 3){
          handleClickDeleteUserDPOS(e,idUserDPOS)
       }else if(respuesta === 'eliminar' && contextDialog === 2){
          handleClickClearUserDPOS(e,idUserDPOS)
       }else if(respuesta === 'eliminar' && contextDialog === 1){
        let isPassEmpty = isEmpty(passwordChange,"passwordChange")

        if(isPassEmpty){
          return
        }
        setOpenDialog(false)
        handleClickChangePassword(e,idUserDPOS)
       }else if(respuesta === 'eliminar' && contextDialog ===4){
       
        
         handleClickSend()
       }
       else {
        setOpenDialog(false)
       }
       setPasswordChange("")
    }

    const handleOpenDialog = (msg,ic) => {
      let object = {
        open:true,
        message:msg,
        type: "alert",
        icon:ic
    }
      props.changeDialogManagerValues(object)
    }

    const formatDate = (date)=>{
      let dateParts = date.split("/");
      let d = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0])
      return d 
    }
    
    return(
          <React.Fragment>
          
           <Snackbar
       anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
        open={openSnackBar}
        onClose={handleCloseSnackBar}
        TransitionComponent={TransitionLeft}
        message={
          <>
        <span>{"¡Tu solicitud para incorporar un usuario adicional ha sido procesada! Para verificar su estatus, ubica en el menú la opción "}<b>{"Gestionar usuarios"}</b></span>  
        
        </>
      }
        //key={vertical + horizontal}
      />
            <Dialog
          PaperProps={{
            style: {
              borderRadius:"20px",
              maxWidth : isDesktopOrLaptop ? "30%":isDesktop?"35%":isBigScreen?"20%":"80%",
              minWidth : isDesktopOrLaptop ? "30%":isDesktop?"35%":isBigScreen?"20%":"80%",
            },
          }}
          open={openDialog}
     
          keepMounted
          onClose={(e)=>handleCloseDialog(e,"",undefined)}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          
        >
          <DialogTitle id="alert-dialog-slide-title" style={{justifyContent:"center"}}>{""}
          <img className={classes.icClassAlert} src={IcWarning}></img>
          
          </DialogTitle>
          <DialogContent >
            <DialogContentText id="alert-dialog-slide-description" style={{paddingTop:"4%", textAlign:"center"}}>
            {dialogMessage}
            </DialogContentText>
          </DialogContent>
          {contextDialog === 1 && 
                <form autoComplete="off">
                <TextField
                  error = {emptyPasswordChange}
                  
                 style ={{marginLeft:"20%",width:"60%"}}
                 value ={passwordChange}
                  required
                  name="passwordChange"
                  label="Contraseña"
                  type={visibilityNewPass?'text' : 'password'}
                  id="passwordChange"
                  disabled = {loader}
                  onChange ={e => setPasswordChange(e.target.value)}
                  helperText={emptyPasswordChange?"Campo vacio":""}
                  className={classes.rootTextfieldClass}
                  InputLabelProps={{
                    classes: {
                      root: classes.rootTextfield,
                      focused: "focused",
                      shrink: "shrink"
                    }
                  }}
                  InputProps={{
                                     
                    endAdornment:(
                  
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowNewPassword}
                          // onMouseDown={handleMouseDownPassword}
                        >
                          <img alt="edit" src={visibilityNewPass? Openeye: Closeeye} className={classes.iconPass} />
                          {/* {!visibility ? <VisibilityOffIcon/>:<VisibilityIcon />} */}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  
                /></form>}
          <DialogActions style={{justifyContent:"center"}}>
            {contextDialog !== 4 && 
                <Button onClick={(e)=>handleCloseDialog(e,"cancelar",undefined)} className={classes.dialogCancel}>
                Cancelar
              </Button>
            }
              
                <Button onClick={(e)=>handleCloseDialog(e,"eliminar")} className={classes.dialogAccept} autoFocus>
                  Aceptar
                </Button>
          </DialogActions>
        </Dialog>
            
           
          
         
          <Grid container spacing={3}>
            {/* Chart */} 
            <Grid item xs={12} md={12} lg={12}>
              <Paper className={fixedHeightPaper}>
                <ResponsiveContainer>
                    <Container >
                    <Row>
                        <Col>
                            <Row>
                              
                              <label className={classes.title_principal}>Gestionar usuarios</label>
                            </Row >
                            <Row>
                              <label className={classes.title_detalle}>En esta sección podrás gestionar de forma segura el acceso a tu Dpos</label>
                            </Row>
                            <Row>
                              <ColorButton className={classes.btn_crear_user}  variant = "contained" onClick = {handleClickOpen}  >
                                Crear Usuario 
                              </ColorButton>
                            </Row>
                        </Col>
                        
                           
                    </Row>
                  </Container>
    
                </ResponsiveContainer> 
              </Paper>
            </Grid>
            {
              open &&
              <Grid item xs={12} md={12} lg={12}>
              <Paper className={fixedHeightPaperUser}>
                {/*<RegisterUser  handleToUpdate = {()=>handleToUpdate}/>*/}
                 <ResponsiveContainer>
                    <Container >
                      <Row>
                          <Col>
                            
                            <Row>
                              <form autoComplete="off"  className={classes.txtUser}>
                                <TextField
                                    error = {emptyMail}
                                    value = {email}
                                    margin="normal"
                                    AutoComplete = {false}
                                    required
                                    size = "small"
                                    id="email"
                                    label="Usuario"
                                    name="email"
                                    className={classes.rootTextfieldClass}
                                    InputLabelProps={{
                                      classes: {
                                        root: classes.rootTextfield,
                                        focused: "focused",
                                        shrink: "shrink"
                                      }
                                    }}
                                    onChange ={e => setEmail(e.target.value.replace(/\s/g, ""))} // no permite espacios en blanco
                                   
                                    //disabled = {loader}
                                    autoFocus
                                    
                                />
                                </form>
                              
                            </Row>

                            <Row>
                                <TextField
                                    error = {emptyPassword}
                                    
                                    margin="normal"
                                    value = {password}
                                    required
                                    size = "small"
                                    name="password"
                                    id="password"
                                    label="Contraseña"
                                    
                                    type={visibility?'text' : 'password'}
                                    InputLabelProps={{
                                      classes: {
                                        root: classes.rootTextfield,
                                        focused: "focused",
                                        shrink: "shrink"
                                      }
                                    }}
                                    onChange ={e => setPassword(e.target.value.replace(/\s/g, ""))}
                                    //disabled = {loader}
                                    
                                    className={classes.txtPassword}
                                    
                                    InputProps={{
                                     
                                      endAdornment:(
                                    
                                        <InputAdornment position="end">
                                          <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            // onMouseDown={handleMouseDownPassword}
                                          >
                                            <img alt="edit" src={visibility? Openeye: Closeeye} className={classes.iconPass} />
                                            {/* {!visibility ? <VisibilityOffIcon/>:<VisibilityIcon />} */}
                                          </IconButton>
                                        </InputAdornment>
                                      )
                                    }}
                        
                                />
                              
                            </Row>
                            <Row>

                            
                                    <Button
                                    fullWidth  
                                    color="white"
                                    className={classes.submit}
                                    disabled ={loader}
                                    onClick= {handleClickConfirm}
                                  >
                                    Crear
                                  </Button>
                              

                            </Row>

                            
                            
                        </Col>
                    
                            
                            
                        
                    </Row>
                  </Container>
          
                </ResponsiveContainer>
              </Paper>
            </Grid> 
              
            }
           
            {/* tabla */}
            <Grid item xs={12} md={12} lg={12}>
              <Paper className={classes.paper}>
                
                <MaterialTable
                    title= {<Typography variant="h5" style={{color:"#004E9B",fontSize: "18px",}}>Usuarios</Typography>}
                    isLoading = {loader}
                    columns={
                                          
                      [
                        { title: 'Fecha', field: 'fecha' ,defaultSort:"desc" , customSort:(a,b)=>formatDate(a.fecha).getTime() - formatDate(b.fecha).getTime()},
                        { title: 'Hora', field: 'hora'},
                        { title: 'Usuario', field: 'username' },
                        { title: 'Contraseña', field: 'clave' },
                        { title: 'Estatus', field: 'estatus', render: rowData => (
                        rowData.estatus ==="Aprobado"?
                          <a className ={classes.successText}>
                            Aprobado
                          </a>:
                        rowData.estatus ==="Pendiente"?
                          <Tooltip title="En espera por aprobación del banco" aria-label="add">
                                    <a>
                                        Pendiente
                                      </a>   
                          </Tooltip>:
                          <a className ={classes.rejectText}>
                          No califica
                          </a>
                        )
                      },
                        
                      ]
                      
                    }
                    
                    data={table.data}
                    localization ={{
                      header:{
                        actions: "Acciones"
                      },
                      toolbar:{
                        searchPlaceholder:"Buscar",
                        searchTooltip:"Buscar"
                      },
                      pagination:{
                        labelRowsSelect: "Filas",
                        labelDisplayedRows: '{from}-{to} de {count}',
                        firstTooltip: "Primera página",
                        lastTooltip: "Última página",
                        nextTooltip: "Siguiente",
                        previousTooltip: "Anterior",
                      },
                      body: {
                        emptyDataSourceMessage:"No hay registros para mostrar"
                      }
                    }}
                    actions={[
                      {
                        icon: () =>  <img src={ic_password} className={classes.imageIcons}/>,
                        tooltip: 'Cambiar contraseña',
                        onClick: (_event, rowData) =>{
                          setDialogMessage("Ingresa la nueva contraseña")
                          setContextDialog(1)
                          setIdUserDPOS(rowData.id)
                          setOpenDialog(true)
                        } 
                      },
                      {
                        icon: () =>  <img src={ic_device} className={classes.imageIcons}/>,
                        tooltip: 'Cambiar dispositivo',
                        onClick: (event, rowData) =>{
                    
                          setDialogMessage("¿Estás seguro que deseas liberar este usuario para utilizarlo en otro dispositivo?")
                          setContextDialog(2)
                          setIdUserDPOS(rowData.id)
                          setOpenDialog(true)
                        } 
                      },
                      {
                        icon: () =>  <img src={ic_delete} className={classes.imageIcons}/>,
                        tooltip: 'Eliminar usuario',
                        onClick: (event, rowData) =>{
                         
                          setDialogMessage("¿Estás seguro que deseas eliminar el usuario?")
                          setContextDialog(3)
                          setIdUserDPOS(rowData.id)
                          setOpenDialog(true)
                        } 
                      }
                    ]}
                   icons={{
                     Search:()=>  <img src={ic_search} style={{width:20,height:18}}/>
                   }}

                    options={{
                      actionsColumnIndex: -1,
                      headerStyle: {
                        backgroundColor: '#FFF',
                        color: '#004E9B'
                      },
                      sorting: true,
                      pageSize : 10,
                    }}
            
                    
                  >
                </MaterialTable>
              </Paper>
            </Grid>
          </Grid>
          </React.Fragment>
           )
      
} 

const mapDispatchToProps =(dispatch) =>({
  changeDialogManagerValues(args){
      dispatch(getDialogManager(args))
  },
  
})
export default connect(null,mapDispatchToProps)(Create)