import React, { useEffect, useState } from 'react';
import { makeStyles,Typography } from '@material-ui/core';
import MaterialTable from 'material-table';
import ReactExport from "react-export-excel";
import download from "../../../assets/download.png"
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
// import download from "../../../assets/download.png"

const useStyles = makeStyles(theme => ({
    titleTable:{
        fontWeight:'bold'
      },
}))
export default function TableReport({flag,data}) {
    const classes = useStyles();
    const [table,setTable] = useState({
        data:[]
    })

    useEffect(() => {
        console.log(data)
        // let keys =[]
        // let values = []
        // data.map((value,index,array) => {
        //     for (const [key, value] of Object.entries(array[index])) {
        //         console.log(key, value);
        //         keys.push(key)
        //         values.push([value])
        //       }
        // })
        // setDataSets([
        //     { columns:keys,data:values}
        // ])
        setTable({data:data})
    }, [data])

    const formatDate = (date)=>{
        let dateParts = date.split("/");
        let d = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0])
        return d 
      }
    return (
        
        <React.Fragment>
           
            <MaterialTable
                title=""
                localization={{
                    toolbar: {
                        searchPlaceholder: "Buscar",
                        searchTooltip: "Buscar",

                    },
                    pagination: {
                        labelRowsSelect: "Filas",
                        labelDisplayedRows: '{from}-{to} de {count}',
                        firstTooltip: "Primera página",
                        lastTooltip: "Última página",
                        nextTooltip: "Siguiente",
                        previousTooltip: "Anterior",
                    },
                    body: {
                        emptyDataSourceMessage: "No hay registros para mostrar"
                    }
                }}
                // icons={{
                //     Search: () => <img src={ic_search} style={{ width: "20px", height: "17px" }} />,
                // }}
                //isLoading={loader}
                columns={
                    flag === "detail" ? [
                        { title: 'Comercio', field: 'razon_social'},
                        { title: 'Código afiliación', field: 'identificador_comercio',cellStyle: {
                            minWidth: 50,
                            maxWidth: 50
                          }},
                        { title: 'Usuario', field: 'username' },
                        { title: 'Modalidad de pago', field: 'modalidad' },
                        { title: 'Tipo de transacción', field: 'tipo_operacion' },
                        { title: 'Estatus', field: 'estatus' },
                        { title: 'Fecha de transacción', field: 'fecha',defaultSort:"desc" ,customSort:(a,b)=>formatDate(a.fecha).getTime() - formatDate(b.fecha).getTime()},
                        { title: 'Hora', field: 'hora' },
                        { title: 'Motivo rechazo', field: 'motivo_rechazo'},
                        { title: 'Monto Transacción (Bs.)', field: 'monto', type: 'numeric' ,customSort: (a, b) =>{ 
                            let montoA = parseFloat(a.monto.replace(".","").replace(",",".")).toFixed(2)
                            let montoB = parseFloat(b.monto.replace(".","").replace(",",".")).toFixed(2)
                            return  montoA - montoB
                        },
                       
                    },
                        // { title: 'Nro Autorizacion', field: 'aprobacion' },
                        // { title: 'Lote',field: 'lote'},
                        { title: 'Referencia', field: 'num_referencia' },
                        { title: 'Número de Tarjeta / Celular', field: 'ultimos4_digitos' ,cellStyle: {
                            minWidth: 150,
                            maxWidth: 150
                          }},
                        
                        
                          { title: 'Ubicación',field: 'geolocalizacion', type: 'numeric'},
                        { title: 'Marca', field: 'tipo_tarjeta'},
                        

                        // { title: 'Aplicación',field:'aplicacion'},

                    ]: [
                        { title: 'Comercio', field: 'razon_social'},
                        { title: 'Código afiliación', field: 'identificador_comercio'},
                        
                        { title: 'Modalidad de pago', field: 'modalidad' },
                        { title: 'Tipo de transacción', field: 'tipo_operacion' },
                        { title: 'Motivo rechazo', field: 'motivo_rechazo' },
                        { title: 'Fecha de transacción', field: 'fecha',defaultSort:"desc",customSort:(a,b)=>formatDate(a.fecha).getTime() - formatDate(b.fecha).getTime() },
                        //{ title: 'Hora', field: 'hora' },
                        { title: 'Total Transacciones', field: 'total_transacciones'},
                        { title: 'Total Monto', field: 'monto', type: 'numeric' ,customSort: (a, b) =>{ 
                            let montoA = parseFloat(a.monto.replace(".","").replace(",",".")).toFixed(2)
                            let montoB = parseFloat(b.monto.replace(".","").replace(",",".")).toFixed(2)
                            return  montoA - montoB
                        } },
                       
                        // { title: 'Nro Autorizacion', field: 'aprobacion' },
                        // { title: 'Lote',field: 'lote'},
                        // { title: 'Referencia', field: 'num_referencia' },
                        // { title: 'Número de Tarjeta / Celular', field: 'ultimos4_digitos' },
                        
                        
                        
                        // { title: 'Marca', field: 'tipo_tarjeta'},
                        

                        // { title: 'Aplicación',field:'aplicacion'},

                    ]
                    

                }
                data={table.data}
                options={{
                    headerStyle: {
                        backgroundColor: '#004E9B',
                        color: '#FFF',
                        fontSize: 13,
                        height:40
                    },
                    rowStyle: {
                        overflowWrap: 'break-word',
                        fontSize:13,
                        textAlign: 'center',  
                      },
                    //   exportButton: {
                    //     csv: true,
                    //     pdf: true,
                    //  },
                    sorting: true,
                    pageSize : 10,
                    
                }}

                actions={[
                    flag !== "detail" ?
                        {
                            icon: () => (
                                <ExcelFile element={<img src={download} style={{ height: 25, width: 25 }} />} filename="Reporte de ventas diaras">
                                    <ExcelSheet data={data} name="Reporte de ventas diaras">
                       
                                        <ExcelColumn label="Comercio" value="razon_social" />
                                        <ExcelColumn label="Código de afiliación" value="identificador_comercio" />
                                        <ExcelColumn label="Modalidad de pago" value="modalidad" />
                                        <ExcelColumn label="Tipo de transacción" value="tipo_operacion" />
                                        <ExcelColumn label="Fecha" value="fecha" />
                                        <ExcelColumn label="Motivo rechazo" value="motivo_rechazo" />
                                        <ExcelColumn label="Total Transacciones" value="total_transacciones" />
                                        <ExcelColumn label="Total Monto" value={(e)=>parseFloat(e.monto.replace(".","").replace(",","."))}/>
                                        
                                    </ExcelSheet>

                                </ExcelFile>

                            ),
                            tooltip: 'Descargar',
                            isFreeAction: true,
                        } :
                        {
                            icon: () => (
                                <ExcelFile element={<img src={download} style={{ height: 25, width: 25 }} />} filename="Reporte detalle de transacciones">
                                    <ExcelSheet data={data} name="Reporte detalle de transacciones">
                       
                                        <ExcelColumn label="Comercio" value="razon_social"  />
                                        <ExcelColumn label="Código de afiliación" value="identificador_comercio" />
                                        <ExcelColumn label="Usuario" value="username" />
                                        <ExcelColumn label="Modalidad de pago" value="modalidad" />
                                        <ExcelColumn label="Tipo de transacción" value="tipo_operacion" />
                                        <ExcelColumn label="Estatus" value="estatus" />
                                        <ExcelColumn label="Fecha" value="fecha" />
                                        <ExcelColumn label="Hora" value="hora" />
                                        <ExcelColumn label="Motivo rechazo" value="motivo_rechazo" />
                                        <ExcelColumn label="Monto Transacción (Bs.)" value={(e)=>parseFloat(e.monto.replace(".","").replace(",","."))} />
                                        <ExcelColumn label="Referencia" value="num_referencia" />
                                        <ExcelColumn label="Número de Tarjeta / Celular" value="ultimos4_digitos" />
                                        <ExcelColumn label="Ubicación" value="geolocalizacion" />
                                        <ExcelColumn label="Marca" value="tipo_tarjeta" />
                                    </ExcelSheet>

                                </ExcelFile>

                            ),
                            tooltip: 'Descargar',
                            isFreeAction: true,
                        },


                ]}
            >

            </MaterialTable>
        </React.Fragment>
    );
}