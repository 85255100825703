import { CHANGE_CONTEXT } from '../constants/typesRedux'; // type actions in constants (optional)

    const initialState = {
        getContext:
        {
            context:""
        }
    }
    const getContextApp = (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_CONTEXT:
            state = {
                getContext:{
                    context:action.context.context
                }
            }
        break;
        default:
        break;
    }
    return state
    };
export default getContextApp;



