import React, { useContext,useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button'
import Tooltip from '@material-ui/core/Tooltip';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { mainListItems, secondaryListItems } from './listItems';
import ListSecondaryItem from './componentsSPA/ListItem/ListSecondaryItem';
import { BrowserRouter as Router, Route } from "react-router-dom"
import Home from './componentsSPA/homeComponents/Home'
import Create from './componentsSPA/createComponents/Create'
import CryptoJS from 'crypto-js'
import { MasterKeyLogin } from '../constants/constants'
import { useHistory } from "react-router-dom";
import lgMercantil from "../assets/m_cn_rgb_sin_bg.png"

import Grid from '@material-ui/core/Grid';
import '../fonts/Roboto/Roboto-Bold.ttf';
import SwipeableTemporaryDrawer from '../componentUtils/SwipeableTemporaryDrawer'
import { useMediaQuery } from 'react-responsive'
import { getMenuDrawerManager } from '../actions/index';
import { connect } from 'react-redux'
import AlertMod from '../modals/alertMod'
import FooterOut from '../componentUtils/FooterOut';
import Help from './componentsSPA/helpComponents/Help';

import Instructivo from './componentsSPA/instructivoComponents/Instructivo';
import Reports from './componentsSPA/reportsComponents/Reports';
import Profile from './componentsSPA/homeComponents/Profile';

const drawerWidth = 320;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  logoMercantilStyle: {
    width: "170px",
    //height:"41px",
    height: "61px",
    [theme.breakpoints.between('sm', 'md')]: {
      height: "60px",
    },
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
    background: "linear-gradient(90deg, rgba(0,78,159,1) 0%, rgba(1,154,216,1) 12%, rgba(0,78,155,1) 39%)"
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    backgroundColor: "#E0E0E0"
  },
  toolbarIconTitle: {
    position: "absolute",
    top: "0",
    left: "30%",
    right: "0",
    fontFamily: "Sans-serif"
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    // transition: theme.transitions.create(['width', 'margin'], {
    //   easing: theme.transitions.easing.sharp,
    //   duration: theme.transitions.duration.leavingScreen,
    // }),
    backgroundColor: "#000048"
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),

  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    flexShrink: 0,
    // transition: theme.transitions.create('width', {
    //   easing: theme.transitions.easing.sharp,
    //   duration: theme.transitions.duration.enteringScreen,
    // }),

  },
  drawerPaperClose: {
    overflowX: 'hidden',
    flexShrink: 0,
    // transition: theme.transitions.create('width', {
    //   easing: theme.transitions.easing.sharp,
    //   duration: theme.transitions.duration.leavingScreen,
    // }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  footerGrid: {
    width: "100%",
    position: "fixed",
    zIndex: "1000",
    bottom: "0",
    height: 50,
    paddingLeft: "10px",
    paddingRight: "10px",
    backgroundColor: "#EBEBEB",
  },
  
  typographyTextTitles: {
    color: '#004E9B', 
    marginBottom: -1,
    marginTop:-5, 
    marginLeft: "15%", 
    marginRight: "15%", 
    textAlign: 'center',
    fontSize:13,
    [theme.breakpoints.down('md')]: {
      fontSize:8,
      textAlign: 'justify',
    },
    
   
  },

  typographyDate: {
    color: 'gray', 
    marginBottom: -10,
    marginTop:-15, 
    textAlign: 'left', 
    fontSize:13,
   
    [theme.breakpoints.down('md')]: {
      fontSize:10,
      textAlign: 'center',
    },
    [theme.breakpoints.only('md')]: {
      
      marginTop:-15, 
      fontSize:10,
      textAlign: 'left',
    }
    
  },
  typographyTodayDate: {
    color: 'gray', 
    marginBottom: -10,
    marginTop:-15, 
    textAlign: 'center', 
    fontSize:13,
   
    [theme.breakpoints.down('md')]: {
      fontSize:10,
      textAlign: 'left',
    },
    [theme.breakpoints.only('md')]: {
      
      marginTop:-15, 
      fontSize:10,
      textAlign: 'right',
    }
  },
  
}));

function Dashboard({ getMenuDrawerManager, changeStatusMenuDrawer }) {
  let history = useHistory();
  let rif = localStorage.getItem("_CDAUPOMGKLJL") || sessionStorage.getItem("_CDAUPOMGKLJL")
  let id = localStorage.getItem("_CDAUPOMGKLJLTYJIKH") || sessionStorage.getItem("_CDAUPOMGKLJLTYJIKH")
  let idempresa = localStorage.getItem("_CDAUPOMGKLJLTYJIKHENTER") || sessionStorage.getItem("_CDAUPOMGKLJLTYJIKHENTER")
  let limite_usuarios = localStorage.getItem("_CDAUPOMGKLJLTYJIKHENTERLIM") || sessionStorage.getItem("_CDAUPOMGKLJLTYJIKHENTERLIM")
  let ultima_sesion = localStorage.getItem("_CDAUPOMGKLJLTYJIKHENTERLIMETRERT") || sessionStorage.getItem("_CDAUPOMGKLJLTYJIKHENTERLIMETRERT")
  let razon_social = localStorage.getItem("_CDAUPOMGKLJLTYJIKHENTERLIMETRERTRAZN") || sessionStorage.getItem("_CDAUPOMGKLJLTYJIKHENTERLIMETRERTRAZN")
  let telefono = sessionStorage.getItem("_CDAUPOMGKLJLTYJIKHENTERLIMETRERTLFN") || localStorage.getItem("_CDAUPOMGKLJLTYJIKHENTERLIMETRERTLFN")
  let identificador_comercio = sessionStorage.getItem("_CDAUPOMGKLJLTYJIKHENTERLIMETRERIDFCT") || localStorage.getItem("_CDAUPOMGKLJLTYJIKHENTERLIMETRERIDFCT")
  let correo= sessionStorage.getItem("_CDAUPOMGKLJLTYJIKHENTERLIMETRERCOR") || localStorage.getItem("_CDAUPOMGKLJLTYJIKHENTERLIMETRERCOR")
  let direccion = sessionStorage.getItem("_CDAUPOMGKLJLTYJIKHENTERLIMETRERDIR") || localStorage.getItem("_CDAUPOMGKLJLTYJIKHENTERLIMETRERDIR")
  let defrif;
  let defid;
  let defidempresa;
  let defultimasesion;
  let defrazonsocial;
  let deftelefono;
  let defidentificadorcomercio;
  let defcorreo;
  let defdireccion;
  let hora;
  /*validar sesion*/
  if (rif === null) {
    history.push("/login")
  } else {
    let decryptRif = CryptoJS.AES.decrypt(rif, MasterKeyLogin)
    defrif = decryptRif.toString(CryptoJS.enc.Utf8);
    let decryptid = CryptoJS.AES.decrypt(id, MasterKeyLogin)
    defid = decryptid.toString(CryptoJS.enc.Utf8);
    let decryptidempresa = CryptoJS.AES.decrypt(idempresa, MasterKeyLogin)
    defidempresa = decryptidempresa.toString(CryptoJS.enc.Utf8);

    console.log("razon_social ", razon_social)
    console.log("id_empresa",defidempresa.toString())
    let decryptrazonsocial = CryptoJS.AES.decrypt(razon_social, MasterKeyLogin)
    defrazonsocial = decryptrazonsocial.toString(CryptoJS.enc.Utf8);

    let decryptultimasesion = CryptoJS.AES.decrypt(ultima_sesion, MasterKeyLogin)
    defultimasesion = decryptultimasesion.toString(CryptoJS.enc.Utf8);
    hora = defultimasesion.split("T")[1].split(".")[0]

    defultimasesion = defultimasesion.split("T")[0]
    defultimasesion = new Date(defultimasesion)
    defultimasesion.setDate(defultimasesion.getDate() + 1)
    defultimasesion = defultimasesion.getDate().toString().padStart(2, "0")+ "/" +  defultimasesion.getMonth().toString().padStart(2, "0")+"/"+defultimasesion.getFullYear()

    let decrypttelefono = CryptoJS.AES.decrypt(telefono, MasterKeyLogin)
    deftelefono = decrypttelefono.toString(CryptoJS.enc.Utf8);

    let decryptidentificadorcomercio = CryptoJS.AES.decrypt(identificador_comercio, MasterKeyLogin)
    defidentificadorcomercio = decryptidentificadorcomercio.toString(CryptoJS.enc.Utf8);

    let decryptcorreo = CryptoJS.AES.decrypt(correo, MasterKeyLogin)
    defcorreo = decryptcorreo.toString(CryptoJS.enc.Utf8);

    let decryptdireccion = CryptoJS.AES.decrypt(direccion, MasterKeyLogin)
    defdireccion = decryptdireccion.toString(CryptoJS.enc.Utf8);

    // str = e.fecha.split("T")
    // e.fecha = str[0]
    // today= new Date(e.fecha)

    // e.fecha =today.toLocaleDateString("en-GB")
    // e.hora = str[1].split(".")[0]
  }



  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  
  const handleDrawerOpen = event => {
    event.preventDefault();
    changeStatusMenuDrawer(true)
    //setOpen(true);
  };

  const handleDrawerClose = event => {
    event.preventDefault();
    changeStatusMenuDrawer(false)
    //setOpen(false);
  };

  const logout = event => {

    localStorage.removeItem("_CDAUPOMGKLJL") || sessionStorage.removeItem("_CDAUPOMGKLJL")
    localStorage.removeItem("_CDAUPOMGKLJLTYJIKH") || sessionStorage.removeItem("_CDAUPOMGKLJLTYJIKH")
    window.location.reload();
  };

  


  const getDate = () => {
    let dt = new Date();

    let year = dt.getFullYear();
    let month = (dt.getMonth() + 1).toString().padStart(2, "0");
    let day = dt.getDate().toString().padStart(2, "0");

    let hour = dt.getHours().toString().padStart(2, "0")
    let minute = dt.getMinutes().toString().padStart(2, "0")
    let second = dt.getSeconds().toString().padStart(2, "0")
    
    return day + '/' + month + '/' + year + '-' + hour + ':' + minute + ':' + second
  }

  const getTime = setInterval(() => {
    let time = document.querySelector("#time")
    
    time.innerHTML =  getDate()
  }, 1000);

  useEffect(() => {
    return () => {
      clearInterval(getTime)
     
    //  socket.emit("get aproved","hola")
    //  socket.emit("user aproved", (data)=>{
      
    //   console.log(data)
    // })
    
    }
  }, [])
  
  // socket.on("get aproved",`Comercio ${defidempresa}`, (data) => {
  //   console.log(data)
  // })
 

  
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 1024px)'
  })
  return (
    <div className={classes.root}>
      <CssBaseline />

    

      <AppBar position="absolute" className={clsx(classes.appBar, (getMenuDrawerManager && isDesktopOrLaptop) && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          {!isDesktopOrLaptop &&
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              className={clsx(classes.menuButton, (getMenuDrawerManager && isDesktopOrLaptop) && classes.menuButtonHidden)}

            >

              <MenuIcon />
            </IconButton>
          }
          <img src={lgMercantil} className={classes.logoMercantilStyle}></img>
          <Typography variant="h6" className={classes.title}>
          </Typography>
          <label style={{ fontSize: "18px", textTransform: "capitalize", }}>Portal Dpos</label>
          {/* <Tooltip title = "cerrar sesión">
              <IconButton color="inherit" onClick={logout} type = "submit">
                
                <ExitToAppIcon  />
            
              </IconButton>
          </Tooltip> */}

        </Toolbar>
      </AppBar>

      {isDesktopOrLaptop ?
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.drawerPaper, (!getMenuDrawerManager && !isDesktopOrLaptop) && classes.drawerPaperClose),
          }}
          open={getMenuDrawerManager}
        >
          <div className={classes.toolbarIcon}>

            <IconButton >
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          <List><ListSecondaryItem rif={defrif} /></List>

          {/* <List>
            <ListSecondaryItem/>
          </List> */}

        </Drawer> :
        <SwipeableTemporaryDrawer>
          <div className={classes.toolbarIcon}>

            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          <List><ListSecondaryItem /></List>
        </SwipeableTemporaryDrawer>

      }

      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="xl" className={classes.container}>
          <Grid container spacing={2}>
         {isDesktopOrLaptop &&
            <Grid item  lg= {4} xs={4}>

            </Grid>
          }
            <Grid item lg= {4}  md = {4} xs={6}>
              <Typography className ={classes.typographyDate}>
                {"Tu última visita fue el " + defultimasesion + ' a las ' + hora}

              </Typography>
            </Grid>
            <Grid item lg= {4} md = {4} xs={6}>
              <Typography className ={classes.typographyDate}  id="time">
               

              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.typographyTextTitles}>
                <strong>¡Recuerda!</strong> Al momento de realizar tus cobros con Dpos debes validar la identidad del cliente y asegurarte que es el titular de la tarjeta, de esta forma evitas que ocurran fraudes en tu comercio.

              </Typography>
            </Grid>
          </Grid>

          <Route
            path='/home'
            render={(props) => <Home {...props} rif={defrif} id={defid} />}
          />
          <Route
            path='/profile'
            render={(props) => <Profile {...props} rif={defrif} id={defid} id_empresa={defidempresa} limite_usuarios={limite_usuarios} identificador_comercio ={defidentificadorcomercio} telefono ={deftelefono} razon_social={defrazonsocial} correo ={defcorreo} direccion={defdireccion}/>}
          />
          <Route
            path='/create'
            render={(props) => <Create {...props} rif={defrif} id={defid} id_empresa={defidempresa} limite_usuarios={limite_usuarios} />}
          />
          <Route
            path='/reports'
            render={(props) => <Reports {...props} rif={defrif} flag = {"detail"} id = {defid} razon_social={defrazonsocial}/>}
          />
          <Route
            path='/reportsSoldDay'
            render={(props) => <Reports {...props} rif={defrif} flag = {"sold"} id={defid} razon_social ={defrazonsocial}/>}
          />
          <Route
            path='/instructivo'
            render={(props) => <Instructivo {...props} />}
          />
          <Route
            path='/help'
            render={(props) => <Help {...props} />}
          />


          <FooterOut page="dashboard" />
        </Container>
      </main>
    </div>
  );
}
const mapStateToProps = state => {
  console.log(state)
  return {
    getMenuDrawerManager: state.getMenuDrawerManager.menuDrawerStatus.open
  };
}

const mapDispatchToProps = (dispatch) => ({
  changeStatusMenuDrawer(args) {
    dispatch(getMenuDrawerManager(args))
  }
})


export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
