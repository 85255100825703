import React from 'react';
import MaterialTable from 'material-table';
import axios from 'axios';
import { BaseURL, getAllTransactions, getTransactionsByDate, getTrxDetail } from '../../../constants/constants'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Buttons from '@material-ui/core/Button';
import Button from '@material-ui/core/Button';
import { makeStyles,ThemeProvider } from '@material-ui/core/styles';
import DateRangeIcon from '@material-ui/icons/DateRange';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import GetAppIcon from '@material-ui/icons/GetApp';
import DateFnsUtils from '@date-io/date-fns';
import Grid from '@material-ui/core/Grid';
import PdfDocument from './PdfDocument';
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import ic_search from "../../../assets/ic_search.png"
import calendario from "../../../assets/calendario.png"
import update from "../../../assets/ic_update.png"
import download from "../../../assets/download.png"
import CircularProgress from "@material-ui/core/CircularProgress";
import { PDFDownloadLink, Document, Page } from '@react-pdf/renderer'
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { es} from 'date-fns/locale'
import Typography from '@material-ui/core/Typography';
import { Filter, Sort } from '@material-ui/icons';
import ReactExport from "react-export-excel";
import { createMuiTheme } from '@material-ui/core';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({

  buttonProgress: {
    color: "#000048",
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  titleTable: {
    fontWeight: 'bold'
  },

  successText: {
    color: 'green'
  },
  pendingText: {
    color: 'orange'
  },
  rejectText: {
    color: 'red'
  },
  
  submit: {
    color: "white",
    margin: "auto",
    marginTop: 20,
    borderRadius: "25px",
    background: "linear-gradient(180deg, #004E9B, #009FDA)",
    textTransform: "capitalize",
    width: "150px",
  },
  input: {
    color: "#004E9B"
  }

}));



export default function Orders(props) {
  const classes = useStyles();
  let rifMerchant = props.rif
  let flag = props.flag
  const [open, setOpen] = React.useState(false);
  const [openDownloader, setOpenDownloader] = React.useState(false)

  const [loader, setLoader] = React.useState(false)
 
  const [dateError, setDateError] = React.useState(false)
  const [selectedDateTo, setSelectedDateTo] = React.useState(new Date());
  const [selectedDateFrom, setSelectedDateFrom] = React.useState(new Date());
  const [table, setStateTable] = React.useState({
    data: [
      //{ fecha: 'Mehmet', monto: 'Baran', aprobacion: 1987, referencia: 63, lote: 63 },
      //{ fecha: 'Zerya Betül',monto: 'Baran',aprobacion: 2017,referencia: 34,lote: 34},
    ],
  })

  const [visibilyUpdate,setVisibilityUpdate] =  React.useState(true)
  const [dataTrx, setDataTrx] = React.useState([])
  React.useEffect((props) => {

    let today, todayCompleta
    setLoader(true)
    axios.get(BaseURL + getAllTransactions + rifMerchant)

      .then(res => {
        console.log("DATA  ", res)
        setLoader(false)
        if (res.data[0] !== undefined) {
          let datos = res.data
          let str = "";
          
          datos.forEach(function (e) {
            
            str = e.fecha.split("T")
            e.fecha = str[0]
            e.fechaCompleta = str[0]+"T"+str[1]
            todayCompleta = new Date(e.fechaCompleta)
            todayCompleta.setDate(todayCompleta.getDate() + 1)
            
            today = new Date(e.fecha)
            today.setDate(today.getDate() + 1);
            console.log("fecha  ", e.fecha)

            
            e.monto = new Intl.NumberFormat("de-DE", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }).format(parseFloat(e.monto))
            e.fecha = today.toLocaleDateString("en-GB")
            e.hora = str[1].split(".")[0]
            if (e.estatus === 1) {
              e.estatus = "Aprobado"
            } else if (e.estatus === 2) {
              e.estatus = "Rechazado"
            }
            if (e.tipo_tarjeta === "Mastercard" || e.tipo_tarjeta === "Visa" || e.tipo_tarjeta === "Diners Club" || e.tipo_tarjeta === "American Express") {
              e.modalidad = "Tarjeta de Crédito"
              e.ultimos4_digitos = "**** " + e.ultimos4_digitos
            } else if (e.tipo_tarjeta === "Maestro") {
              e.modalidad = "Tarjeta de Débito Mercantil"
              e.ultimos4_digitos = "**** " + e.ultimos4_digitos
            } else if (e.tipo_tarjeta === "C2P Compra") {
              e.modalidad = "Pago móvil C2P"
            }
           
            e.tipo_operacion = "Venta"

          })

          setStateTable({ data: datos })
          return res.data;
        } else {
          return 'Null';
        }

      })
      .catch(err => {
        console.log(err);
        setLoader(false)

      })
    getDetailTransactions() // analizar esta funcion para q en un futuro no se utilice aca
  }, [])



  const handleDateChangeTo = date => {
    // date = new Date(date).toLocaleDateString('en-ZA', { year: 'numeric', month: '2-digit', day: '2-digit' }).toString()

    setSelectedDateTo(date)
  };


  const handleDateChangeFrom = date => {
    // date = new Date(date).toLocaleDateString('en-ZA', { year: 'numeric', month: '2-digit', day: '2-digit' }).toString()
    setSelectedDateFrom(date)
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRemoveFilter = () => {
    let today
    setLoader(true)
    axios.get(BaseURL + getAllTransactions + rifMerchant)

      .then(res => {

        setLoader(false)
        if (res.data[0] !== undefined) {
          setVisibilityUpdate(true)
          let datos = res.data
          let str = "";
          console.log("DATA  ", datos)
          datos.forEach(function (e) {
            str = e.fecha.split("T")
            e.fecha = str[0]
            today = new Date(e.fecha)
            today.setDate(today.getDate() + 1);
            console.log("fecha  ", e.fecha)
            e.monto = new Intl.NumberFormat("de-DE", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }).format(parseFloat(e.monto))
            e.fecha = today.toLocaleDateString("en-GB")
            e.hora = str[1].split(".")[0]
            if (e.estatus === 1) {
              e.estatus = "Aprobado"
            } else if (e.estatus === 2) {
              e.estatus = "Rechazado"
            }
            if (e.tipo_tarjeta === "Mastercard" || e.tipo_tarjeta === "Visa" || e.tipo_tarjeta === "Diners Club" || e.tipo_tarjeta === "American Express") {
              e.modalidad = "Tarjeta de Crédito"
              e.ultimos4_digitos = "**** " + e.ultimos4_digitos
            } else if (e.tipo_tarjeta === "Maestro") {
              e.modalidad = "Tarjeta de Débito Mercantil"
              e.ultimos4_digitos = "**** " + e.ultimos4_digitos
            } else if (e.tipo_tarjeta === "C2P Compra") {
              e.modalidad = "Pago móvil C2P"
            }

            e.tipo_operacion = "Venta"

          })

          setStateTable({ data: datos })
          //return res.data;
        } else {
          setVisibilityUpdate(false)
          return 'Null';
        }

      })
      .catch(err => {
        setVisibilityUpdate(false)
        console.log(err);
        setLoader(false)

      })
  }

  // const exportCsv = (allColumns, allData) => {
  //   const columns = allColumns.filter(columnDef => columnDef["export"] !== false);
  //   const exportedData = allData.map(rowData => columns.map(columnDef => rowData[columnDef.field]));
  //   new _filefy.CsvBuilder('filename_' + moment().format('YYYY-MM-DDTHHmmss'))
  //     .setDelimeter(';')
  //     .setColumns(columns.map(columnDef => columnDef.title))
  //     .addRows(exportedData)
  //     .exportFile();
  // }

  
  const getTransactionDate = () => {
    let to = document.getElementById("toTrx")
    let from = document.getElementById("fromTrx")
    let today
    let dateTrim = formatDate(to.value) + "-" +formatDate(from.value)
    let dateFormat = dateTrim.split("-")
    let toWithoutFormat = dateFormat[0].replace(/[/]/g, " ")
    let fromWthoutFormat = dateFormat[1].replace(/[/]/g, " ")
    console.log(toWithoutFormat)
    if (selectedDateTo > selectedDateFrom) {
      setDateError(true)
      return
    }

    handleClose()
    setLoader(true)
    axios.get(BaseURL + getTransactionsByDate + "?fecha=" + toWithoutFormat + "-" + fromWthoutFormat + "&rif=" + rifMerchant)
      .then(res => {
        setVisibilityUpdate(false)
        console.log("DATOS ", res)
        setLoader(false)
        let datos = res.data.message
        let str = ""
        datos.forEach(function (e) {
          str = e.fecha.split("T")
          e.fecha = str[0]
          e.fechaCompleta = str[0]+"T"+str[1]
          today = new Date(e.fecha)
          today.setDate(today.getDate() + 1);
          e.monto = new Intl.NumberFormat("de-DE", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }).format(parseFloat(e.monto))
          e.fecha = today.toLocaleDateString("en-GB")
          e.hora = str[1].split(".")[0]
          if (e.estatus === 1) {
            e.estatus = "Aprobado"
          } else if (e.estatus === 2) {
            e.estatus = "Rechazado"
          }

          
          if (e.tipo_tarjeta === "Mastercard" || e.tipo_tarjeta === "Visa" || e.tipo_tarjeta === "Diners Club" || e.tipo_tarjeta === "American Express") {
            e.modalidad = "Tarjeta de Crédito"
            e.ultimos4_digitos = "**** " + e.ultimos4_digitos
          } else if (e.tipo_tarjeta === "Maestro") {
            e.modalidad = "Tarjeta de Débito Mercantil"
            e.ultimos4_digitos = "**** " + e.ultimos4_digitos
          } else if (e.tipo_tarjeta === "C2P Compra") {
            e.modalidad = "Pago móvil C2P"
          }

          e.tipo_operacion = "Venta"
        })


        setStateTable({ data: datos })

        return res.data

      })
      .catch(err => {

        setLoader(false)

      })
  };

  const formatDate = (date)=>{
    let dateParts = date.split("/");
    let d = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]),
      month = "" + (d.getMonth() + 1).toString().padStart(2, "0"),
      day = "" + d.getDate().toString().padStart(2, "0"),
      year = d.getFullYear().toString().padStart(2, "0");
    return [year,month,day].join("/")  
  }

  const dateToSort=(date) =>{
    console.log(date)
    
        let d = new Date(date)
        return d 
  }
  const handleOpenDowloader = () => {
    
    setOpenDownloader(true)
  };

  const handleCloseDownloader = () => {
    setOpenDownloader(false)
  };

  const getDetailTransactions = () => {
    let schema = {
      rif: rifMerchant
    }
    axios.post(BaseURL + getTrxDetail, schema)
      .then(res => {

        setDataTrx(res.data.data)

      })
      .catch(err => {

      })
  }
  // const localeMap = {
  //   es: esLocale,
  
  // };
  return (

    <React.Fragment>
     
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{""}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Selecciona los criterios de filtrado y haz clic en el botón <strong>Buscar</strong>
          </DialogContentText>
          
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es} >
            <Grid container justify="space-around">
              <KeyboardDatePicker
                disableToolbar
                disableFuture
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                autoOk
                
                id="toTrx"
                label="Desde"
                value={selectedDateTo}
                onChange={handleDateChangeTo}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                keyboardIcon={<img src={calendario} style={{ width: 20, height: 20 }} />}
                InputProps={{ className: classes.input }}
              error ={dateError}
              
              />
              <KeyboardDatePicker
                autoOk
                disableToolbar
                disableFuture
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                id="fromTrx"
                label="Hasta"
                value={selectedDateFrom}
                onChange={handleDateChangeFrom}
                KeyboardButtonProps={{
                  'aria-label': 'change date',

                }}
                keyboardIcon={<img src={calendario} style={{ width: 20, height: 20 }} />}
                InputProps={{ className: classes.input }}
              />
            </Grid>
          </MuiPickersUtilsProvider>
         
        </DialogContent>
        <DialogActions>
          {/* <Buttons onClick = {getTransactionDate} color="primary">
              Ir
            </Buttons> */}
          <Button
            fullWidth
            color="white"
            className={classes.submit}
            disabled={loader}
            onClick={getTransactionDate}
          >
            {loader && <CircularProgress size={24} className={classes.buttonProgress} />}
            Buscar
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        open={openDownloader}

        onClose={handleClose}
        message="Descargar archivo"
        action={
          <React.Fragment>

            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              {openDownloader && <PDFDownloadLink
                document={<PdfDocument data={table} />}
                fileName="Reporte.pdf"
                style={{
                  textDecoration: "none",
                  padding: "5px",
                  color: "white",
                  backgroundColor: "transparent",

                }}
              >
                {({ blob, url, loading, error }) =>
                  loading ? (<CircularProgress style={{ height: 20, width: 20 }} color="green" />) : (<GetAppIcon onClick={handleCloseDownloader} />)
                }
              </PDFDownloadLink>}

            </IconButton>
          </React.Fragment>
        }
      />
      <MaterialTable
        title={<Typography className={classes.titleTable}>{flag !== "reports" ? "Consulta de transacciones" : "Reporte detalles de transacciones"}</Typography>}
        localization={{
          toolbar: {
            searchPlaceholder: "Buscar",
            searchTooltip: "Buscar",

          },
          pagination: {
            labelRowsSelect: "Filas",
            labelDisplayedRows: '{from}-{to} de {count}',
            
            firstTooltip: "Primera página",
            lastTooltip: "Última página",
            nextTooltip: "Siguiente",
            previousTooltip: "Anterior",
          },
          body: {
            emptyDataSourceMessage: "No hay registros para mostrar"
          }
        }}
        icons={{
          Search: () => <img src={ic_search} style={{ width: "20px", height: "17px" }} />,
        }}
        isLoading={loader}
        columns={
          [
            { title: 'Fecha', field: 'fecha', defaultSort:"desc", customSort:(a,b)=>{console.log(dateToSort(a.fechaCompleta).getTime()); return dateToSort(a.fechaCompleta).getTime() - dateToSort(b.fechaCompleta).getTime()}},
            { title: 'Hora', field: 'hora',cellStyle: {
              minWidth: 50,
              maxWidth: 50
            }  },
            { title: 'Monto Transacción (Bs.)', field: 'monto',type: 'numeric' ,customSort: (a, b) =>{ 
              let montoA = parseFloat(a.monto.replace(".","").replace(",",".")).toFixed(2)
              let montoB = parseFloat(b.monto.replace(".","").replace(",",".")).toFixed(2)
              return  montoA - montoB
          },cellStyle: {
            minWidth: 100,
            maxWidth: 100
          }  },
            // { title: 'Nro Autorizacion', field: 'aprobacion' },
            // { title: 'Lote',field: 'lote'},
            { title: 'Referencia', field: 'num_referencia', cellStyle: {
              minWidth: 150,
              maxWidth: 150
            } },
            { title: 'Usuario', field: 'username',width: "14%" },
            { title: 'Modalidad de pago', field: 'modalidad' ,cellStyle:{width: '12'}},
            { title: 'Tipo de transacción', field: 'tipo_operacion' },
            { title: 'Estatus', field: 'estatus',cellStyle:{width: '12'} },
            { title: 'Número de Tarjeta / Celular', field: 'ultimos4_digitos',   },

            // { title: 'Aplicación',field:'aplicacion'},

          ]

        }
        data={table.data}
        options={{
          headerStyle: {
            backgroundColor: '#004E9B',
            color: '#FFF',
            fontSize: 13,
            height:40
          },
          rowStyle: {
            overflowWrap: 'break-word',
            fontSize:13,
            textAlign: 'center',  
          },
          
          //   exportButton: {
          //     csv: true,
          //     pdf: true,
          //  },
          sorting: true,
          pageSize : 10,
          
        }}

        actions={[
          
            {
              icon: () => <img src={calendario} style={{ width: 20, height: 20 }} />,
              tooltip: 'Buscar fecha',
              isFreeAction: true,
              onClick: handleClickOpen,
            },
            {
              icon: () => <img src={update} style={{ width: 20, height: 20 }} />,
              tooltip: 'Quitar filtro',
              isFreeAction: true,
              onClick: handleRemoveFilter,
              hidden:visibilyUpdate
            } 
            


        ]}
      >

      </MaterialTable>


    </React.Fragment>
  );


}